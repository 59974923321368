import { useContext, useEffect, useState } from "react";

import { UserInfo, CommentsData } from "../../organisms/UserInfo/UserInfo";
import "./Summary.css";
import Button from "../../atoms/Button/Button";
import { request } from "../../../customHook/request";
import { useMsal } from "@azure/msal-react";
import { ArrowLeftOutlined } from "@ant-design/icons";

import { NavLink, useNavigate } from "react-router-dom";

import { FloatButton, message } from "antd";
import AntCard from "../../atoms/Card/Card";
import { fetchOptions } from "../../../customHook/FetchOptions";
import Spinner from "../../atoms/spinner/Spinner";
import { ThemeContext } from "../../../customHook/useContext/Usecontext";
function Summary() {
  
  const [pageLoader, setPageLoader] = useState(false);
  const [error, setError] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [storeName, setStoreName] = useState("");

  const [isReader, setIsReader] = useState(false);
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);
  const [dropDownChange, setDropDownChange] = useState(false);
  const [storeId, setStoreId] = useState("");
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [cardLoading, setCardLoading] = useState(false);
  const [messageBoxValue, setMessageBoxValue] = useState("");
  const [updatedBy, setUpdatedBy] = useState("");
  const [commentResponse, setCommentResponse] = useState([]);
  const { accounts } = useMsal();
  const username = accounts[0] && accounts[0].name;
  const Store_Emailid = accounts[0] && accounts[0].username;
  const [refreshData, setRefreshData] = useState(0);
  const [number, setnumber] = useState(0);
  const [infoComments, setInfoComments] = useState({
    sales: "",
    customer: "",
    staffing: "",
    inventory: "",
    salesOpportunities: "",
    rewards: "",
  });
  const [comments, setComments] = useState({
    sales: "",
    customer: "",
    staffing: "",
    inventory: "",
    salesOpportunities: "",
    rewards: "",
  });
  const { state } = useContext(ThemeContext);
  const DarkMode = state.darkMode;
  const navigate = useNavigate();


      /* <-------------Fetching SummaryDay-----------> */
  const handleSummaryDay = async () => {
    setPageLoader(true);
    setStoreId(localStorage.getItem("storeid"))
    try {
      let res = await request("post", {
        requesttype: "fetch_summary_page",
      });

      if (res.data.statuscode === 200) {
        setPageLoader(false);
        setIsReader(false);
        setSaveBtnDisabled(false);
      } else if (res.data.statuscode === 404) {
        setPageLoader(false);
        setIsReader(true);
        setMessageBoxValue(res.data.data);
        message.warning(res.data.data);
      }
    } catch (err) {
      setError(true);
      setPageLoader(false);
      setIsReader(true);
    } finally {
      setPageLoader(false);
    }
  };

  /* <-------------Call SummaryDay Function-----------> */
  useEffect(() => {
    handleSummaryDay();
  }, []);

  const responseHandle = (res) => {
    if (res.data.statuscode === 200) {
      let data_comments = JSON.parse(res.data.data);

      setUpdatedBy(data_comments[0].updated_by);
      setComments((prevComments) => ({
        ...prevComments,
        sales: data_comments[0].sales_comments,
        customer: data_comments[0].customer_service_comments,
        staffing: data_comments[0].staffing_comments,
        inventory: data_comments[0].inventory_comments,
        rewards: data_comments[0].loyalty_comments,
        salesOpportunities: data_comments[0].store_opportunities_comments,
      }));
    } else {
      setUpdatedBy("");
      setComments((prevComments) => ({
        ...prevComments,
        sales: "",
        customer: "",
        staffing: "",
        inventory: "",
        salesOpportunities: "",
        rewards: "",
      }));
    }
  };

  
   /* <-------------Fetching Summary Comments -----------> */
   const Weekly_summary = async (storename) => {
    try {
      let res = await request("post", {
        requesttype: "fetch_summary_comments",
        store_name: storename ? storename : localStorage.getItem("storename"),
      });
      responseHandle(res);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
      fetchPrevComments();
    }
  };

  
    /* <-------------Fetching Comments-----------> */
  

  const fetchComments = async () => {
    try {
      const { res, DropdownValues: storeNameOptions } = await fetchOptions({
        requesttype: "storeinfobyemail",
        store_email: Store_Emailid,
        user_name: username,
        //             store_email: "vijay.kumar@gtigrows.com",
        // user_name: "vijay.kumar@gtigrows.com",
      });

      //   console.log({res,storeNameOptions})
      localStorage.setItem("documenturl", res.data.documentation_url);
      if (res) {
        const stores = await JSON.parse(res.data.stores);

        setDropdownOptions(storeNameOptions);
        setCommentResponse(stores);

  
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
      fetchPrevComments();
    }
  };
  
    /* <-------------Fetching Previous Comments(info)-----------> */
  
  const fetchPrevComments = async () => {
    const dateObject = new Date();
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, "0");
    const day = String(dateObject.getDate()).padStart(2, "0");

    const CurrentDate = `${year}-${month}-${day}`;
    try {
      const sales_highlights = async () => {
        try {
          let res = await request("post", {
            requesttype: "fetch_comments",
            store_name: localStorage.getItem("storename")
              ? localStorage.getItem("storename") 
              : storeName,
            processing_date: CurrentDate,
            comment_type: "sales highlights",
          });
          if (res.status === 200) {
            let sales = JSON.parse(res.data.data);
            setInfoComments((prev) => ({
              ...prev,
              sales: sales,
            }));
          } else {
            setInfoComments((prev) => ({
              ...prev,
              sales: "",
            }));
          }
        } catch (err) {
          console.log(err);
        }
      };
      sales_highlights();

      const Customer = async () => {
        try {
          let res = await request("post", {
            requesttype: "fetch_comments",
            store_name: localStorage.getItem("storename")
              ? localStorage.getItem("storename") 
              : storeName,
            processing_date: CurrentDate,
            comment_type: "customer",
          });

          let customer = JSON.parse(res.data.data);
          setInfoComments((prev) => ({
            ...prev,
            customer: customer,
          }));
        } catch (err) {
          console.log(err);
        }
      };
      Customer();

      const Staffing = async () => {
        try {
          let res = await request("post", {
            requesttype: "fetch_comments",
            store_name: localStorage.getItem("storename")
            ? localStorage.getItem("storename") 
            : storeName,
            processing_date: CurrentDate,
            comment_type: "staffing",
          });

          let staffing = JSON.parse(res.data.data);
          setInfoComments((prev) => ({
            ...prev,
            staffing: staffing,
          }));
        } catch (err) {
          console.log(err);
        }
      };
      Staffing();

      const Inventory = async () => {
        try {
          let res = await request("post", {
            requesttype: "fetch_comments",
            store_name: localStorage.getItem("storename")
              ? localStorage.getItem("storename") 
              : storeName,
            processing_date: CurrentDate,
            comment_type: "inventory",
          });

          let inventory = JSON.parse(res.data.data);
          setInfoComments((prev) => ({
            ...prev,
            inventory: inventory,
          }));
        } catch (err) {
          console.log(err);
        }
      };
      Inventory();

      const Rewards = async () => {
        try {
          let res = await request("post", {
            requesttype: "fetch_comments",
            store_name: localStorage.getItem("storename")
            ? localStorage.getItem("storename") 
            : storeName,
            processing_date: CurrentDate,
            comment_type: "rewards",
          });

          let rewards = JSON.parse(res.data.data);
          setInfoComments((prev) => ({
            ...prev,
            rewards: rewards,
          }));
        } catch (err) {
          console.log(err);
        }
      };
      Rewards();

      const Sale_Opportunities = async () => {
        try {
          let res = await request("post", {
            requesttype: "fetch_comments",
            store_name: localStorage.getItem("storename")
              ? localStorage.getItem("storename") 
              : storeName,
            processing_date: CurrentDate,
            comment_type: "sale opportunities",
          });

          let saleOpportunities = JSON.parse(res.data.data);
          setInfoComments((prev) => ({
            ...prev,
            salesOpportunities: saleOpportunities,
          }));
        } catch (err) {
          console.log(err);
        }
      };
      Sale_Opportunities();
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
    }
  };
  /* <-------------Calling fetchComments and Weekly_summary -----------> */
  useEffect(() => {
    fetchComments();
    Weekly_summary();
  }, [refreshData]);


   /* <-------------Sorting infoComments based on Date -----------> */
const sortedColumn1Comments = [...infoComments.sales].sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  const sortedColumn2Comments = [...infoComments.customer].sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );
  const sortedColumn3Comments = [...infoComments.staffing].sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );
  const sortedColumn4Comments = [...infoComments.inventory].sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );
  const sortedColumn5Comments = [...infoComments.rewards].sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  const sortedColumn6Comments = [...infoComments.salesOpportunities].sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

   /* <-------------display last week comments based on storename-----------> */
  const modalContent1 = (
    <div className="scrollable-info-content">
      {sortedColumn1Comments.length > 0 ? (
        sortedColumn1Comments.map(({ sales_comments, date }, index) => {
          const dateOnly = date; // Extract date part from the timestamp
          return (
            <div key={index} className="comment-item">
              <span className="date-label">{dateOnly}</span>
              <ul>
                <li className="info-msg">{sales_comments}</li>
              </ul>
            </div>
          );
        })
      ) : (
        <div className="no-data-message">No Data</div>
      )}
    </div>
  );


  const modalContent2 = (
    <div className="scrollable-info-content">
      {sortedColumn2Comments.length > 0 ? (
        sortedColumn2Comments.map(
          ({ customer_service_comments, date }, index) => {
            const dateOnly = date; // Extract date part from the timestamp
            return (
              <div key={index} className="comment-item">
                <span className="date-label">{dateOnly}</span>
                <ul>
                  <li className="info-msg">{customer_service_comments}</li>
                </ul>
              </div>
            );
          }
        )
      ) : (
        <div className="no-data-message">No Data</div>
      )}
    </div>
  );


  const modalContent3 = (
    <div className="scrollable-info-content">
      {sortedColumn3Comments.length > 0 ? (
        sortedColumn3Comments.map(({ staffing_comments, date }, index) => {
          const dateOnly = date; // Extract date part from the timestamp
          return (
            <div key={index} className="comment-item">
              <span className="date-label">{dateOnly}</span>
              <ul>
                <li className="info-msg">{staffing_comments}</li>
              </ul>
            </div>
          );
        })
      ) : (
        <div className="no-data-message">No Data</div>
      )}
    </div>
  );

  const modalContent4 = (
    <div className="scrollable-info-content">
      {sortedColumn4Comments.length > 0 ? (
        sortedColumn4Comments.map(({ inventory_comments, date }, index) => {
          const dateOnly = date; // Extract date part from the timestamp
          return (
            <div key={index} className="comment-item">
              <span className="date-label">{dateOnly}</span>
              <ul>
                <li className="info-msg">{inventory_comments}</li>
              </ul>
            </div>
          );
        })
      ) : (
        <div className="no-data-message">No Data</div>
      )}
    </div>
  );
 
  const modalContent5 = (
    <div className="scrollable-info-content">
      {sortedColumn5Comments.length > 0 ? (
        sortedColumn5Comments.map(({ loyalty_updates, date }, index) => {
          const dateOnly = date; // Extract date part from the timestamp
          return (
            <div key={index} className="comment-item">
              <span className="date-label">{dateOnly}</span>
              <ul>
                <li className="info-msg">{loyalty_updates}</li>
              </ul>
            </div>
          );
        })
      ) : (
        <div className="no-data-message">No Data</div>
      )}
    </div>
  );
 
  const modalContent6 = (
    <div className="scrollable-info-content">
      {sortedColumn6Comments.length > 0 ? (
        sortedColumn6Comments.map(
          ({ store_opportunities_comments, date }, index) => {
            const dateOnly = date; // Extract date part from the timestamp

            return (
              <div key={index} className="comment-item">
                <span className="date-label">{dateOnly}</span>
                <ul>
                  <li className="info-msg">{store_opportunities_comments}</li>
                </ul>
              </div>
            );
          }
        )
      ) : (
        <div className="no-data-message">No Data</div>
      )}
    </div>
  );

  const showInfoCmts = (modalNumber) => {
    if (modalNumber === 1) {
      setnumber(1);
    } else if (modalNumber === 2) {
      setnumber(2);
    } else if (modalNumber === 3) {
      setnumber(3);
    } else if (modalNumber === 4) {
      setnumber(4);
    } else if (modalNumber === 5) {
      setnumber(5);
    } else if (modalNumber === 6) {
      setnumber(6);
    } else {
      setnumber(0);
    }
  };

  const renderModalContent = (modalNumber) => {
    switch (modalNumber) {
      case 1:
        return modalContent1;
      case 2:
        return modalContent2;
      case 3:
        return modalContent3;
      case 4:
        return modalContent4;
      case 5:
        return modalContent5;
      case 6:
        return modalContent6;
      default:
        return null;
    }
  };

  
    /* <-------------StoreName Onchancge Function-----------> */
  

  const handleStoreNameChange = async (value, record) => {
    setCardLoading(true);
    localStorage.setItem("storename", record.value);
    setStoreName(record.value);
    setDropDownChange(true);

    let store_info = commentResponse.filter(
      (data) => data.dim_store_key === record.storekey
    );
   

    setStoreId(store_info[0].dim_store_key);
    localStorage.setItem("storeid", store_info[0].dim_store_key);
    localStorage.setItem("storename", store_info[0].store_name);

    setnumber(0);
    setInfoComments((prev) => ({
      ...prev,
      sales: "",
      customer: "",
      staffing: "",
      inventory: "",
      rewards: "",
      salesOpportunities: "",
    }));
    setComments((prevComments) => ({
      ...prevComments,
      sales: "",
      customer: "",
      staffing: "",
      inventory: "",
      salesOpportunities: "",
      rewards: "",
    }));

    const fetchingAllComments = async () => {
      try {
        const fetch = await Weekly_summary(record.value);
        if (fetch) {
          setCardLoading(false);
        }
      } catch (err) {
        setCardLoading(false);
      } finally {
        setCardLoading(false);
      }
    };
    fetchingAllComments();
  };


    /* <-------------Save Comments-----------> */
  
  const handle_submit = async (value) => {
  
    setSaveBtnLoading(true);
  
   
    let store_info = commentResponse.filter(
      (key) => key.dim_store_key== storeId
    );
  
    try {
      if (!isReader) {
        let storename = localStorage.getItem("storename")
          ? localStorage.getItem("storename")
          : storeName;
        const res = await request("post", {
          requesttype: "summary_save",
          store_timezone: store_info[0].store_timezone,
          store_id: store_info[0].dim_store_key,
          user_id: store_info[0].user_id,
          user_name: username,
   
        // user_name: "vijay.kumar@gtigrows.com",
          store_name: storename,
          sales_comments: comments.sales,
          staffing_comments: comments.staffing,
          inventory_comments: comments.inventory,
          store_opportunities_comments: comments.salesOpportunities,
          customer_service_comments: comments.customer,
          loyalty_updates: comments.rewards,
        });

        const data = res.data.statuscode;
        if (data === 200) {
          setSaveBtnLoading(false);
          message.success("Saved Sucessfully");
          setRefreshData(refreshData + 1);
          Weekly_summary(storeName);
        } else if (data === 403) {
          setSaveBtnLoading(false);
        }
      } else {
        message.warning(messageBoxValue);
      }
    } catch (err) {
      setSaveBtnLoading(false);
      message.error("Save Failed");
      console.log(err);
    } finally {
      setSaveBtnLoading(false);
    }
  };

  
    /* <-------------user Datas-----------> */
  

  const UserDatas = [
    {
      type: "paragraph",
      label: "Store Email Id",
      className: "form-input-noborder",
      value:Store_Emailid,
    },
    {
      type: "dropdown",
      label: "Store Name",

      placeholder: "storename",
      width: 200,
      className: "form-field-lg",
      onChange: (value, record) => handleStoreNameChange(value, record),
      options: dropdownOptions,
      value:
        dropDownChange === true ? storeName : localStorage.getItem("storename"),
    },
    {
      type: "paragraph",
      label: "Updated By",
      className: "form-input-noborder",
      value: updatedBy ? updatedBy : "",
    },
  ];

  
    /* <-------------CommentBox Datas-----------> */
  
  const data = [
    {
      type: "textarea",

      rows: 4,
      maxLength: 500,
      readOnly: isReader,
      value: comments.sales,
      title: "Sales Highlights",
      showCount: true,
      onChange: (e) =>
        setComments((prev) => ({
          ...prev,
          sales: e.target.value,
        })),

      loading: cardLoading,
      IconClick: () => showInfoCmts(1),
    },
    {
      type: "textarea",

      rows: 4,
      maxLength: 500,
      readOnly: isReader,
      value: comments.customer,
      title: "Customer",
      showCount: true,
      onChange: (e) =>
        setComments((prev) => ({
          ...prev,
          customer: e.target.value,
        })),
      loading: cardLoading,
      IconClick: () => showInfoCmts(2),
    },
    {
      type: "textarea",

      rows: 4,
      maxLength: 500,
      readOnly: isReader,
      value: comments.staffing,
      title: "Staffing",
      showCount: true,
      onChange: (e) =>
        setComments((prev) => ({
          ...prev,
          staffing: e.target.value,
        })),
      loading: cardLoading,
      IconClick: () => showInfoCmts(3),
    },
    {
      type: "textarea",

      rows: 4,
      maxLength: 500,
      readOnly: isReader,
      value: comments.inventory,
      title: "Inventory",
      showCount: true,
      onChange: (e) =>
        setComments((prev) => ({
          ...prev,
          inventory: e.target.value,
        })),
      loading: cardLoading,
      IconClick: () => showInfoCmts(4),
    },
    {
      type: "textarea",

      rows: 4,
      maxLength: 500,
      readOnly: isReader,
      value: comments.rewards,
      title: "Rewards",
      showCount: true,
      onChange: (e) =>
        setComments((prev) => ({
          ...prev,
          rewards: e.target.value,
        })),
      loading: cardLoading,
      IconClick: () => showInfoCmts(5), // IconClick: () => openModal("Rewards", infoComments.rewards),
    },
    {
      type: "textarea",

      rows: 4,
      maxLength: 500,
      readOnly: isReader,
      value: comments.salesOpportunities,
      title: "Sale Opportunities",
      showCount: true,
      onChange: (e) =>
        setComments((prev) => ({
          ...prev,
          salesOpportunities: e.target.value,
        })),
      loading: cardLoading,
      IconClick: () => showInfoCmts(6),
    },
  ];
  return pageLoader === true ? (
    <Spinner />
  ) : error === true ? (
    navigate("error")
  ) : (
    <div className="ccr-submit-container">
      {/* <-------------dashbord userInfo-----------> */}

      <section className="ccr-header">
        <div className="summary-backbtn">
          <NavLink to={"/ccr-submit"}>
            <Button
              className={DarkMode==="true"||DarkMode===true?"primary-btn-dark":"primary-btn"}
              Icon={ArrowLeftOutlined}
            ></Button>
          </NavLink>

          <h3  className={DarkMode==="true"||DarkMode===true?"page-header-dark":"page-header"}>
            <span>CLOSING REPORT WEEKLY SUMMARY </span>
          </h3>
        </div>

        <UserInfo fieldData={UserDatas} />
      </section>

      {/* <-------------dashbord CommentBox Section-----------> */}

      <section className="summary-content">
        <CommentsData fieldData={data} className={"summary-cmt-section"} />

        <AntCard
          className={"sp-sticky-card"}
          content={
            <>
              <p
                className="label"
                style={{ width: "100%", paddingBottom: "1rem" }}
              >
                Weekly Comments :
                <span className="data">
                  {" "}
                  {number === 1
                    ? "Sales Highlights"
                    : number === 2
                    ? "Customer"
                    : number === 3
                    ? "Staffing"
                    : number === 4
                    ? "Inventory"
                    : number === 5
                    ? "Rewards"
                    : number === 6
                    ? "Sale Opportunities"
                    : ""}
                </span>
              </p>

              {renderModalContent(number)}
            </>
          }
        />
      </section>

      {/* <-------------save btn-----------> */}

      <div className="cmt-save-btn">
        <div className="access-msg"></div>
        <Button
          onClick={handle_submit}
          loading={saveBtnLoading}
          disabled={saveBtnDisabled}
          label={"Submit"}
          className={saveBtnDisabled ? "" : DarkMode==="true"||DarkMode===true?"primary-btn-dark":"primary-btn"}
        />
      
      </div>
      <br/>
      <FloatButton.BackTop />
    </div>
  );
}

export default Summary;
