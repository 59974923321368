
import Dropdown from '../../atoms/Dropdown/Dropdown';
import "./FormDropdown.css"

const FormDropdown = ({ label, showSearch, value, placeholder, allowClear, onChange, options, className, mode, disabled, defaultValue }) => {
  return (
    <div className='Form-label-field'>
      <label className='label'>{label}</label>

      <Dropdown
        className={className}
        allowClear={allowClear}
        mode={mode}
        showSearch={showSearch}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={onChange}
        options={options}
        defaultValue={defaultValue}
      />
    </div>
  );
};

export default FormDropdown;
