
import './ErrorPage.css';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
const ErrorPage = () => {

  const navigate=useNavigate()
  const { page } = useParams();
  const isError=true;

  // console.log(page,"page")
  const handleTryAgain = () => {
    navigate(`/${page}`)
  
  };
  const handleHome = () => {
    navigate("/homepage")
  };

  return (


    
      isError?
      (
        <div className={`error-page ${isError ? 'error' : ''}`}>
      
        <div className="error-content">
          <h1>{isError ? 'Oops!' : ''}</h1>
          <p>
            {isError
              ? 'Something went wrong while processing your request.'
              : ''}
          </p>
          <div className="button-container">
            <button onClick={handleTryAgain} className="try-again-button">
              Try Again
            </button>
            <button className="home-button" onClick={handleHome}>Home</button>
          </div>
        </div>
      </div>
      ):
      null
    

  );
};

export default ErrorPage;
