import React from 'react';
import { Modal as AntdModal } from 'antd';
import './DynamicModal.css';
import Button from '../../atoms/Button/Button';

const DynamicModal = ({ visible, onClose, title, content, buttons, width }) => {
  return (

    <AntdModal
      maskClosable={false}
      visible={visible}
      onCancel={onClose}
      title={title}
      footer={null}
      style={{
        top: 20,

      }}
      width={width}
    >

      {content}
      <div className="modal-buttons" style={{ display: "flex", gap: "3rem" }}>
        {buttons ? buttons.map((button, index) => (
          <Button
            key={index}
            label={button.label}
            onClick={button.onClick}
            loading={button.loading}
            disabled={button.disabled}
          />
        )) : null}
      </div>

    </AntdModal>

  );
};

export default DynamicModal;
