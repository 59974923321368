import React, { useContext, useEffect } from 'react'
import Button from '../../atoms/Button/Button'
import Table from '../../atoms/Table/Table'
import { useState } from 'react'
import { useMsal } from '@azure/msal-react';
import { Upload } from "antd"
import AWS from 'aws-sdk';
import './FileUpload.css'
import { request } from '../../../customHook/request';
import _ from 'lodash';
import * as XLSX from 'xlsx';
import { message } from 'antd';
import Dropdown from '../../atoms/Dropdown/Dropdown';
import { UploadOutlined } from '@ant-design/icons';
import { UserInfo } from '../../organisms/UserInfo/UserInfo';
import { ThemeContext } from '../../../customHook/useContext/Usecontext';

function FileUpload() {

    const s3 = new AWS.S3();

    AWS.config.update({
        accessKeyId: 'AKIA2NUSMD6UHMZ656HZ',
        secretAccessKey: 'vQfA5juZfvc5omZzAK3HuQtueAbIljCyafj/oicu',
        region: 'us-east-1', // e.g., 'us-east-1'
    });

    const [excelData, setExcelData] = useState(null);


    const [file, setFile] = useState("")
    const [fileType, setFileType] = useState("")
    const [submitLoading, setSubmitLoading] = useState(false)
    const [disableButton, setDisableButton] = useState(true)
    const [fileName, setFileName] = useState("")
    const [loading, setLoading] = useState(true)
    const [fetchData, setFetchData] = useState([])
    const [gettableData, setGettableData] = useState(0)
    const [count, setCount] = useState("")
    const [fileNameExtension, setFileNameExtension] = useState("")
    const [error, SetError] = useState(false)
    const { state } = useContext(ThemeContext);
    const DarkMode = state.darkMode;
    const { accounts } = useMsal();
    const Store_Emailid = accounts[0] && accounts[0].username;

    const [userNameFilter, setUserNameFilter] = useState([])
    const [fileNameFilter, setFileNameFilter] = useState([])
    const [dateFilter, setDateFilter] = useState([])

    const columns = [
        {
            key: '1',
            title: "User Name",
            dataIndex: 'user_name',
            width: 150,
            filters: userNameFilter,
            filterMode: 'tree',
            filterSearch: true,
            onFilter: (value, record) => record.user_name.startsWith(value),
        },
        {
            key: '2',
            title: "File Name",
            dataIndex: 'file_name',
            width: 150,
            filters: fileNameFilter,
            filterMode: 'tree',
            filterSearch: true,
            onFilter: (value, record) => record.file_name.startsWith(value),
        },
        {
            key: '3',
            title: "File Type",
            align: 'center',
            dataIndex: 'file_type',
            width: 150,
        },
        {
            key: '4',
            title: "Record Count",
            align: 'center',
            dataIndex: 'record_count',
            width: 100,
        },
        {
            key: '5',
            title: "Status",
            align: 'center',
            dataIndex: 'status',
            width: 160,
        },
        {
            key: '6',
            title: 'Date',
            align: 'center',
            dataIndex: 'rows_ins_dt',
            width: 120,
            filters: dateFilter,
            filterMode: 'tree',
            filterSearch: true,
            onFilter: (value, record) => record.rows_ins_dt.startsWith(value),
        },
    ];

    const handle_change = () => {
        // console.log("Print Numbers")
    }

    const UserData = [
        {
            type: "dropdown",
            // label: "File Type",
            placeholder: "File Type",
            width: 200,
            className: "form-field-lg",
            options: [
                { text: "Purchase Order", value: "Purchase Order" },
            ],
            onChange: (value) => setFileType(value)
        },
        {
            type: "upload",
            label: "Click to Upload",
            width: 200,
            className: "form-field-lg",
            icon: <UploadOutlined />,
            onChange: () => handle_change(),
            customRequest: (file) => customRequest(file)
        },
        {
            className: "bottom-btn",
            type: "button",
            label: "Submit",
            name: "Submit",
            valuePropName: "checked",
            wrapperCol: {
                offset: 0,
                span: 24,
            },
            // onClick: () => setIsModalAdd(false),
        },
    ]

    const FetchData = async () => {
        try {
            const response = await request("Post", {
                "requesttype": "fetch_audit_detail",
                "user_name": Store_Emailid
            })
            let UserName = []
            let fileName = []
            let Date = []
            if (response.data.statuscode === 200) {
                setLoading(false)
                const fetch = JSON.parse(response.data.data)
                setFetchData(_.uniq(fetch))
                fetch.map((res) => {
                    if (res.user_name !== "") {
                        UserName.push({
                            text: res.user_name,
                            value: res.user_name
                        })
                    }
                    if (res.file_name !== "") {
                        fileName.push({
                            text: res.file_name,
                            value: res.file_name
                        })
                    }
                    if (res.rows_ins_dt !== "") {
                        Date.push({
                            text: res.rows_ins_dt,
                            value: res.rows_ins_dt
                        })
                    }
                    return UserName, fileName, Date
                })
                const uniqueUserName = _.uniqBy(UserName, 'value');
                const uniqueFileName = _.uniqBy(fileName, 'value');
                const uniqueDate = _.uniqBy(Date, 'value');
                setUserNameFilter(uniqueUserName)
                setFileNameFilter(uniqueFileName)
                setDateFilter(uniqueDate)
            } else {
                setLoading(false)
                message.warning("Somthing Went Wrong")
            }
        } catch (error) {
            SetError(true)
            console.error("Error fetching data:", error.message);
        }
    }

    useEffect(() => {
        FetchData()
    }, [])

    const handleFileChange = (e) => {
        
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

                setExcelData(jsonData);
            };

            reader.readAsBinaryString(file);
        }
    };

    // console.log(file, "file")

    // const handleUpload = async (file) => {
    //     console.log(file)
    //     setFile(file)
    //     const params = {
    //         Bucket: 'gti-spectrm-data',
    //         Key: `uploads/${file.name}`, // Change the Key to your desired path and file name
    //         Body: file,
    //     };

    //     try {
    //         const data = await s3.upload(params).promise();
    //         console.log('File uploaded successfully:', data);
    //         message.success('File uploaded successfully');
    //     } catch (error) {
    //         console.error('Error uploading file:', error);
    //         message.error('Error uploading file');
    //     }
    // };

    const customRequest = ({ file, onSuccess, onError }) => {
        handleFileChange(file)
            .then(onSuccess)
            .catch(onError);
    };

    const handle_upload = async (info) => {
        // console.log(info,"info")
        try {
            if (!info.fileList || info.fileList.length === 0) {
                message.error("No file selected");
                setDisableButton(true);
                return;
            }

            const file = info.fileList[0];

            if (
                file.type === "text/csv" ||
                file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ) {
                setFileName(file.name);
                const FileName = file.name;
                const filenameWithoutExtension = FileName.slice(
                    0,
                    FileName.lastIndexOf(".")
                );
                setFileNameExtension(filenameWithoutExtension);
                setDisableButton(false);
            } else {
                message.error("Format Miss Match for file type");
                setDisableButton(true);
                return;
            }

            const data = await readFileAsync(file);
            const workbook = XLSX.read(data, { type: "binary" });

            // Object to store data from each sheet with sheet names as keys
            const sheetData = {};

            // Process each sheet in the workbook
            workbook.SheetNames.forEach((sheetName) => {
                const worksheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: "null" });

                // Check if the jsonData is not empty
                if (jsonData && jsonData.length > 0) {
                    let headers = jsonData[0];
                    const numColumns = headers.length;
                    headers = Array.from(
                        { length: numColumns + 1 },
                        (_, index) => (index === 0 ? "s_no" : `column_${index}`)
                    );
                    headers.push("file_name");
                    const arrayOfObjects = jsonData.map((row, rowIndex) => {
                        const obj = Object.fromEntries(
                            headers.map((header, index) => {
                                if (header === "s_no") {
                                    return [header, rowIndex + 1];
                                } else if (header === "file_name") {
                                    return [header, file.name];
                                } else {
                                    return [header, row[index - 1]];
                                }
                            })
                        );
                        return obj;
                    }).filter((obj) => obj !== null);

                    // Store data from each sheet in the sheetData object with sheet name as the key
                    sheetData[sheetName] = arrayOfObjects;
                }
            });

            // Optionally, you can set the total count (number of sheets) and the sheetData object
            // based on your requirements
            setCount(Object.keys(sheetData).length);
            setFile(sheetData);
        } catch (error) {
            console.error('Error:', error);
            // Handle the error, show a message, etc.
        }
    };

    const readFileAsync = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => resolve(event.target.result);
            reader.onerror = (error) => reject(error);
            reader.readAsBinaryString(file);
        });
    };

    console.log(fileName, "file")



    // const handle_upload = (info) => {
    //     if (
    //         info.fileList[0].type === "text/csv" ||
    //         info.fileList[0].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    //     ) {
    //         setFileName(info.fileList[0].name);
    //         const FileName = info.fileList[0].name;
    //         const filenameWithoutExtension = FileName.slice(
    //             0,
    //             FileName.lastIndexOf(".")
    //         );
    //         setFileNameExtension(filenameWithoutExtension);
    //         setDisableButton(false);
    //     } else {
    //         message.error("Format Miss Match for file type");
    //         setDisableButton(true);
    //         return;
    //     }
    //     const { file } = info;
    //     const reader = new FileReader();
    //     reader.onload = function (event) {
    //         const data = event.target.result;
    //         const workbook = XLSX.read(data, { type: "binary" });

    //         // Object to store data from each sheet with sheet names as keys
    //         const sheetData = {};

    //         // Process each sheet in the workbook
    //         workbook.SheetNames.forEach((sheetName) => {
    //             const worksheet = workbook.Sheets[sheetName];
    //             const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: "null" });

    //             // Check if the jsonData is not empty
    //             if (jsonData && jsonData.length > 0) {
    //                 let headers = jsonData[0];
    //                 const numColumns = headers.length;
    //                 headers = Array.from(
    //                     { length: numColumns + 1 },
    //                     (_, index) => (index === 0 ? "s_no" : `column_${index}`)
    //                 );
    //                 headers.push("file_name");
    //                 const arrayOfObjects = jsonData.map((row, rowIndex) => {
    //                     const obj = Object.fromEntries(
    //                         headers.map((header, index) => {
    //                             if (header === "s_no") {
    //                                 return [header, rowIndex + 1];
    //                             } else if (header === "file_name") {
    //                                 return [header, info.fileList[0].name];
    //                             } else {
    //                                 return [header, row[index - 1]];
    //                             }
    //                         })
    //                     );
    //                     return obj;
    //                 }).filter((obj) => obj !== null);

    //                 // Store data from each sheet in the sheetData object with sheet name as the key
    //                 sheetData[sheetName] = arrayOfObjects;
    //             }
    //         });

    //         // Optionally, you can set the total count (number of sheets) and the sheetData object
    //         // based on your requirements
    //         setCount(Object.keys(sheetData).length);
    //         setFile(sheetData);
    //     };
    //     reader.readAsBinaryString(file);
    // };

    const handle_submit = async () => {
        setSubmitLoading(true)
        setLoading(true)
        try {
            const response = await request("Post", {
                "requesttype": "product_qty_insert_stg",
                "op_type": "new",
                "user_name": Store_Emailid,
                "file_type": fileType,
                "file_name": fileName,
                "data": file,
                "record_count": count,
                "file_name_load_type": fileNameExtension
            })

            if (response.data.statuscode === 200) {
                setFileType("")
                setFile("")
                setCount("")
                setFileName("")
                setSubmitLoading(false)
                setLoading(false)
                message.success("Data has been processed Successfully")
            }

            else if (response.data.statuscode === 404) {
                setFileType("")
                setFile("")
                setCount("")
                setFileName("")
                setSubmitLoading(false)
                setLoading(false)
                message.success("File Format Miss Match")
            }

            else if (response.data.statuscode === 403) {
                setFileType("")
                setFile("")
                setCount("")
                setFileName("")
                setSubmitLoading(false)
                setLoading(false)
                message.success("File Already Exist")
            }
        } catch (error) {
            setLoading(false)
            setSubmitLoading(false)
            message.warning("File Not Upload")
            console.error("Error fetching data:", error.message);
        } finally {
            setSubmitLoading(false)
            setLoading(false)
        }

    }


    return (
        <div className='fileupload-container'>
            <div className='header-session'>
                <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "1rem" }}>
                    <h3 className={DarkMode==="true"||DarkMode===true?"page-header-dark":"page-header"}>FILE UPLOAD</h3>
                    {excelData && (
                        <div>
                            <h3>Excel Data:</h3>
                            <pre>{JSON.stringify(excelData, null, 2)}</pre>
                        </div>
                    )}
                    <UserInfo fieldData={UserData} />
                </div>

                {/* <Button
                    loading={submitLoading}
                    onClick={() => handle_submit()}
                    label={"Submit"}
                    className={"primary-btn"}
                /> */}
            </div>
            <Table dataSource={fetchData} loading={loading} columns={columns} />
        </div>
    )
}

export default FileUpload