import "./CpgSummary.css"
import {
  Button,
  Table,

} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useMsal } from "@azure/msal-react";
import dayjs from "dayjs";
import { request } from "../../../customHook/request";
import { UserInfo } from "../../organisms/UserInfo/UserInfo";
import { MoneyCollectTwoTone } from "@ant-design/icons";
import { ThemeContext } from "../../../customHook/useContext/Usecontext";
const monthFormat = "YYYY-MM";

function CpgSummary() {
  const navigate = useNavigate();
  const { accounts } = useMsal();
  const username = accounts[0] && accounts[0].name;
  const Store_Emailid = accounts[0] && accounts[0].username;

  const [fetchData, setFetchData] = useState([]);
  const [storeDrobDown, setStoreDropDown] = useState("");
  const [gettableData, setGettableData] = useState(0);
  const [loading, setLoading] = useState(false);
  const [storeName, setStoreName] = useState("");
  const [startingBalance, setStartingBalance] = useState("");

  const [amount, setAmount] = useState("");

  const [totalAmount, setTotalAmount] = useState("");
  const [endingBalance, setEndingBalance] = useState("");
  const { state } = useContext(ThemeContext);
  const DarkMode = state.darkMode;
  const [selectedValue, _] = useState(
    localStorage.getItem("storename") ? localStorage.getItem("storename") : ""
  );

  const defaultValue = localStorage.getItem("date");
  const [date, setDate] = useState(
    localStorage.getItem("date")
      ? localStorage.getItem("date")
      : getCurrentDate()
  );
  function getCurrentDate() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    return `${year}-${month}`;
  }

  //<-------------Table Columns--------------------->
  const columns = [
    {
      key: "1",
      title: "Catagory",
      dataIndex: "sub_catagory",
      fixed: "left",
      width: 150,
    },
    {
      key: "2",
      title: "Total Amount",
      align: "center",
      dataIndex: "sum",
      fixed: "left",
      width: 150,
    },
  ];

  //<-------------Handle storename  changes --------------------->
  const hanlde_store_name = (value) => {
    if (value === undefined || value === null) {
      localStorage.setItem("storename", "");
    }
    localStorage.setItem("storename", value);
    setLoading(true);
    setStoreName(value);
    async function StoreName() {
      try {
        await request("Post", {
          op_type: "fetch_cash_supplies_summary_catagory",
          requesttype: "cash_supplies_summary",
          month: date,
          store_name: value,
        //   user_name: username,
     
        
         
        }).then((res) => {
          if (res.data.statuscode === 200) {
            setLoading(false);
            const val = JSON.parse(res.data.data);
            setFetchData(val);
          } else if (res.data.statuscode === 404) {
            setLoading(false);
            setFetchData([]);
          }
        });
      } catch (err) {
        setLoading(false);
        if (err.response) {
          // The client was given an error response (5xx, 4xx)
          console.log("Error", err.response);
        } else if (err.request) {
          console.log("Error", err.request);
          // The client never received a response, and the request was never left
        } else {
          // Anything else
          console.log("Error", err.message);
        }
      }
    }
    StoreName();

    async function StartingBalance() {
      try {
        await request("Post", {
          op_type: "fetch_cash_supplies_summary_starting_balance",
          requesttype: "cash_supplies_summary",
          user_id: Store_Emailid,
          store_name: value,
          user_name: username,
          month: date,
        }).then((res) => {
          if (res.data.statuscode === 200) {
            setLoading(false);

            const starting_balance = res.data.data.starting_balance;

            if (starting_balance === null) {
              setStartingBalance(0);
            } else {
              setStartingBalance(starting_balance);
            }
        
          }
       
        });
      } catch (err) {
        if (err.response) {
          // The client was given an error response (5xx, 4xx)
          console.log("Error", err.response);
        } else if (err.request) {
          console.log("Error", err.request);
          // The client never received a response, and the request was never left
        } else {
          // Anything else
          console.log("Error", err.message);
        }
      }
    }
    StartingBalance();

    async function Amount() {
      try {
        await request("Post", {
          op_type: "fetch_cash_supplies_summary_allocated_amount",
          requesttype: "cash_supplies_summary",
          user_id: Store_Emailid,
          store_name: value,
          user_name: username,
          month: date,
        }).then((res) => {
          if (res.data.statuscode === 200) {
            setLoading(false);
            const allocated_amount = res.data.data.total_allocated_amount;
            if (allocated_amount === null) {
              setAmount(0);
           
            } else {
              setAmount(allocated_amount);
           
            }
          }
        });
      } catch (err) {
        if (err.response) {
          // The client was given an error response (5xx, 4xx)
          console.log("Error", err.response);
        } else if (err.request) {
          console.log("Error", err.request);
          // The client never received a response, and the request was never left
        } else {
          // Anything else
          console.log("Error", err.message);
        }
      }
    }
    Amount();

    async function TotalAmount() {
      try {
        await request("Post", {
          op_type: "fetch_cash_supplies_summary_total_amount",
          requesttype: "cash_supplies_summary",
          user_id: Store_Emailid,
          store_name: value,
          user_name: username,
          month: date,
        }).then((res) => {
          if (res.data.statuscode === 200) {
            setLoading(false);

            const total_amount = res.data.data.total_amount;
            if (total_amount === null) {
              setTotalAmount(0);
            } else {
              setTotalAmount(total_amount);
            }

            // val.map((res) => {
            //   setTotalAmount(res.total_amount);
            // })
          }
          // else if(res.data.statuscode === 404){
          //   setLoading(false)
          //   message.error("Data Unavailable")
          // }
        });
      } catch (err) {
        if (err.response) {
          // The client was given an error response (5xx, 4xx)
          console.log("Error", err.response);
        } else if (err.request) {
          console.log("Error", err.request);
          // The client never received a response, and the request was never left
        } else {
          // Anything else
          console.log("Error", err.message);
        }
      }
    }
    TotalAmount();

    async function Ending() {
      try {
        await request("Post", {
          op_type: "calculate_cash_supplies_difference",
          requesttype: "cash_supplies_summary",
          user_id: Store_Emailid,
          store_name: value,
          user_name: username,
          month: date,
        }).then((res) => {
          setLoading(false);
          // console.log(res.data.data.result, "endingbalance");
          const val = res.data.data.result;
          setEndingBalance(val);
        });
      } catch (err) {
        if (err.response) {
          // The client was given an error response (5xx, 4xx)
          console.log("Error", err.response);
        } else if (err.request) {
          console.log("Error", err.request);
          // The client never received a response, and the request was never left
        } else {
          // Anything else
          console.log("Error", err.message);
        }
      }
    }
    Ending();
  };

  function getCurrentDate() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    return `${year}-${month}`;
}

  //<-------------Dropdown Fields--------------------->

  const StoreDrobdown = [
    {
      type: "dropdown",
      // label: "Store",
      className: "form-field-lg",
      style: { width: "200px" },
      showSearch: true,
      optionFilterProp: "children",
      filterOption: (input, option) =>
        (option?.value ?? "").toLowerCase().includes(input),
      filterSort: (optionA, optionB) =>
        (optionA?.value ?? "")
          .toLowerCase()
          .localeCompare((optionB?.value ?? "").toLowerCase()),
      placeholder: "Store",
      options: storeDrobDown,
      onChange: hanlde_store_name, // You need to define this function
      defaultValue: selectedValue,
    },
    {
      type: "daysdate",
      label: "Date",
      defaultValue: localStorage.getItem("date") ? defaultValue : date,
      format: monthFormat,
      onChange: (date, dateString) => handle_date(date, dateString), // You need to define this function
      allowClear: false,
      picker: "month",
      className: "form-field-lg",
    },
  ];

  //<-------------Input Fields--------------------->

  const AmountData = [
    {
      type: "input",
      label: "Petty Cash",
      placeholder: "Petty Cash",
      width: 200,
      className: "form-field-lg",
      value: startingBalance,
    },
    {
      type: "input",
      label: "Supply Cash",
      placeholder: "Supply Cash",
      width: 200,
      className: "form-field-lg",
      value: amount,

      required: true,
    },
    {
      type: "input",
      label: "Total Amount",
      placeholder: "Total Amount",
      width: 200,
      className: "form-field-lg",
      value: totalAmount,
    },
    {
      type: "input",
      label: "Ending Balance",
      placeholder: "Ending Balance",
      width: 200,
      className: "form-field-lg",
      value: endingBalance,
    },
  ];

  //<-------------Fetch Storename--------------------->
  useEffect(() => {
    async function StoreNameFetch() {
      try {
        await request("Post", {
          requesttype: "petty_cash_entry",
          op_type: "fetch_store_name",
        //   user_id:  "vijay.kumar@gtigrows.com",
        //   user_name:  "vijay.kumar@gtigrows.com",
          user_id: Store_Emailid,
          user_name: username,
          // "user_id": "greg.zambelli@gtigrows.com"
        }).then((res) => {
          const val = JSON.parse(res.data.data);
          const Store = [];
          val.map((res) => {
            if (res.store_name !== "") {
              Store.push({
                text: res.store_name,
                value: res.store_name,
              });
            }
            return Store;
          });
          setStoreDropDown(Store);
        });
      } catch (err) {
        if (err.response) {
          // The client was given an error response (5xx, 4xx)
          console.log("Error", err.response);
        } else if (err.request) {
          console.log("Error", err.request);
          // The client never received a response, and the request was never left
        } else {
          // Anything else
          console.log("Error", err.message);
        }
      }
    }
    StoreNameFetch();
  }, []);

  useEffect(() => {
    if (selectedValue) {
      hanlde_store_name(selectedValue);
    }
  }, []);

  useEffect(() => {
    if (storeName && date) {
      hanlde_store_name(storeName);
    } else if (selectedValue && date) {
      hanlde_store_name(selectedValue);
    }
    if (date) {
      handle_date(date, date);
    }
  }, [gettableData, date]);

  //<-------------NAvigate (cash-supply-expenses)--------------------->

  const hanlde_cash_supply_expenses = () => {
    localStorage.setItem("app_id", 13);
    navigate("/cash-supply-expenses");
  };

  //<-------------handle date dropdown--------------------->

  const handle_date = (date, dateString) => {
    localStorage.setItem("date", dateString.toString());
    if (!date) {
      setAmount("");
      setEndingBalance("");

      setStartingBalance("");
      setTotalAmount("");
      setFetchData([]);
    }

    if (dateString == "" || dateString == null || dateString == undefined) {
      localStorage.setItem("date", getCurrentDate());
      setDate(getCurrentDate());
    }
    localStorage.setItem("date", dateString.toString());
    setDate(dateString);
  };

  return (
    <div
      className="cpgsummary"
    
    >
     

      <div
      className="cpgsummaey__header"
      >
        <h3 className={DarkMode==="true"||DarkMode===true?"page-header-dark":"page-header"}>CASH SUPPLY SUMMARY</h3>
        <Button className="primary-btn" onClick={hanlde_cash_supply_expenses}>
          Cash Supply Expenses
        </Button>
      </div>

      <div>
        <>
          <section style={{ margin: "0.5rem 0 0.5rem 0" }}>
            <UserInfo fieldData={StoreDrobdown} />
          </section>

          <section>
            <h4 style={{padding:"0.5rem",fontSize:"16px",display:"flex",alignItems:"center",gap:"0.3rem",margin:"0.5rem 0 0.5rem 0",zIndex:"10"}}>
            <hr style={{border:"1px solid black",width:"5%",opacity:"0.1"}}/>
            <MoneyCollectTwoTone />
                Amount
                <hr style={{border:"1px solid black",width:"100%",opacity:"0.1"}}/>
                </h4>
                <div  className="data">
                <UserInfo fieldData={AmountData} />
               
                </div>
           
          </section>
        </>

        <br />

        <div className="data-table">
          <Table
            loading={loading}
            className="storekeymaster-table"
            dataSource={fetchData}
            columns={columns}
            bordered
            scroll={{ x: 1000, y: 440 }}
            pagination={{
              pageSize: 20,
              showSizeChanger: false,
            }}
          />
        </div>
        <br></br>
      </div>
    </div>
  );
}

export default CpgSummary;
