import React, { useContext } from 'react'
import "./Footer.css"
import { ThemeContext } from '../../../customHook/useContext/Usecontext';
const ticketMessage=false;
function Footer() {
  const { state } = useContext(ThemeContext);
  const DarkMode = state.darkMode;

return (
    <footer>
      
       <div className={DarkMode===true || DarkMode==="true" ?"footer-dark":"footer"}>

       <div>
                    {
                        ticketMessage === true ?
                            <p style={{ position: "absolute", bottom: "50px", padding: "20px", color: "red" }}>Please raise a FreshService Ticket to Get Access for any of this Application !!!</p>
                            : null
                    }
                  </div>
                  <div className={DarkMode===true || DarkMode==="true"?"footer_container-dark":"footer_container"}>
                  <p className="green-thumb-text">
                  © 2021 Green Thumb Industries (GTI) All Rights Reserved.</p>
              
                  <p className="app-version-text">App Version 1.85(28-02-2024)</p>                                                                                                                                                                                                                                                                                                                          
                  </div>
            
            </div>
        </footer>
  )
}

export default Footer