import { useContext, useEffect } from "react";
import "./ScrollIndicator.css"
import { useState } from "react";
import { ThemeContext } from "../../../customHook/useContext/Usecontext";

function ScrollIndicator(props) {
    const [scrollProgress, setScrollProgress] = useState(0);
    // const [darkMode, setDarkMode] = useState(localStorage.getItem("darkmode")==="true"?true:false);
    // const { state, dispatch } = useContext(ThemeContext);
    // const DarkMode = state.darkMode;
    const { DarkMode } = props;

    useEffect(() => {

        const handleScroll=()=>{
            const ScrollTop=document.documentElement.scrollTop;
    const ScrollHeight=document.documentElement.scrollHeight - document.documentElement.clientHeight
    const Scrolled=(ScrollTop/ScrollHeight)*100;
    setScrollProgress(Scrolled)
        }

  document.addEventListener("scroll",handleScroll)

  return()=>{
document.removeEventListener("scroll",handleScroll)
  }
        
      }, []);

      
      
     
  return (
    <div className="scroll-indicatorpage"       >
    <div  className={DarkMode==="true"||DarkMode===true?"scroll-inticator-dark":"scroll-inticator"}>
        <div  className={DarkMode==="true"||DarkMode===true?"scroll-inticator-progress-dark":"scroll-inticator-progress"}  style={{ width: `${scrollProgress}%` }}></div>
    </div>
    </div>
  )
}

export default ScrollIndicator