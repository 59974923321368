import { useContext, useEffect, useState } from "react";
import Button from "../../atoms/Button/Button";
import { Select } from "antd";
import { request } from "../../../customHook/request";
import Table from "../../atoms/Table/Table";
import { NavLink, useNavigate } from "react-router-dom";
import "./PurchaceOrder.css";

import Spinner from "../../atoms/spinner/Spinner";
import { ThemeContext } from "../../../customHook/useContext/Usecontext";
function PageOne({ onDropdownChange, onDataChange, InvoiceNo }) {
  const [storeFilter, setStoreFilter] = useState([]);
  const [fileFilter, setFileFilter] = useState([]);
  const [orderFilter, setOrderFilter] = useState([]);
  const [tableloader, setTableLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [vendorFilter, setVendorFilter] = useState([]);
  const [shipmentDateFilter, setShipmentDateFilter] = useState([]);
  const [fixedColumnLeft, setFixedColumnLeft] = useState("left");
  const [fixedColumnRight, setFixedColumnRight] = useState("right");
  const [tableData, setTableData] = useState([]);
  const { state } = useContext(ThemeContext);
  const DarkMode = state.darkMode;
  const { Option } = Select;
  const navigate = useNavigate();

  //remove table fixed column in mobile view
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1000) {
        setFixedColumnLeft("");

        setFixedColumnRight("");
      } else {
        setFixedColumnLeft("left");

        setFixedColumnRight("right");
      }
    };

    window.addEventListener("resize", handleResize);

    // Call handleResize initially to set the class based on the initial window width
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //fetch table datas
  useEffect(() => {
    setLoader(true);
    setTableLoader(true);
    async function fetchTableData() {
      try {
        const res = await request("post", {
          requesttype: "product_qty_update",
          message_key: "product_qty_update",
          op_type: "fetch_buyer_orders",
        });

        if (res !== "") {
          const data = res.data.data;
          if (data != null && data.length > 0) {
            const Data = JSON.parse(data);
            setTableData(Data);
            setTableLoader(false);
            setLoader(false);
          }
        }
      } catch (err) {
        setError(true);
        setTableLoader(false);
        setLoader(false);

        if (err.response) {
          console.log("Error", err.response);
        } else if (err.request) {
          console.log("Error", err.request);
        } else {
          console.log("Error", err.message);
        }
      } finally {
        setTableLoader(false);
        setLoader(false);
      }
    }

    fetchTableData();
    // setUpdate(update + 1)
  }, []);

  useEffect(() => {
    const extractUniqueValues = (key, setter) => {
      const uniqueValues = [
        ...new Map(tableData.map((item) => [item[key], item])).values(),
      ];
      const filteredValues = uniqueValues
        .filter((res) => res[key] !== "" && res[key] !== null)
        .map((res) => ({
          text: res[key],
          value: res[key],
        }));

      setter(filteredValues);
    };

    extractUniqueValues("store_name", setStoreFilter);
    extractUniqueValues("vendor_name", setVendorFilter);
    extractUniqueValues("shipment_date", setShipmentDateFilter);
    extractUniqueValues("file_name", setFileFilter);
    extractUniqueValues("order_date", setOrderFilter);
    // extractUniqueValues("catalog", setOrderFilter);
  }, [tableData]);

  //handle dropdown Onchange
  const handleSelectChange = (value, record) => {
    setTableLoader(true);
    localStorage.setItem("status", true);
    localStorage.setItem("invoiceno", record.buyer_inv_key);
    localStorage.setItem("buyerkey", record.buyer_inv_key);

    InvoiceNo(record.buyer_inv_key);

    if (value.toLowerCase() === "received") {
      onDropdownChange(true);
      async function fetchProductQuantity() {
        try {
          const res = await request("post", {
            requesttype: "product_qty_update",
            op_type: "received",
            message_key: "buyer_orders_qty_update",
            buyer_inv_key: record.buyer_inv_key,
          });

          if (res !== "") {
            const data = res.data.data;
            if (data != null && data.length > 0) {
              const Data = JSON.parse(data);
              setTableData(Data);
            }
            if (res.data.statuscode === 200) {
              onDataChange(JSON.parse(data));
              setTableLoader(true);
            }
          }
        } catch (err) {
          setTableLoader(false);
          if (err.response) {
            console.log("Error", err.response);
          } else if (err.request) {
            console.log("Error", err.request);
          } else {
            console.log("Error", err.message);
          }
        } finally {
          setTableLoader(false);
        }
      }

      fetchProductQuantity();
    }
  };

  //table columns-(header)
  const columns = [
    {
      key: "1",
      title: (
        <div style={{ maxHeight: "40px", overflow: "hidden" }}>Store Name</div>
      ),
      dataIndex: "store_name",
      fixed: fixedColumnLeft,
      width: 180,
      filters: storeFilter,
      filterMode: "tree",
      onFilter: (value, record) => record.store_name.startsWith(value),
      render: (text, record) => (
        <div
          style={{
            backgroundColor:
              record.buyer_inv_key == localStorage.getItem("buyerkey")
                ? "#ffc069"
                : "",
            padding: 0,
            width: "100%",
            height: "100%",
            display: "inline-block",
          }}
        >
          {text}
        </div>
      ),
    },

    {
      key: "2",
      title: (
        <div style={{ maxHeight: "40px", overflow: "hidden" }}>Vendor Name</div>
      ),
      dataIndex: "vendor_name",
      fixed: fixedColumnLeft,
      width: 180,
      filters: vendorFilter,
      filterMode: "tree",
      onFilter: (value, record) => record.vendor_name.startsWith(value),
    },
    {
      key: "3",
      title: (
        <div style={{ maxHeight: "40px", overflow: "hidden" }}>File Name</div>
      ),
      dataIndex: "file_name",
      fixed: fixedColumnLeft,
      filterMode: "tree",
      width: 150,
      filters: fileFilter,

      onFilter: (value, record) => record.file_name.startsWith(value),
      render: (text, record) => <p>{record.file_name}</p>,
    },
    {
      key: "4",
      title: (
        <div style={{ maxHeight: "40px", overflow: "hidden" }}>Order Date</div>
      ),
      dataIndex: "order_date",
      align: "center",
      width: 150,
      filters: orderFilter,
      filterMode: "tree",
      onFilter: (value, record) => record.order_date.startsWith(value),
      render: (text, record) => <p>{record.order_date}</p>,
    },
    {
      key: "5",
      title: (
        <div style={{ maxHeight: "40px", overflow: "hidden" }}>Total Items</div>
      ),
      dataIndex: "total_items",
      align: "center",
      width: 150,
      render: (text, record) => <p>{record.total_items}</p>,
    },
    {
      key: "6",
      title: (
        <div style={{ maxHeight: "40px", overflow: "hidden" }}>
          Invoice Total
        </div>
      ),
      dataIndex: "invoice_total",
      align: "center",

      width: 150,
      render: (text, record) => <p>{record.invoice_total}</p>,
    },
    {
      key: "7",
      title: (
        <div style={{ maxHeight: "40px", overflow: "hidden" }}>
          Shipment Date
        </div>
      ),
      dataIndex: "shipment_date",
      align: "center",
      width: 150,
      filters: shipmentDateFilter,
      filterMode: "tree",
      onFilter: (value, record) => record.shipment_date.startsWith(value),
      render: (text, record) => <p>{record.shipment_date}</p>,
    },
    {
      key: "8",
      title: <div style={{ maxHeight: "40px" }}>Completed Date</div>,
      dataIndex: "completed_date",
      width: 150,
      align: "center",
      render: (text, record) => <p>{record.completed_date}</p>,
    },
    {
      title: (
        <div style={{ maxHeight: "40px", overflow: "hidden" }}>
          Order Status
        </div>
      ),
      width: 200,
      key: "9",
      align: "center",
      fixed: fixedColumnRight,
      render: (text, record) => {
    
     return (  record.order_status == "Pending"?
     (
      // <input/>
      <>
            <Select
              key={record.invoice_no}
              onChange={(value) => handleSelectChange(value, record)}
              style={{ width: "150px" }}
              defaultValue={"Pending"}
             
              // ClassName="custom-dropdown"
            >
                
              <Option value="received">Received</Option>
           
            </Select>
       
            </>
          ): record.order_status?.toLowerCase() == "completed"?       (
            <Button
              style={{
                width: "150px",
                textAlign: "start",
                backgroundColor: "#045625",
                color: "#fff",
              }}
            >
              {record.order_status}
            </Button>
          ):record.order_status== "Ordered"?
          (
             <Select
               key={record.invoice_no}
               onChange={(value) => handleSelectChange(value, record)}
               style={{ width: "150px" }}
               defaultValue={"Ordered"}
             >
               <Option value="received">Received</Option>
             </Select>
           ):"")
       
        //   record.order_status?.toLowerCase()=== "ordered" ?
     
        
        //  (
        //     <Select
        //       key={record.invoice_no}
        //       onChange={(value) => handleSelectChange(value, record)}
        //       style={{ width: "150px" }}
        //       defaultValue={"Ordered"}
        //     >
        //       <Option value="received">Received</Option>
        //     </Select>
        //   ):
        
 
        // record.order_status?.toLowerCase() === "completed"?
          //  (
          //   <Button
          //     style={{
          //       width: "150px",
          //       textAlign: "start",
          //       backgroundColor: "#045625",
          //       color: "#fff",
          //     }}
          //   >
          //     {record.order_status}
          //   </Button>
          // )
        // :"")
      },
    },
  ];
  return (
    <>
      {/* <-------------App Name-----------> */}

      {loader === true ? (
        <Spinner />
      ) : error === true ? (
        navigate("error")
      ) : (
        <div >
     
          <div>

          <div className='header-session'>
                <h3 className={DarkMode==="true"||DarkMode===true?"page-header-dark":"page-header"}>PURCHASE ORDER</h3>
                <NavLink to="/fileupload">
                <Button label={"File Upload"} className={"third-btn"} />
              </NavLink>
            </div>






            <Table
              loading={tableloader}
              dataSource={tableData}
              columns={columns}
              
            />
          </div>
        </div>
      )}
    </>
  );
}

export default PageOne;
