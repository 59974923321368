import {Input} from "antd";

const TextArea = ({ onChange, rows, showCount, maxLength, readOnly ,value}) => {
  const { TextArea } = Input;

  return (
    <TextArea
      onChange={onChange}
      rows={rows}
      maxLength={maxLength}
      readOnly={readOnly}
      showCount={showCount}
      value={value}
      style={{
   
        resize: 'none',
      }}
      className="textarea"
    />
  );
};
export default TextArea;
