
import Header from '../../organisms/Header/Header';
import "./Template.css"
import Footer from '../../organisms/Footer/Footer';
import { QuestionCircleOutlined, CloseOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react';
import { FloatButton } from 'antd';
import { MdDarkMode } from "react-icons/md";
import { FaMagic } from "react-icons/fa";
import { ThemeContext } from '../../../customHook/useContext/Usecontext';
import Darkmode from '../../molecules/Darkmode/Darkmode';

const Template = ({children,appname}) => {
  const [open, setOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(localStorage.getItem("darkmode")==="true"?true:false);
  const { state, dispatch } = useContext(ThemeContext);
  const DarkMode = state.darkMode;

  const toggleDarkMode = () => {
    setOpen(!open);
    setDarkMode(!darkMode);
    localStorage.setItem("darkmode",!darkMode)
    dispatch({ type: "TOGGLE_DARK_MODE" });

  };
  const onChange = () => {
    setOpen(!open);
  };

return (
  <div className={DarkMode===true || DarkMode==="true"  ? "homepage-template-dark" :"homepage-template"}>

  <Header className="Header" appname={appname}  DarkMode={DarkMode}/>
  <main>
{children}
  <div>

</div>
  </main>
  {/* <div className="float-icons-container">
      <FloatButton
        icon={open ? <CloseOutlined onClick={onChange} /> : <FaMagic onClick={onChange} />}
        type="primary"
        style={{ right: 14 }}
      />

      {open===true && (
       <div className={`float-icons-container`}>
          <a href={localStorage.getItem("documenturl")} target="_blank" rel="noreferrer" >
          <FloatButton icon={<QuestionCircleOutlined />} type="primary" style={{ right: 58 }} />
          </a>
   
       <FloatButton
         icon={
           <MdDarkMode
             onClick={toggleDarkMode}
             className={`${DarkMode === false || DarkMode==="false" ? 'dark-mode' : 'not-dark-mode'}`}
           />
         }
         type="default"
         style={{ right: 104 }}
       />
     </div>
      )}
    </div> */}
    <Darkmode/>
  <Footer className="Footer" DarkMode={DarkMode} />
</div>

  );
};

export default Template;
