import './Button.css';
import { Popover, Avatar,Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { BiLogOut } from 'react-icons/bi'; 



const LogoutBtn = ({ label, onClick, loading, disabled, Icon,className,username}) => {
    const content = (
        <div className="custom-popover-content">
        <Avatar icon={<UserOutlined />} className="avatar-icon" />
        <span className="user-name">{username}</span>
      </div>
      );
  return (
    <Popover content={content} trigger="hover"  placement="bottomRight">
  <Button onClick={onClick} className='logot' >
 <BiLogOut className='logout-icon'/> Logout
  </Button>
    
    </Popover>
  );
};

export default LogoutBtn;
