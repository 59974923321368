import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Input, Checkbox } from "antd";
import Button from "../../atoms/Button/Button";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Table from "../../atoms/Table/Table";
import { useNavigate } from "react-router-dom";
import { base_url } from "../../../api/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Spinner from "../../atoms/spinner/Spinner";
import AntCard from "../../atoms/Card/Card";
import { ThemeContext } from "../../../customHook/useContext/Usecontext";

function PageTwo({ data, InvoiceNo, onDropdownChange }) {
  const [filter, setFilter] = useState([]);
  const [isChange, setIsChange] = useState(false);
  const [fixedColumnLeft, setFixedColumnLeft] = useState("left");
  const [fixedColumnRight, setFixedColumnRight] = useState("right");
  const { state } = useContext(ThemeContext);
  const DarkMode = state.darkMode;
  const [verifyLoading, setVerifyLoading] = useState(false);

  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);

  const [storeNameData, setStoreNameData] = useState([]);

  const [checkedRows, setCheckedRows] = useState([]);

  const [updatedData, setUpdatedData] = useState(0);
  const navigate = useNavigate();

//remove table fixed column in mobile view
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1000) {
        setFixedColumnLeft("");

        setFixedColumnRight("");
      } else {
        setFixedColumnLeft("left");

        setFixedColumnRight("right");
      }
    };

    window.addEventListener("resize", handleResize);

    // Call handleResize initially to set the class based on the initial window width
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //get storename from props
  useEffect(() => {
    setStoreNameData(data);
  }, [data]);

  //fetch table datas
  useEffect(() => {
    if(isChange===false){
      setLoader(true);
      setTableLoader(true);
    }
  
    async function fetchProductQuantity() {
      try {
        const res = await axios.post(`${base_url}`, {
          requesttype: "product_qty_update",
          op_type: "received",
          message_key: "buyer_orders_qty_update",
          buyer_inv_key: localStorage.getItem("invoiceno")
            ? localStorage.getItem("invoiceno")
            : InvoiceNo,
        });

        if (res !== "") {
          const data = res.data.data;
          if (data != null && data.length > 0) {
            const storeNameData = JSON.parse(data);

            // Filter unique records based on record_seq
            const uniqueRecords = Array.from(
              new Set(storeNameData.map((record) => record.record_seq))
            ).map((recordSeq) =>
              storeNameData.find((record) => record.record_seq === recordSeq)
            );

            setStoreNameData(uniqueRecords);
            setLoader(false);
            setTableLoader(false);
          }
        }
      } catch (err) {
        setError(true);
        setTableLoader(false);
        setLoader(false);
        if (err.response) {
          console.log("Error", err.response);
        } else if (err.request) {
          console.log("Error", err.request);
        } else {
          console.log("Error", err.message);
        }
      } finally {
        setTableLoader(false);
        setLoader(false);
      }
    }

    fetchProductQuantity();
  }, [updatedData, InvoiceNo]);

  useEffect(() => {
    const filteredData = storeNameData.filter((data) => {
      return true;
    });

    setFilter(filteredData);
  }, [storeNameData]);

  //handle input value change(Quantity)
  const handleInputChange = (e, id, record, dataIndex) => {
    const value = e.target.value;

    if (!filter) {
      const updatedData = filter.map((item) => {
        if (item.record_seq === id) {
          const hasChanged = item.qty !== value;
          if (hasChanged) {
            setCheckedRows(checkedRows.filter((rowId) => rowId !== id));
            item.is_update = false; // Set is_update to false
          }
          return { ...item, [dataIndex]: value };
        }
        return item;
      });
      setFilter(updatedData);
    }

    if (filter) {
      const updatedData = filter.map((item) => {
        if (item.record_seq === id) {
          const hasChanged = item.qty !== value;
          if (hasChanged) {
            setCheckedRows(checkedRows.filter((rowId) => rowId !== id));
            item.is_update = false; // Set is_update to false
          }
          return { ...item, [dataIndex]: value };
        }
        return item;
      });
      setFilter(updatedData);
    }

    setCheckedRows(checkedRows.filter((rowId) => rowId !== id));
  };

  //handle verify function (check all quantity updates)
  const handle_verify = () => {
    setVerifyLoading(true);

    async function Verify() {
      try {
        let res = await axios.post(`${base_url}`, {
          requesttype: "product_qty_update",
          message_key: "buyer_orders_qty_update",
          op_type: "fetch_buyer_verify",
          buyer_inv_key:InvoiceNo || localStorage.getItem("invoiceno"),
        });

        if (res.data.statuscode === 200) {
          VerifySuccess();
          setVerifyLoading(false);
          setTimeout(() => {
            localStorage.setItem("status", false);
            onDropdownChange(false);
          }, 2000);
        }

        if (res.data.statuscode === 404) {
          VerifyFailed();
          setVerifyLoading(false);
        }
      } catch (err) {
        if (err.response) {
          // The client was given an error response (5xx, 4xx)
          console.log("Error", err.response);
          setVerifyLoading(false);

          Error(err.response);
        } else if (err.request) {
          console.log("Error", err.request);
          // The client never received a response, and the request was never left
          setVerifyLoading(false);

          Error(err.request);
        } else {
          // Anything else
          console.log("Error", err.message);
          setVerifyLoading(false);

          Error(err.message);
        }
      }
    }

    Verify();
  };

  //handle chechox(update quantity)
  const handleCheckboxChange = (e, id, records) => {
    const checked = e.target.checked;
    setIsChange(true)
    const dataqty = storeNameData.find((item) => item.record_seq === id);

    if (checked) {
      const checkvalue = [...checkedRows, id];
      setCheckedRows(checkvalue);
     
      let dataToUpdate = [];
      if (filter) {
        dataToUpdate = filter.filter((record) =>
          checkvalue.includes(record.record_seq)
        );
      } else {
        dataToUpdate = filter.filter((record) =>
          checkvalue.includes(record.record_seq)
        );
      }

      const lastCheckedRowId = checkvalue[checkvalue.length - 1];
   

      Promise.all(
        dataToUpdate.map((record) => {
          if (record.record_seq !== lastCheckedRowId) {
            return Promise.resolve({ ...record, is_update: false });
          }

          let adjustedQty;
          if (record.adjusted_qty !== null) {
            adjustedQty = parseInt(record.adjusted_qty);
          } else {
            adjustedQty = parseInt(record.orig_qty);
          }

          return axios
            .post(`${base_url}`, {
              requesttype: "product_qty_update",
              message_key: "buyer_orders_qty_update",
              op_type: "update",
              record_seq: record.record_seq,
              buyer_inv_key: record.buyer_inv_key,
              gm_percent: record.gm_percent,
              product_name: record.product_name,
              cost: record.cost,
              file_name: record.file_name,
              is_update: true,
              order_status: "Pending",
              packages: record.packages,
              product_weight: record.product_weight,
              retail_price: record.retail_price,
              orig_qty: parseInt(dataqty.orig_qty),
              adjusted_qty: adjustedQty,
              updated_dt: new Date().toISOString(),
            })

            .then((res) => {
              if (res.status === 200) {
                setUpdatedData(updatedData + 1);
              }
              if (res.status === 404) {
                Failed();

                setUpdatedData(updatedData + 1);
                localStorage.setItem("invoiceno", InvoiceNo);
              }

              return { ...record, is_update: false };
            })
            .catch((err) => {
              console.log("Error:", err);
              Failed();
              return { ...record, is_update: false };
            });
        })
      )
        .then((updatedData) => {
          // console.log("All updates completed successfully.",updatedData);
        })
        .catch((err) => {
          console.log("Error updating data: ", err);
        });
    } else {
      setCheckedRows(checkedRows.filter((rowId) => rowId !== id));
    }
  };

  //table columns-(header)
  const columns = [
    {
      key: "1",
      title: "Product Name",
      dataIndex: "product_name",
      fixed: fixedColumnLeft,
      width: 150,
      render: (text, record) => <p>{record.product_name}</p>,
    },
    {
      key: "2",
      title: "Product Weight",
      align: "center",
      dataIndex: "product_weight",
      fixed: fixedColumnLeft,
      width: 150,
      render: (text, record) => <p>{record.product_weight}</p>,
    },
    {
      key: "3",
      title: "Packages",

      dataIndex: "product_cnt",
      fixed: fixedColumnLeft,
      align: "center",
      width: 200,
      render: (text, record) => <p>{record.packages}</p>,
    },

    {
      key: "4",
      title: "Quantity",
      align: "center",
      dataIndex: "quantity",
      fixed: fixedColumnLeft,
      width: 100,
      render: (text, record) => (
        <div>
          {record.adjusted_qty == null ? (
            <Input
              value={record.orig_qty}
              onChange={(e) =>
                handleInputChange(e, record.record_seq, record, "orig_qty")
              }
            />
          ) : (
            <Input
              value={record.adjusted_qty}
              onChange={(e) =>
                handleInputChange(e, record.record_seq, record, "adjusted_qty")
              }
            />
          )}
        </div>
      ),
    },

    {
      key: "5",
      title: "Cost",
      dataIndex: "cost",
      align: "center",
      width: 100,
      render: (text, record) => <p>{record.cost}</p>,
    },

    {
      key: "6",
      title: "Retail Price",
      dataIndex: "retail_price",
      align: "center",
      width: 150,
      render: (text, record) => <p>{record.retail_price}</p>,
    },

    {
      key: "7",
      title: "Promo",
      dataIndex: "4/20 promo",
      align: "center",
      width: 100,
    },
    {
      key: "8",
      title: "Gm Percent",
      dataIndex: "gm_percent",
      align: "center",
      width: 150,
      render: (text, record) => <p>{record.gm_percent}</p>,
    },

    {
      key: "9",
      title: "Verify Date",
      dataIndex: "row_upd_dt",
      align: "center",
      width: 200,
      render: (text, record) => <p>{record.row_upd_dt}</p>,
    },

    {
      key: "10",
      title: <p style={{ width: "150px" }}>Update</p>,
      dataIndex: "is_update",
      align: "center",
      fixed: fixedColumnRight,
      width: 150,

      render: (text, record) => (
        <Checkbox
          loading={true}
          checked={record.is_update?true:false }
          onChange={(e) => handleCheckboxChange(e, record.record_seq, record)}
        />
      ),
    },
  ];

  //handle messages(success and failed)
  const Failed = () => {
    const notify = () => toast.error("Data updated Failed!");
    notify();
  };

  const VerifySuccess = () => {
    const notify = () => toast.success("Data Verified successfuly!");
    notify();
  };

  const VerifyFailed = () => {
    const notify = () => toast.error("Data still exists!");
    notify();
  };

  const Error = (error) => {
    console.log(error, "errrw");
    const notify = () => toast.error(error.statusText);
    notify();
  };

  //back-btn-(navigate to ordered page)
  const handleGoBack = () => {
    localStorage.removeItem("status");
    localStorage.removeItem("invoiceno");
    setFilter([]);
    onDropdownChange(false);
  };

  const generateLabelValue = (label, value) => {
    if (value) {
      return (
        <p className="label">
          {label} : <span className="data">{value}</span>
        </p>
      );
    }
    return null; // If value is falsy (undefined, null, empty string), don't render anything
  };

  return (
    <div className="received-page">
      {loader === true ? (
        <Spinner />
      ) : error === true ? (
        navigate("error")
      ) : (
        <div className="data-table">
          <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />

          <div className="header-backbtn">
            <div className="previous-btn">
              <Button
                className={"primary-btn back-btn "}
                Icon={ArrowLeftOutlined}
                onClick={handleGoBack}
              />
            </div>

            <h3 className="receivedpage-header">
              <span className={DarkMode==="true"||DarkMode===true?"page-header-dark":"receivedpage-header"}>PURCHASE ORDER </span>
            </h3>
          </div>
          <div className="thirdparty-header-container">
            <AntCard
              content={
                <div className={"purchase-order-datas"}>
                  {generateLabelValue(
                    "StoreName",
                    storeNameData[0]?.store_name
                  )}
                  {generateLabelValue(
                    "VendorName",
                    storeNameData[0]?.vendor_name
                  )}
                  {generateLabelValue("FileName", storeNameData[0]?.file_name)}
                  <Button
                    label={"Verify"}
                    loading={verifyLoading}
                    onClick={handle_verify}
                    className={"primary-btn"}
                  />
                </div>
              }
            ></AntCard>
          </div>

          <div>
            <Table
              dataSource={filter}
              columns={columns}
              bordered
              className={"purchase-order-table"}
              loading={tableLoader}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default PageTwo;
