// src/components/molecules/FormField.js
import "./FormInput.css"
import Input from '../../atoms/Input/Input';

const FormInput = ({ label, placeholder, value, onChange,className,type,inputType,disabled ,defaultValue,max,count}) => {
  
  return (
    <div className='Form-label-field'>
      <label className='label'>{label}</label>
      <div >

        {type==="paragraph"?(
        <p  className='fin-value'>{value} </p>
          ):
          (
            <Input disabled={disabled} 
            inputType={inputType}
             placeholder={placeholder} value={value} 
             onChange={onChange} className={className}  
             defaultValue={defaultValue}  
             max={max}
             count={count}/>
          )
          
          }
     
      </div>
   
    </div>
  );
};

export default FormInput;
