import React, { useContext, useEffect, useState } from 'react'
import { DeleteOutlined, EditOutlined, SearchOutlined, InfoCircleFilled } from '@ant-design/icons';
import { useMsal } from '@azure/msal-react';
import { Space, Form, message } from 'antd';
import { useRef } from 'react';
import Highlighter from 'react-highlight-words';
import moment from 'moment'
import './userAdministration.css'
import { useNavigate } from 'react-router';
import { request } from '../../../customHook/request';
import DynamicModal from '../../organisms/DynamicModel/DynamicModel';
import Table from '../../atoms/Table/Table';
import Input from '../../atoms/Input/Input';
import Button from '../../atoms/Button/Button';
import { UserInfo } from '../../organisms/UserInfo/UserInfo';
import { ThemeContext } from '../../../customHook/useContext/Usecontext';

function UserAdministration() {


    const [addRecord, setAddRecord] = useState({
        userName: "",
        emailId: "",
        department: "",
        appSecurity: "",
        applicationName: "",
        newApplicationName: "",
        location: "",
        dimStoreKey: "0",
        newDimStoreKey: "0",
        appId: "",
    })

    const [editRecord, setEditRecord] = useState({
        userName: "",
        emailId: "",
        department: "",
        appSecurity: "",
        applicationName: "",
        newApplicationName: "",
        location: "",
        dimStoreKey: "0",
        newDimStoreKey: "0",
        appId: "",
        userId: "",
    })


    const [fetchUserDetails, setFetchUserDetails] = useState([])
    const [storeNameDisable, setStoreNameDisable] = useState(true)

    const [departmentDropdown, setDepartmentDropdown] = useState("")
    const [applicationDropdown, setApplicationDropdown] = useState([])
    const [storeDropdown, setStoreDropdown] = useState([])
    const [appSecurityDropDown, setAppSecurityDropDown] = useState([])

    const [saveLoading, setSaveLoading] = useState(false)
    const [updateLoading, setUpdateLoading] = useState(false)
    const [isModalAdd, setIsModalAdd] = useState(false)
    const [isModalEdit, setIsModalEdit] = useState(false)
    const [loading, setLoading] = useState(true)
    const { accounts } = useMsal();
    const username = accounts[0] && accounts[0].name;
    const Store_Emailid = accounts[0] && accounts[0].username;
    const [pageLoader, setPageLoader] = useState(false);
    const [error, setError] = useState("")
    const [locationDisabled,setLocationDisabled] = useState(false)
    const { state } = useContext(ThemeContext);
    const DarkMode = state.darkMode;
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                    maxWidth: "20rem"
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >

                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                        label={"Search"}
                        Icon={SearchOutlined}
                        className={"third-btn"}
                    >

                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                        label={"Reset"}
                        className={"third-btn"}
                    >

                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                        label={"close"}
                        className={"third-btn"}
                    >

                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (

            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });


    const columns = [
        {
            key: '1',
            title: "Email ID",
            dataIndex: 'email_id',
            // fixed: "left",
            width: 250,
            ...getColumnSearchProps('email_id'),
        },
        {
            key: '2',
            title: "Application Name",
            align: 'center',
            dataIndex: 'application_name',
            // fixed: "left",
            width: 240,
        },
        {
            key: '4',
            title: "Department",
            align: 'center',
            dataIndex: 'department',
            // fixed: "left",
            width: 140,
        },
        {
            key: '5',
            title: 'Store Name',
            dataIndex: 'store_name',
            width: 200,
            align: "center",
        },
        {
            key: '6',
            title: 'App Security',
            dataIndex: 'app_security',
            width: 200,
            align: "center",
        },
        {
            key: '7',
            title: 'Actions',
            fixed: "right",
            width: 100,
            render: (record) => {
                return <>
                    <div className='action-button'>
                        <EditOutlined
                            onClick={() => handle_edit(record)}
                            className='table-icon' />
                        <DeleteOutlined
                            onClick={() => handle_delete(record)}
                            className='table-delete-icon' />
                    </div>
                </>
            },
        },
    ];

    const UserData = [
        {
            type: "input",
            label: "User Name",
            placeholder: "User Name",
            width: 200,
            className: "form-field-lg",
            value: addRecord.userName,
            onChange: (e) => { setAddRecord({ ...addRecord, userName: e.target.value }) }
        },

        {
            type: "input",
            label: "Email Id",
            placeholder: "Email Id",
            width: 200,
            className: "form-field-lg",
            value: addRecord.emailId,
            onChange: (e) => { setAddRecord({ ...addRecord, emailId: e.target.value }) }
        },

        {
            type: "dropdown",
            label: "Department",
            placeholder: "Department",
            width: 200,
            className: "form-field-lg",
            options: departmentDropdown,
            value: addRecord.department,
            onChange: (value) => { setAddRecord({ ...addRecord, department: value }) }
        },

        {
            type: "dropdown",
            label: "App Security",
            placeholder: "App Security",
            width: 200,
            className: "form-field-lg",
            options: appSecurityDropDown,
            value: addRecord.appSecurity,
            onChange: (value) => { setAddRecord({ ...addRecord, appSecurity: value }) }
        },

        {
            type: "dropdown",
            label: "Application Name",
            placeholder: "Application Name",
            width: 200,
            className: "form-field-lg",
            options: applicationDropdown,
            value: addRecord.applicationName,
            onChange: (value) => handle_application_name(value),
          
        },

        {
            type: "dropdown",
            label: "Location",
            placeholder: "Location",
            width: 200,
            className: "form-field-lg",
            options: storeDropdown,
            value: addRecord.location,
            onChange: (value) => handle_store_name(value),
            disabled:locationDisabled


        },
        {
            type: "button",
            className: "primary-btn",
            label: "Save",
            name: "Save",
            valuePropName: "checked",
            loading: saveLoading,
            wrapperCol: {
                offset: 0,
                span: 24,
            },
            onClick: () => handle_save(),
        },

        {
            className: "bottom-btn",
            type: "button",
            label: "Cancel",
            name: "Cancel",
            valuePropName: "checked",
            wrapperCol: {
                offset: 0,
                span: 24,
            },
            onClick: () => setIsModalAdd(false),
        },
    ]

    const UserDataEdit = [
        {
            type: "input",
            label: "User Name",
            placeholder: "User Name",
            width: 200,
            className: "form-field-lg",
            value: editRecord.userName,
            onChange: (e) => { setEditRecord({ ...editRecord, userName: e.target.value }) }
        },

        {
            type: "input",
            label: "Email Id",
            placeholder: "Email Id",
            width: 200,
            className: "form-field-lg",
            value: editRecord.emailId,
            onChange: (e) => { setEditRecord({ ...editRecord, emailId: e.target.value }) }
        },

        {
            type: "dropdown",
            label: "Department",
            placeholder: "Department",
            width: 200,
            className: "form-field-lg",
            options: departmentDropdown,
            value: editRecord.department,
            onChange: (value) => { setEditRecord({ ...editRecord, department: value }) }
        },

        {
            type: "dropdown",
            label: "App Security",
            placeholder: "App Security",
            width: 200,
            className: "form-field-lg",
            options: appSecurityDropDown,
            value: editRecord.appSecurity,
            onChange: (value) => { setEditRecord({ ...editRecord, appSecurity: value }) }
        },

        {
            type: "dropdown",
            label: "Application Name",
            placeholder: "Application Name",
            width: 200,
            className: "form-field-lg",
            options: applicationDropdown,
            value: editRecord.newApplicationName,
            disabled: storeNameDisable,
            onChange: (value) => handle_application_name(value)
        },

        {
            type: "dropdown",
            label: "Location",
            placeholder: "Location",
            width: 200,
            className: "form-field-lg",
            options: storeDropdown,
            value: editRecord.location,
            disabled: storeNameDisable,
            onChange: (value) => handle_store_name(value)
        },
        {
            type: "button",
            className: "primary-btn",
            label: "Update",
            name: "Update",
            valuePropName: "checked",
            loading: updateLoading,
            wrapperCol: {
                offset: 0,
                span: 24,
            },
            onClick: () => handle_update(),
        },

        {
            className: "bottom-btn",
            type: "button",
            label: "Cancel",
            name: "Cancel",
            valuePropName: "checked",
            wrapperCol: {
                offset: 0,
                span: 24,
            },
            onClick: () => setIsModalEdit(false),
        },
    ]

    const FetchDetails = async () => {
        try {
            const response = await request("Post", {
                "requesttype": "fetch_user_details"
            })
            let res = await JSON.parse(response.data.data);
            setFetchUserDetails(res)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setError(true)
            setPageLoader(false);
            console.error("Error fetching data:", error.message);
        } finally {
            setLoading(false)
            setPageLoader(false);
        }
    }

    const FetchDepartment = async () => {
        try {
            const response = await request("Post", {
                "requesttype": "fetch_department",
                "user_id": Store_Emailid,
                "user_name": username
            })
            let department = await JSON.parse(response.data.data);
            let department_dropdown = []
            if (response.data.statuscode === 200) {
                department.map((res) => {
                    if (res.department !== "") {
                        department_dropdown.push({
                            text: res.department,
                            value: res.department
                        })
                    }
                    return department_dropdown
                })
                setDepartmentDropdown(department_dropdown)
            }

        } catch (error) {
            setError(true)
            console.error("Error fetching data:", error.message);
        }
    }

    const FetchAppSecurity = async () => {
        try {
            const response = await request("Post", {
                "requesttype": "fetch_app_security",
                "user_id": Store_Emailid,
                "user_name": username
            })
            let app_security = await JSON.parse(response.data.data);
            let app_security_dropdown = []
            if (response.data.statuscode === 200) {
                app_security.map((res) => {
                    if (res.app_security !== "") {
                        app_security_dropdown.push({
                            text: res.app_security,
                            value: res.app_security
                        })
                    }
                    return app_security_dropdown
                })
                setAppSecurityDropDown(app_security_dropdown)
            }

        } catch (error) {
            setError(true)
            console.error("Error fetching data:", error.message);
        }
    }

    const FetchApplicationName = async () => {
        try {
            const response = await request("Post", {
                "requesttype": "fetch_application_name",
                "user_id": Store_Emailid,
                "user_name": username
            })
            let application_name = await JSON.parse(response.data.data);
            let application_name_dropdown = []
            if (response.data.statuscode === 200) {
                application_name.map((res) => {
                    if (res.application_name !== "") {
                        application_name_dropdown.push({
                            text: res.app_name,
                            value: res.app_name
                        })
                    }
                    return application_name_dropdown
                })
                setApplicationDropdown(application_name_dropdown)
            }

        } catch (error) {
            setError(true)
            console.error("Error fetching data:", error.message);
        }
    }

    const handle_application_name = async (value) => {
        setAddRecord((prevAddRecord) => ({
            ...prevAddRecord,
            applicationName: value,
            newApplicationName: value,
        }));

        setEditRecord((prevEditRecord) => ({ ...prevEditRecord, newApplicationName: value }))

        // if (value === "Closing Comments App" || value === "Cash/Supply Expenses") {
        // }
        //  else if (value === "Master CPG Entry") {
        //     setAddRecord((prevAddRecord) => ({
        //         ...prevAddRecord,
        //         dimStoreKey: 0,
        //         newDimStoreKey: 0,
        //     }));
        // } else {
        //     setAddRecord((prevAddRecord) => ({
        //         ...prevAddRecord,
        //         dimStoreKey: 0,
        //         newDimStoreKey: 0,
        //     }));
        // }

        try {
            const res = await request("Post", {
                requesttype: "fetch_store_name",
                user_id: Store_Emailid,
                user_name: username,
                application_name: value,
            });

            const Store_name = [];
            const statuscode = res.data.statuscode;

            if (statuscode === 200) {
                const app_id_data = res.data.app_id_data;
                const store_name_data = res.data.store_name_data;
const LocationDisable= res.data.store_name_data.statuscode;

if(LocationDisable===200){
    setLocationDisabled(false)
 
}
else{
    setLocationDisabled(true)
}


                if (app_id_data && app_id_data.data) {
                    const app_id = JSON.parse(app_id_data.data);
                    app_id.forEach((app) => {
                        if (app.app_id !== "") {
                            setAddRecord((prevAddRecord) => ({
                                ...prevAddRecord,
                                appId: app.app_id,
                            }));
                            setEditRecord((prevEditRecord) => ({
                                ...prevEditRecord,
                                appId: app.app_id,
                            }));
                        }
                    });
                }

                if (store_name_data && store_name_data.data) {
                    const Store = JSON.parse(store_name_data.data);
                    if (Array.isArray(Store)) {
                        Store.forEach((store) => {
                            if (store && store.store_name !== "") {
                                Store_name.push({
                                    text: store.store_name,
                                    value: store.store_name,
                                });
                            }
                        });
                    }
                    setStoreDropdown(Store_name);
                }
            } else {
                // Handle other status codes or error cases
            }
        } catch (error) {
            setError(true);
            console.error("Error fetching data:", error.message);
        }
    };

    const handle_store_name = async (value) => {
        setAddRecord({ ...addRecord, location: value })
        setEditRecord({ ...editRecord, location: value })
        try {
            const response = await request("Post", {
                "requesttype": "fetch_store_key",
                "user_id": Store_Emailid,
                "user_name": username,
                "store_name": [value]
            })
            let store_name = JSON.parse(response.data.data)
            const storekey = []
            if (response.data.statuscode === 200) {
                store_name.map((res) => {
                    if (res.dim_store_key !== "") {
                        // setDimStoryKey(res.dim_store_key)
                        // setNewDimStoryKey(res.dim_store_key)
                        // setNewDimStoreKeyEdit(res.dim_store_key)
                        setAddRecord((prevAddRecord) => ({ ...prevAddRecord, dimStoreKey: res.dim_store_key, newDimStoreKey: res.dim_store_key }))
                        setEditRecord({ ...editRecord, newDimStoreKey: res.dim_store_key })
                    }
                    return storekey
                })
            }

        } catch (error) {
            setError(true)
            console.error("Error fetching data:", error.message);
        }
    }

    const handle_save = async () => {
        setSaveLoading(true)
        try {
            const response = await request("Post", {
                "requesttype": "form_user_details_procedure",
                "op_type": "new",
                "user_id": Store_Emailid,
                "user_name": addRecord.userName,
                "email_id": addRecord.emailId,
                "department": addRecord.department,
                "application_name": addRecord.applicationName,
                "store_key": addRecord.dimStoreKey,
                "new_store_key": addRecord.newDimStoreKey,
                "app_id": addRecord.appId,
                "app_security": addRecord.appSecurity,
                "new_application_name": addRecord.newApplicationName,
            })
            if (response.data.statuscode === 200) {
                setSaveLoading(false)
                setLoading(false)
                message.success("User Data Saved Successfully")
                setIsModalAdd(false)
                FetchDetails()
                handle_clear()
            }
            else if (response.data.statuscode === 404) {
                setSaveLoading(false);
                message.error("This Email Id is already taken");
            }
            else if (response.data.statuscode === 500) {
                setSaveLoading(false);
                message.warning("An error occurred during data processing. Try again.")
        
            }
            else{
                setSaveLoading(false);
                message.warning("User Data not added. Try again.");
            }
        } catch (error) {
            setError(true)
            setLoading(false)
            setSaveLoading(false)
            message.warning("User Data not added. Try again.")
            console.error("Error fetching data:", error.message);
        } finally {
            setSaveLoading(false)
            setLoading(false)
        }
    }


    const handle_update = async () => {
       
        setLoading(true)
        setUpdateLoading(true)
        try {
            const res = await request("Post", {
                "requesttype": "form_user_details_procedure",
                "op_type": "update",
                "user_id": editRecord.userId,
                "user_name": editRecord.userName,
                "application_name": editRecord.applicationName,
                "store_key": editRecord.dimStoreKey,
                "new_store_key": editRecord.dimStoreKey,
                "department": editRecord.department,
                "email_id": editRecord.emailId,
                "app_id": editRecord.appId,
                "app_security": editRecord.appSecurity,
                "new_application_name": editRecord.newApplicationName,
            })
            if (res.data.statuscode === 200) {
                message.success("User Data Updated Successfully")
                setIsModalEdit(false)
                setUpdateLoading(false)
                setLoading(false)
                FetchDetails()
            }
            else if (res.data.statuscode === 500) {
                setLoading(false)
                message.warning("An error occurred during data processing. Try again.")
            }
            else {
                setUpdateLoading(false)
                setLoading(false)
                message.warning("User Data not Updated. Try again.")
            }
        } catch (error) {
            setError(true)
            setLoading(false)
            setUpdateLoading(false)
            message.warning("User Data not Updated.Try again.")
            console.error("Error fetching data:", error.message);
        } finally {
            setUpdateLoading(false)
            setLoading(false)
        }
    }

    const handle_delete = async (value) => {
        setLoading(true)
        try {
            const response = await request("Post", {
                "requesttype": "delete_user_details",
                "user_name": username,
                "user_id": Store_Emailid,
                "store_key": value.dim_store_key,
                "application_name": value.application_name,
                "email_id": value.email_id,
                "location_id": value.location_id
            })
            let res = await JSON.parse(response.data.statuscode);
            if (res === 200) {
                message.success("Data Deleted Successfully")
                setLoading(false)
                FetchDetails()
            }
        } catch (error) {
            setError(true)
            setLoading(false)
            message.warning("Data Not Deleted")
            console.error("Error fetching data:", error.message);
        } finally {
            setLoading(false)
        }
    }

    const handle_edit = (value) => {
     
        setIsModalEdit(true)
        setEditRecord((prevEditRecord) => ({ ...prevEditRecord, dimStoreKey: "0", newDimStoreKey: "0" }))
        if (value.dim_store_key === null) {
            setEditRecord((prevEditRecord) => ({ ...prevEditRecord, dimStoreKey: "0", newDimStoreKey: "0" }))
        }
        else {
            setEditRecord((prevEditRecord) => ({ ...prevEditRecord, dimStoreKey: value.dim_store_key, newDimStoreKey: value.dimStoreKeyEdit }))
        }

        // if (value.application_name === "Closing Comments App") {

        // }
        // if (value.application_name === "Cash/Supply Expenses") {

        // }
        // if (value.application_name === "Master CPG Entry") {

        // }
        // else {

        // }

        setEditRecord((prevEditRecord) => ({
            ...prevEditRecord,
            userName: value.user_name,
            emailId: value.email_id,
            department: value.department,
            applicationName: value.application_name,
            newApplicationName: value.application_name,
            location: value.store_name,
            appSecurity: value.app_security,
            appId: value.app_id,
            userId: value.user_id
        }))
    }

    useEffect(() => {
        FetchDetails()
        FetchDepartment()
        FetchAppSecurity()
        FetchApplicationName()
    }, [])

    const handle_add_record = () => {
        setIsModalAdd(true)
        handle_clear()
    }

    const handle_clear = () => {
        setAddRecord({
            userName: "",
            emailId: "",
            department: "",
            appSecurity: "",
            applicationName: "",
            newApplicationName: "",
            location: "",
            dimStoreKey: "0",
            newDimStoreKey: "0",
            appId: "",
        })
    }


    return (
        <div className='storekeymaster-container'>
            <DynamicModal visible={isModalAdd} content={<UserInfo fieldData={UserData} />} title={"Add Record"} onClose={() => { setIsModalAdd(false); handle_clear() }} width={1100} />
            <DynamicModal visible={isModalEdit} content={<UserInfo fieldData={UserDataEdit} />} title={"Edit Record"} onClose={() => setIsModalEdit(false)} width={1100} />
            <div className='header-session'>
                <h3 className={DarkMode==="true"||DarkMode===true?"page-header-dark":"page-header"}>USER ADMINISTRATION</h3>
                <Button
                    onClick={() => handle_add_record()}
                    label={"Add Record"}
                    className={"third-btn"}
                />
            </div>

            <div>
                <Table loading={loading} dataSource={fetchUserDetails} columns={columns} />
            </div>

        </div>
    )
}

export default UserAdministration