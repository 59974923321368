import React from 'react';
import './Button.css';
import { Button as AntButton } from 'antd';
import AntIcon from "../Icon/Icon"

const Button = ({ label, onClick, loading, disabled, Icon, IconSrc, className }) => {
  return (
    <AntButton

      className={className}
      onClick={onClick}
      loading={loading}
      disabled={disabled}
    >
      <div className={className}>

   
      {Icon ? <Icon /> : (IconSrc ? <AntIcon IconSrc={IconSrc}  /> : null)} {label}
      </div>
    </AntButton>
  );
};

export default Button;
