import { request } from "./request";

export const fetchOptions = async (requestype) => {
    try {
      const res = await request("Post",requestype );
      
      const storeNameOptions = await JSON.parse(res.data.stores);
  
      if (storeNameOptions) {
  
        let DropdownValues = [];

        storeNameOptions.map((res) => {
          DropdownValues.push({
            value: res.store_name,
            label: res.store_name,
            storekey: res.dim_store_key,
          });

          return DropdownValues;
        });
  
  
     
        return { res, DropdownValues }; 
      
    } 
  }catch (err) {
      console.log(err);
      return { error: err };
    }
  };