import React,{useState} from 'react'
import { Row, Col } from 'antd'
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { SignInButton } from './signin'
import "./Login.css"
import { msalConfig } from '../../../config/msalconfig';
import { PublicClientApplication } from '@azure/msal-browser';






  function Login() {
  
      const navigate = useNavigate()
      const isAuthenticated = useIsAuthenticated();
      const { instance ,accounts} = useMsal();
      const accesstoken  =localStorage.getItem("accesstoken");
      const username = accounts[0] && accounts[0].name;
      const Store_Emailid = accounts[0] && accounts[0].username;
      const msalInstance = new PublicClientApplication(msalConfig);
      const [counter, setCounter] = useState(1);
      const Accesstoken = localStorage.getItem("accesstoken");


  
      useEffect(() => {
        const interval = setInterval(() => {
          document.getElementById(`radio${counter}`).checked=true;
          setCounter((prevCounter) => (prevCounter % 4) + 1);
        }, 3000);
    
        return () => clearInterval(interval);
      }, [counter]);
    
  
      useEffect(() => {
          if (accounts) {
            // Example: Fetch additional user profile information using MS Graph API
            const fetchData = async () => {
              try {
                // Get the authenticated user's account
                const account = accounts[0];
      
                // Fetch user profile from MS Graph API
                const response = await instance.acquireTokenSilent({
                  account,
                  scopes: ['User.Read'],
                });
                const accessToken = response.accessToken;
                localStorage.setItem("accesstoken",accessToken)
               
      
                // Use the acquired token to fetch user profile
                const graphResponse = await fetch('https://graph.microsoft.com/v1.0/me', {
                  headers: {
                    Authorization: `Bearer ${response.accessToken}`,
                  },
                });
      
                const userProfile = await graphResponse.json();
               
                
                navigate("/homepage")
              } catch (error) {
                console.error('Error fetching user profile:', error);
              }
            };
      
            fetchData();
          }
        }, [accounts, instance, isAuthenticated]);
  

       
  
   return (
    <div className="sliders">
    <div className="sliders__left">
      <div className="slides">
        <input type="radio" id="radio1" name="radio" onChange={()=>setCounter(1)} />
        <input type="radio" id="radio2" name="radio" onChange={()=>setCounter(2)} />
        <input type="radio" id="radio3" name="radio" onChange={()=>setCounter(3)}  />
        <input type="radio" id="radio4" name="radio" onChange={()=>setCounter(4)}  />
  
        <div className="slide first">

          <img
            src="https://cdn.pixabay.com/photo/2013/02/21/19/10/grass-84622_1280.jpg"
            alt="slide_img"
          />
        </div>
        <div className="slide second">
          <img
            src="https://www.gtigrows.com/wp-content/uploads/rise-wall-sign.jpg"
            alt="slide_img"
          />
        </div>
        <div className="slide third">
          <img
            src="https://giecdn.blob.core.windows.net/fileuploads/image/2023/10/12/gti_risefla.jpg"
            alt="slide_img"
          />
        </div>
        <div className="slide forth">
          <img
            src="https://vangst.com/_next/image?url=https%3A%2F%2Fvangst-direct-upload.s3-accelerate.amazonaws.com%2F3kBNpVwvFp2FjXXHN8zU8k.png&w=3840&q=75"
            alt="slide_img"
          />
        </div>
  
        <div className="slides__auto">
          <div className="auto1" onClick={()=>setCounter(1)} ></div>
          <div className="auto2" onClick={()=>setCounter(2)} ></div>
          <div className="auto3" onClick={()=>setCounter(3)} ></div>
          <div className="auto4" onClick={()=>setCounter(4)} ></div>
        </div>
      </div>
      <div className="manuals">
        <label for="radio1" className="manual"></label>
        <label for="radio2" className="manual"></label>
        <label for="radio3" className="manual"></label>
        <label for="radio4" className="manual"></label>
      </div>
    </div>
  
    <div className='login'>
    <div className="limiter">
        <div className="container-login100 background-img">

            <div className="container">
            <div className="login_topimg"> </div>
      
                   
                        <div className="row">
                            <form className="login-form">
                         
<div className='form-titles'>
<p className='login100-form-title '>SIGN IN</p>
                                <p className='login100-form-title '>With Microsoft Account</p>
</div>
                              
                                <div>
                                    <input className="input100" type="text" name="Username" placeholder="Username" />
                                </div>
                                <div>
                                    <input className="input100" name="pass" placeholder="Password" />
                                </div>
                                <div className='login-btns '>
                                    <button className="login-form-btn" >Login</button>
                               
                                    <SignInButton />
                                </div>
                            </form>
                        </div>
                 
              
            </div>
        </div>
    </div>
    
    </div>
  </div>

  



    )
}

export default Login
