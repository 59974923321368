import React from 'react'
import "./spinner.css"
function Spinner() {
  return (
    <div className="spinner">

    <div className="item item-1"></div>
    <div className="item item-2"></div>
    <div className="item item-3"></div>
    <div className="item item-4"></div>
    <br></br>
    </div>
  )
}

export default Spinner