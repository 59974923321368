import  './Card.css';
import { Card } from 'antd';

const AntCard = ({ title, content,onClick,className,loading }) => {
  return (
    <Card title={title} onClick={onClick} className={`default-card ${className}`} loading={loading}>
     {content}
    </Card>
  );
};

export default AntCard;
