import { Input as AntInput } from 'antd';
import './Input.css';

const Input = ({ placeholder, value, onChange,className ,inputType, disabled,defaultValue,max}) => {

  return <AntInput
   disabled={disabled}
   placeholder={placeholder}
    value={value}
    onChange={onChange} 
    defaultValue={defaultValue} 
    className={className}
     type={inputType?inputType:"text"}
   maxLength={max}
     
     />;
};

export default Input;
