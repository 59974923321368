import React, { useContext, useEffect } from "react";
import { Image } from "antd";

import "./Header.css";
import { NavLink, useNavigate } from "react-router-dom";
import { useMsal,useIsAuthenticated } from "@azure/msal-react";
import logo from "../../../assets/headerlogo.jpeg";
import { QuestionCircleOutlined } from "@ant-design/icons";


import LogoutBtn from "../../atoms/Button/LogoutBtn";
import { logoutRequest } from "../../../config/msalconfig";
import { ThemeContext } from "../../../customHook/useContext/Usecontext";
import ScrollIndicator from "../../molecules/ScrolIndicator/ScrollIndicator";








function Header({ className, appname  }) {
  const { accounts,instance } = useMsal();
  const username = accounts[0] && accounts[0].name;
  const Store_Emailid = accounts[0] && accounts[0].username;

  // const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const { state } = useContext(ThemeContext);
  const DarkMode = state.darkMode;
  const token = localStorage.getItem("accesstoken");



  // useEffect(() => {
  //   console.log("isAuthenticated:", isAuthenticated);
  //   console.log("Accounts:", accounts);
  //   console.log("Instance:", instance);
  // }, [isAuthenticated, accounts, instance]);









  // <-------Access Tocken Verify--------->

  useEffect(() => {

    // Check if there's an access token in local storage or a cookie
    const token = localStorage.getItem("accesstoken");
    if (!isAuthenticated && !token) {
      navigate("/")
    }
  }, [navigate,token,isAuthenticated]);



  /* <-------------Scroll Event Function-----------> */
  useEffect(() => {
    const handleScroll = () => {
      const navElement = document.querySelector('.header');

      if (window.scrollY > 0 && navElement) {
        navElement.classList.add('window-scroll-heder');
      } else if (navElement) {
        navElement.classList.remove('window-scroll-heder');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);



  // <-------display appname in header section--------->
  useEffect(() => {
    const handleScroll = () => {
      const navElement = document.querySelector(".app");

      if (window.scrollY > 25 && navElement) {
        navElement.classList.add("app-name");
      } else {
        navElement.classList.remove("app-name");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);




  //  <*/--------Logout function----*/>



  const handleLogout = async () => {
    try {

      if (accounts.length > 0) {
        localStorage.removeItem("darkmode")
        localStorage.removeItem("app_name")
        localStorage.removeItem("app_id")
        localStorage.removeItem("accesstoken");
        localStorage.removeItem("invoiceno")
        localStorage.removeItem("status")
    
        navigate('/')


        await instance.logout(logoutRequest);
      } else {
        // No accounts are signed in, handle accordingly
        console.warn("No accounts found for logout.");
      }
    } catch (error) {
      console.error("Logout error:", error);
    }
  };











  //  <*/--------homepage nevigation----*/>
  const handle_homepage = () => {
    localStorage.removeItem("app_name")
    navigate("/homepage");
    localStorage.removeItem("invoiceno");
    localStorage.removeItem("status");
    localStorage.removeItem("app_name");
  };

  return (
    <div className={DarkMode===true || DarkMode==="true"?`header-dark ${className} `:`header  ${className} `}>
      <div  className={DarkMode===true || DarkMode==="true"?`header-container-dark`:`header-container`} >
        <div className="header_left">
          <NavLink to={"/homepage"}>
            <Image
              preview={false}
              className="header_logo"
              src={logo}
              alt="GTI"
              onClick={handle_homepage}
            />
          </NavLink>
        </div>
        <div className="app ">
          <h4>{appname} </h4>
        </div>
        <div className="header_right">
          <a href={localStorage.getItem("documenturl")} target="_blank" rel="noreferrer" >
            <QuestionCircleOutlined />
          </a>

          <LogoutBtn
            onClick={handleLogout}


            username={username}

          />
        </div>
      </div>
      <ScrollIndicator  DarkMode={DarkMode} />
    </div>
  );
}

export default Header;
