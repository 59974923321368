import "./App.css";
import Template from "./components/templates/HomeTemplate/Template";
import HomePage from "./components/pages/HomePage/homePage";
import Login from "./components/pages/LoginPage/Login";
import { Route, Routes, useNavigate } from "react-router-dom";
import LoginTemplate from "./components/templates/LoginTemplate/LoginTemplate";
import Redirect from "./components/pages/404 -Page/redirect";
import CcrSubmit from "./components/pages/Ccr-Submit/CcrSubmit";
import Summary from "./components/pages/SummaryPage/Summary";
import PurchaceOrder from "./components/pages/PurchaceOrder/PurchaceOrder";
import TableTemplate from "./components/templates/TableTemplate/TableTemplate";
import ErrorPage from "./components/pages/ErrorPage/ErrorPage";
import StoreKeyMaster from "./components/pages/StoreKeyMaster/storeKeyMaster";
import ProductCatalog from "./components/pages/ProductCatalog/productCatalog";
import FileUpload from "./components/pages/FileUpload/FileUpload";
import UserAdministration from "./components/pages/UserAdministration/userAdministration";
import { useEffect } from "react";
import CashSupplyExpenses from "./components/pages/CashSupplyExpenses/cashSupplyExpenses";
import CpgSummary from "./components/pages/CpgSummary/CpgSummary";
import BarcodeScanner from "./components/pages/BarcodeScanner/BarcodeScanner";
import ScannedData from "./components/pages/BarcodeScanner/ScannedData";
import MasterCpgEntry from "./components/pages/MasterCpgEntry/masterCpgEntry"



function App() {
  const navigate = useNavigate();


  // useEffect(() => {
  //   const currentPath = window.location.pathname;
  //   if (currentPath === '/' ) {
  //     navigate("/")
  //   }
  //   else if (currentPath === '/storekeymaster' && localStorage.getItem("app_name") === 'StoreKey Master App') {
  //     navigate("/storekeymaster")
  //   }
  //   else if (currentPath === '/fileupload' && localStorage.getItem("app_name") === 'File Upload') {
  //     navigate("/fileupload")
  //   }
  //   else if (currentPath === '/barcodescanner' && localStorage.getItem("app_name") === 'Bar Code Scanner') {
  //     navigate("/barcodescanner")
  //   }
  //   else if (currentPath === '/productcatalog' && localStorage.getItem("app_name") === 'Product Catalog App') {
  //     navigate("/productcatalog")
  //   }
  //   else if (currentPath === '/ccr-submit' && localStorage.getItem("app_name") === 'Closing Comments App') {
  //     navigate("/ccr-submit")
  //   }
  //   else if (currentPath === '/adduser' && localStorage.getItem("app_name") === 'User Administration') {
  //     navigate("/adduser")
  //   }
  //   else if (currentPath === '/purchase-order' && localStorage.getItem("app_name") === "Purchase Order") {
  //     navigate("/purchase-order")
  //   }
  //   else if (currentPath === '/cash-supply-expenses' && localStorage.getItem("app_name") === "Cash/Supply Expenses") {
  //     navigate("/cash-supply-expenses")
  //   }
  //   else if (currentPath === '/master-cpg-entry' && localStorage.getItem("app_name") === "Master CPG Entry") {
  //     navigate("/master-cpg-entry")
  //   }
  //   else if (currentPath === '/cash-supply-summary' && localStorage.getItem("app_name") === "Cash/Supply Summary") {
  //     navigate("/cash-supply-summary")
  //   }
  //   else if (currentPath === '/ccr-submit' && localStorage.getItem("app_name") === 'Closing Comments App') {
  //     navigate("/summary")
  //   }
  //   else if (currentPath === '/barcodescanner' && localStorage.getItem("app_name") === 'Bar Code Scanner') {
  //     navigate("/barcodescanner")
  //   }
  //   else {
  //     // history.push('/homepage');
  //     navigate("/homepage")
  //   }
  // }, []);


  return (
    <div className="App">

      <Routes>
        <Route
          path="/"
          element={
            <LoginTemplate>
              <Login />
            </LoginTemplate>
          }
        ></Route>

        <Route
          path="/homepage"
          element={
            <Template  appname={"HOMEPAGE"} >
              <HomePage />
            </Template>
          }
        />
        <Route
          path="/ccr-submit"
          element={
            <Template appname={"CLOSING COMMENTS APP"}>
              <CcrSubmit />
            </Template>
          }
        />
        <Route
          path="/summary"
          element={
            <Template appname={"CLOSING REPORT WEEKLY SUMMARY "}>
              <Summary />
            </Template>
          }
        />
        <Route
          path="/purchase-order"
          element={
            <TableTemplate appname={"PURCHASE ORDER"}>
              <PurchaceOrder />
            </TableTemplate>
          }
        />

        <Route
          path="/storekeymaster"
          element={
            <TableTemplate appname={"STOREKEYMASTER"}>
              <StoreKeyMaster />
            </TableTemplate>
          }
        />

        <Route
          path="/productcatalog"
          element={
            <TableTemplate appname={"PRODUCTCATALOG"}>
              <ProductCatalog />
            </TableTemplate>
          }
        />

        <Route
          path="/fileupload"
          element={
            <TableTemplate appname={"FILE UPLOAD"}>
              <FileUpload />
            </TableTemplate>
          }
        />

        <Route
          path="/adduser"
          element={
            <TableTemplate appname={"USER ADMINISTRATION"}>
              <UserAdministration />
            </TableTemplate>
          }
        />

        <Route
          path='/master-cpg-entry'
          element={
            <TableTemplate appname={"Master CPG Entry"}>
              <MasterCpgEntry/>
            </TableTemplate>
          }
        />

        <Route
          path='/cash-supply-expenses'
          element={
            <TableTemplate appname={"CASH SUPPLY EXPENSES"}>
              <CashSupplyExpenses />
            </TableTemplate>
          }
        />
        <Route
          path='/cash-supply-summary'
          element={
            <Template appname={"CASH SUPPLY SUMMARY"}>
              <CpgSummary />
            </Template>
          }
        />

<Route
          path="/barcodescanner"
          element={
            <Template appname={"BARCODE SCANNER"}>
              <BarcodeScanner />
            </Template>
          }
        />



        <Route path=":page/error" element={<ErrorPage />} />
        <Route
          path="*"
          element={
            <Template>
              <Redirect />
            </Template>
          }
        />

<Route
          path="/barcodetable"
          element={
            <TableTemplate appname={"BARCODE TABLE"}>
              <ScannedData />
            </TableTemplate>
          }
        />







      </Routes>

    </div>
  );
}

export default App;
