


import React,{useState,useEffect}from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import "./Barcode.css";
import { useContext } from 'react';
import { useMsal } from "@azure/msal-react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";

import {  message } from "antd";
import { base_url } from "../../../api/api";
import { ThemeContext } from "../../../customHook/useContext/Usecontext";
import Button from "../../atoms/Button/Button";
import { request } from "../../../customHook/request";


function BarcodeScanner() {
  const [data, setData] = React.useState("Not Found");

  const [codeFound, setCodeFound] = React.useState(false);

  const [facingMode, setFacingMode] = React.useState("environment");
  const scannerRef = React.useRef(null);
  const{dispatch}= useContext(ThemeContext)
const navigate=useNavigate()

    const [hasPermission, setHasPermission] = useState(false);
    const { accounts } = useMsal();
    const username = accounts[0] && accounts[0].name;
    const Store_Emailid = accounts[0] && accounts[0].username;


    useEffect(() => {
      // Request camera permission
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(() => {
          // User granted permission
          setHasPermission(true);
        })
        .catch(() => {
          // User denied or blocked permission
          setHasPermission(false);
        });
    }, []);
  
  


    useEffect(() => {
        // console.log("1")
        if (Number.isInteger(parseInt(data))) {
            // console.log("2")
 
const fetchData = async () => {

            try {
              const res = await request("Post",{
                "requesttype":"barcode_reader",
                // "user_name":  "vijay.kumar@gtigrows.com",
                "user_name":  username 
                // username
                ,
                "op_type": "new",
                "barcode_no":data
                }
                );
          console.log("res",res)
              if (res && res.data) { 
                const status_code = res.data.statuscode;
                // console.log("3")
                if (status_code === 200) {
                  message.success("Barcode Saved Successfully");
                }
              } else {
       
                console.error("Invalid response format:", res);
                // console.log("4")
              }
            
           
         
              }

              catch (error) {
          
                console.error("Error fetching data:", error.message);
              } 
            } 
           
        

fetchData()





        }
      }, [data]);
      
  // console.log(data,"data")
  const handleUpdate = React.useCallback((err, result) => {
    if (result) {
      setData(result.text);
      setCodeFound(true);
      dispatch({type:"ADD_SCANEED_DATA",payload:result.text})

    } else {
      setData("Not Found");
    }
  }, [dispatch]);

  const handleRescan = () => {
    // console.log("Rescan button clicked");

      setData("Not Found");
      setCodeFound(false);
 
    
  };
  
  
  

  const handleSwitchCamera = React.useCallback(() => {
    setFacingMode((prevFacingMode) =>
      prevFacingMode === "environment" ? "user" : "environment"
    );
  }, []);

  const handleLoadedMetadata = () => {
    scannerRef.current.play();
  };

  useEffect(() => {
    // Request camera permission
    navigator.mediaDevices.getUserMedia({ video: true })
      .then(() => {
        // User granted permission
        setHasPermission(true);
      })
      .catch(() => {
        // User denied or blocked permission
        setHasPermission(false);
      });
  }, []);

  if (hasPermission === null) {
    // Permission prompt is not yet resolved
    return <div className="camera-instruction-card">Requesting camera access...</div>;
  }


  


  return (

    <>
            <div className="barcode-btn">
              <NavLink to={"/barcodetable"}>
                <Button label={"Barcode Table"} className={"third-btn"} />
              </NavLink>
            </div>

    <div className="App-container">
  


      {codeFound && (
        <div className="resulpage-container">
          <div className="resultpage">
            <div>
              <p>Barcode Value :</p>
              <p>{data}</p>
            </div>
            <button onClick={handleRescan}>Rescan</button>
          </div>
        </div>
      )}
{!hasPermission?(<>
<div className="camera-access-card">
        <div className="camera-access-header">
          <h2>Camera Access Required</h2>
        </div>
        <div className="camera-access-content">
          <p>Sorry, we couldn't access your camera. Please allow camera access to use the barcode scanner.</p>
          <p>To grant permission:</p>
          <ul>
            <li>Click the lock icon in the address bar</li>
            <li>Click the "Site settings" button</li>
            <li>Set the "Camera" permission to "Allow"</li>
            <li>Refresh the page and try again</li>
          </ul>
        </div>
      </div>
</>):(<>

  <div className="App-content">
        {!codeFound && (
          <div className="scanner-container">
            <button onClick={handleSwitchCamera} className="switchcamera">
              Switch Camera ({facingMode === "environment" ? "Front" : "Back"})
            </button>
            <BarcodeScannerComponent
              ref={scannerRef}
            
              facingMode={facingMode}
              torch={false}
              onUpdate={handleUpdate}
              onLoadedMetadata={handleLoadedMetadata}
            />
          </div>
        )}
      </div>
      <div className="App-footer">
        <p>© 2023 Barcode Scanner Inc.</p>
      </div>

</>)}


    </div>

    </>
  );
}

export default BarcodeScanner;
