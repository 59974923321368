import React, { useState, useEffect, useContext } from "react";
import "./homePage.css";
import { Card, Image } from "antd";
import { request } from "../../../customHook/request"; // Import the request function
import Spinner from "../../atoms/spinner/Spinner";
import homebgImage from "../../../assets/yellow_logo.png";
import Dropdown from "../../atoms/Dropdown/Dropdown";
import { NavLink } from "react-router-dom";

import AntCard from "../../atoms/Card/Card";
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { ThemeContext } from "../../../customHook/useContext/Usecontext";
const HomePage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userValue, setUserValue] = useState();
  const [dropDownOptions, setDropdownOptions] = useState([]);
  const { accounts, instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const username = accounts[0] && accounts[0].name;
  const Store_Emailid = accounts[0] && accounts[0].username;
  const { state } = useContext(ThemeContext);
  const DarkMode = state.darkMode;

  // </*------------Fetching all apps-----------*/>
  localStorage.setItem("app_name", "");

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     // Example: Fetch additional user profile information using MS Graph API
  //     const fetchData = async () => {
  //       try {
  //         // Get the authenticated user's account
  //         const account = accounts[0];

  //         // Fetch user profile from MS Graph API
  //         const response = await instance.acquireTokenSilent({
  //           account,
  //           scopes: ['user.read'],
  //         });

  //         // Use the acquired token to fetch user profile
  //         const graphResponse = await fetch('https://graph.microsoft.com/v1.0/me', {
  //           headers: {
  //             Authorization: `Bearer ${response.accessToken}`,
  //           },
  //         });

  //         const userProfile = await graphResponse.json();
  //         console.log('User Profile:', userProfile);
  //       } catch (error) {
  //         console.error('Error fetching user profile:', error);
  //       }
  //     };

  //     fetchData();
  //   }
  // }, [accounts, instance, isAuthenticated]);


const fetchData = async () => {
    setLoading(true);
    try {
      const res = await request("Post", {
        requesttype: "fetchapps",
        user_name: username,
        user_id: Store_Emailid,
        // user_name: "vijay.kumar@gtigrows.com",
        // user_id: "vijay.kumar@gtigrows.com",
      });
      let response = JSON.parse(res.data.data);
      localStorage.setItem("documenturl","https://gtigrows-my.sharepoint.com/:p:/r/personal/nic_de-angelis_gtigrows_com/_layouts/15/Doc.aspx?sourcedoc=%7B5C9CDF72-7240-463A-AD76-7D6CDA926C07%7D&file=Daily%20R")

  
      if (response) {
        let DropdownValues = [];

        setData(response);
        setLoading(false);

        response.map((res) => {
          if (res.app_name) {
            DropdownValues.push({
              text: res.app_name,
              value: res.app_name,
            });
          }

          return DropdownValues;
        });
        setDropdownOptions(DropdownValues);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error.message);
    } finally {
      setLoading(false);
    }
  };

  // </*------------function calling -----------*/>

  useEffect(() => {
    fetchData();
  }, []);

  // </*------------different app navigation-(card) -----------*/>

  function handlecard_click(product) {
    // window.location.href = product.app_url;
    localStorage.setItem("app_name", product.app_name);
    localStorage.setItem("app_id", product.app_id);
  }

  // </*------------ searching apps -(Dropdown) -----------*/>

  const onSearch = (value) => {
    if (value) {
      setUserValue(value);
    } else {
      setUserValue("");
    }
  };

  // <===========homepage Design=========>

  return (
    <div className="product-listing-page">
      {loading === true ? (
        <Spinner />
      ) : (
        <>
          <div className={DarkMode==="true"||DarkMode===true?"app-search-dark":"app-search"}>
            <Image
              preview={false}
              src={homebgImage}
              alt=""
              width="300px"
              height="auto"
            />
            <div className="app_search">
              <Dropdown
                allowClear={true}
                showSearch={true}
                placeholder={"Search"}
                width={200}
                className={"form-field-lg"}
                onChange={onSearch}
                options={dropDownOptions}
              />
            </div>
          </div>
          <div className="hp_card_container">
            {data
              .filter((name) => {
                if (userValue !== "" && userValue !== undefined) {
                  return name.app_name.includes(userValue);
                } else {
                  return name;
                }
              })

              .map((product, index) => {
                return (
                  <div>
                    <NavLink to={product.app_url}>
                      <AntCard
                        key={index}
                        title={
                        <p
                        style={DarkMode === "true" || DarkMode === true ? { color: "#fff" } : {}}
                        
                        >{product.app_name}</p>
                      }
                        bordered={false}
                        hoverable
                        onClick={() => handlecard_click(product)}
                        // className="our-team"
                        className={DarkMode==="true"||DarkMode===true?"dark-card":"our-team"}
                      >
                        <Image
                          preview={false}
                          src={product.image_url}
                          alt=""
                          width="130px"
                          height="auto"
                        />
                      </AntCard>
                    </NavLink>
                  </div>
                );
              })}
          </div>
        </>
      )}
    </div>
  );
};

export default HomePage;
