import React, { useContext, useEffect, useState } from 'react'
import { DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { useMsal } from '@azure/msal-react';
import { Modal, Space, message } from 'antd';
import { useRef } from 'react';
import Highlighter from 'react-highlight-words';

import "./productCatalog.css"

import { request } from '../../../customHook/request';
import Spinner from '../../atoms/spinner/Spinner';
import DynamicModal from '../../organisms/DynamicModel/DynamicModel';
import Table from '../../atoms/Table/Table';
import Input from '../../atoms/Input/Input';
import Button from '../../atoms/Button/Button';
import { UserInfo } from '../../organisms/UserInfo/UserInfo';
import { toast } from 'react-toastify';
import { ThemeContext } from '../../../customHook/useContext/Usecontext';

function ProductCatalog() {
  const [addRecord, setAddRecord] = useState( 
    {
    storeName: "",
    sku: "",
    productName: "",
    onlineTitle: "",
    masterCategory: "",
    catalogCategory: "",
    brand: "",
    productVendor: "",
    servingSizePerUnit: "",
    gramConcentrate: "",
    productUnitPrice: "",
    cost: "",
    servingSize: "",
    productStrain: "",
    lineage: "",
    alternateName: "",
    thcContent: "",
    cbdContent: "",
    flavor: "",
    productDefaultUnit: "",
    cannabisProduct: "",
    posAvailable: "",
    onlineAvailable: "",
    productTaxable: "",
    size: "",
    flowerEquivalent: "",
    taxCategories: "",
    upc: "",
    medPrice: "",
    recPrice: "",
    pricingtierName: "",
    tags: "",
    grossWeight: "",
    netWeight: "",
    productId: "",
    description: "",
    thcContentunit: "",
    cbdContentunit: "",
    recFloweRequivalent: "",
    unitType: "",
    onlineDescription: "",
    isMedicalonly: "",
    defaultUnit: "",
    ndc: "",
    daysSupply: "",
    illinoistaxcategory: "",
    instructions: "",
    regulatoryCatagory: "",
    isActive: "",
  }
  );
  const [editRecord, setEditRecord] = useState( {
    storeName: "",
    oldSku:"",
    sku: "",
    productsUploadKey:"",
    productName: "",
    onlineTitle: "",
    masterCategory: "",
    catalogCategory: "",
    brand: "",
    productVendor: "",
    servingSizePerUnit: "",
    gramConcentrate: "",
    productUnitPrice: "",
    cost: "",
    servingSize: "",
    productStrain: "",
    lineage: "",
    alternateName: "",
    thcContent: "",
    cbdContent: "",
    flavor: "",
    productDefaultUnit: "",
    cannabisProduct: "",
    posAvailable: "",
    onlineAvailable: "",
    productTaxable: "",
    size: "",
    flowerEquivalent: "",
    taxCategories: "",
    upc: "",
    medPrice: "",
    recPrice: "",
    pricingtierName: "",
    tags: "",
    grossWeight: "",
    netWeight: "",
    productId: "",
    description: "",
    thcContentunit: "",
    cbdContentunit: "",
    recFloweRequivalent: "",
    unitType: "",
    onlineDescription: "",
    isMedicalonly: "",
    defaultUnit: "",
    ndc: "",
    daysSupply: "",
    illinoistaxcategory: "",
    instructions: "",
    regulatoryCatagory: "",
    isActive: "",
  }
  );
  const[fixedColumnLeft,setFixedColumnLeft]=useState("left")
  // const[fixedColumnRight,setFixedColumnRight]=useState("right")
   const [pageLoader,setPageLoader]=useState(false)
 
   const [ischange,setIsChange]=useState(false)
    const [catagoryDrobdown, setCatagoryDrobdown] = useState("")
    const [subCatagoryDrobdown, setSubCatagoryDrobdown] = useState("")
    const [vendorDrobdown, setVendorDrobdown] = useState("")
    const [brandDrobdown, setBrandDrobdown] = useState("")
    const [storeDrobdown, setStoreDrobdown] = useState("")
    const [strainNameDrobdown, setStrainNameDrobdown] = useState("")
    const [strainTypeDrobdown, setStrainTypeNameDrobdown] = useState("")
    const [taxCategoryDropdown, setTaxCategoryDropdown] = useState("")
    const [tagsDrobdown, setTagsDrobdown] = useState("")
  
    const [saveLoading, setSaveLoading] = useState(false)
    const [gettableData, setGettableData] = useState(0)
    const [productCatalog, setProductCatalog] = useState([])
    const [isModalAdd, setIsModalAdd] = useState(false)
    const [isModalEdit, setIsModalEdit] = useState(false)
    // const [error, setError] = useState("")
    const [loading, setLoading] = useState(true)
    const { state } = useContext(ThemeContext);
    const DarkMode = state.darkMode;


    const { accounts } = useMsal();
    const username = accounts[0] && accounts[0].name;
    const Store_Emailid = accounts[0] && accounts[0].username;

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [sizeDropdown, setSizeDropdown] = useState("")
 



useEffect(() => {
  const handleResize = () => {
   

    if (window.innerWidth < 1000 ) {
      setFixedColumnLeft("")

      // setFixedColumnRight("")
    } else {
      setFixedColumnLeft("left")

      // setFixedColumnRight("right")
    }
  };

  window.addEventListener("resize", handleResize);

  // Call handleResize initially to set the class based on the initial window width
  handleResize();

  return () => {
    window.removeEventListener("resize", handleResize);
  };
}, []);





    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                    maxWidth:"20rem"
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >

                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                      
                        size="small"
                        style={{
                            width: 90,
                        }}
                        label={"Search"}
                        Icon={SearchOutlined}
                        className={"third-btn"}
                    >
                        
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                        label={"Reset"}
                        className={"third-btn"}
                    >
                        
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                        label={"close"}
                        className={"third-btn"}
                    >
                        
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (

            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) => {
        
            if (record.store_name !== null && record.online_title !== null) {
                return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            }
        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

 
    const FetchData = async () => {
      
if(gettableData===0){
  setPageLoader(true)

}
else{
  setPageLoader(false)
}

setLoading(true)
   
        try {
            const response = await request("Post", {
                "requesttype":"form_productcatalog",
                "op_type":"fetch",
                "app_id":"4",
                timeout:30000,
            })
            let res = await JSON.parse(response.data.data);

            if (response.data.statuscode === 200) {
                setProductCatalog(res)
                setPageLoader(false)
                setLoading(false)
                let a=res.filter((store)=>store.store_name=="BluePoint Branford")
               
            }
        } catch (error) {
            // setError(true)
            setPageLoader(false);
            setLoading(false)
            console.error("Error fetching data:", error.message);
        } 
        finally {
            setPageLoader(false);
            setLoading(false)
          
        }

    }

    useEffect(() => {
        FetchData();
    }, [gettableData])





    
      const columns = [
        {
            key: '1',
            title: "Store",
            dataIndex: 'store_name',
            fixed: fixedColumnLeft,
            width: 220,
            ...getColumnSearchProps('store_name'),
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => {
              const storeNameA = a.store_name || ''; 
              const storeNameB = b.store_name || ''; 
              return storeNameA.localeCompare(storeNameB);
          }
        },
        {
            key: '2',
            title: "SKU",
            align: 'center',
            dataIndex: 'sku',
            fixed:fixedColumnLeft,
            width: 140,
            // filters:
            //     storeStateFilter,
            // filterMode: 'tree',
            // onFilter: (value, record) => record.store_state.startsWith(value),
        },
        {
            key: '3',
            title: "Product_Name",
            align: 'center',
            dataIndex: 'product_name',
            width: 300,
            
        },
        {
            key: '4',
            title: "Online_Title",
            dataIndex: 'online_title',
            width: 300,
            align: "center",
            ...getColumnSearchProps('online_title'),
 
        },
        {
            key: '5',
            title: "Master_Category",
            dataIndex: 'master_category',
            align: "center",
            width: 160,
        },
        {
            key: '6',
            title: "Catalog_Category",
            dataIndex: 'product_category',
            align: "center",
            width: 240,
        },
        {
            key: '7',
            title: "Brand",
            dataIndex: 'brand',
            align: "center",
            width: 150,
        },
        {
            key: '8',
            title: "Vendor",
            align: "center",
            dataIndex: 'product_vendor',
            width: 250
        },
        {
            key: '9',
            title: "Serving_Size_Per_Unit",
            dataIndex: 'serving_size_per_unit',
            align: "center",
            width: 270
        },
        {
            key: '10',
            title: "Grams_Concentration",
            dataIndex: 'gram_concentrate',
            align: "center",
            width: 200
        },
        {
            key: '11',
            title: "Price",
            dataIndex: 'product_unit_price',
            align: "center",
            width: 100
        },
        {
            key: '12',
            title: "Cost",
            dataIndex: 'cost',
            align: "center",
            width: 100
        },
        {
            key: '13',
            title: "Serving_Size",
            dataIndex: 'serving_size',
            align: "center",
            width: 130
        },
        {
            key: '14',
            title: "Strain",
            dataIndex: 'product_strain',
            align: "center",
            width: 200
        },
        {
            key: '15',
            title: "Lineage",
            dataIndex: 'lineage',
            align: "center",
            width: 100
        },
        {
            key: '16',
            title: "Alternate_Name",
            dataIndex: 'alternate_name',
            align: "center",
            width: 180
        },
        {
            key: '17',
            title: "THC_Content",
            dataIndex: 'thc_content',
            align: "center",
            width: 150
        },

        {
            key: '18',
            title: "CBD_Content",
            dataIndex: 'cbd_content',
            align: "center",
            width: 150
        },
        {
            key: '19',
            title: "Flavor",
            dataIndex: 'flavor',
            align: "center",
            width: 130
        },
        {
            key: '20',
            title: "Default_Unit",
            dataIndex: 'product_default_unit',
            align: "center",
            width: 150
        },
        {
            key: '21',
            title: "Cannabis_Product",
            dataIndex: 'cannabis_product',
            align: "center",
            width: 170
        },
        {
            key: '22',
            title: "POS_Available",
            dataIndex: 'pos_available',
            align: "center",
            width: 140
        },
        {
            key: '23',
            title: "Online_Available",
            dataIndex: 'online_available',
            align: "center",
            width: 160
        },
        {
            key: '24',
            title: "Product_Taxable",
            dataIndex: 'product_taxable',
            align: "center",
            width: 160
        },
        {
            key: '25',
            title: "Size",
            dataIndex: 'size',
            align: "center",
            width: 110
        },
        {
            key: '26',
            title: "Flower_Equivalent",
            dataIndex: 'flower_equivalent',
            align: "center",
            width: 160
        },
        {
            key: '27',
            title: "Tax_Categories",
            dataIndex: 'tax_categories',
            align: "center",
            width: 200
        },
        {
            key: '28',
            title: "UPC",
            dataIndex: 'upc',
            align: "center",
            width: 110
        },
        {
            key: '29',
            title: "Med Price",
            dataIndex: 'medprice',
            align: "center",
            width: 110
        },
        {
            key: '30',
            title: "Rec Price",
            dataIndex: 'recprice',
            align: "center",
            width: 110
        },
        {
            key: '31',
            title: "Pricing Tier Name",
            dataIndex: 'pricingtiername',
            align: "center",
            width: 180
        },

        {
            key: '32',
            title: "Tags",
            dataIndex: 'tags',
            align: "center",
            width: 140,
        },
        {
            key: '34',
            title: " CBDContent Unit",
            dataIndex: 'cbdcontentunit',
            align: "center",
            width: 150
        },

        {
            key: '35',
            title: "THCContent Unit",
            dataIndex: 'thccontentunit',
            align: "center",
            width: 150
        },

        {
            key: '36',
            title: "Days Supply",
            dataIndex: 'dayssupply',
            align: "center",
            width: 150
        },

        {
            key: '37',
            title: "DefaultUnit",
            dataIndex: 'defaultunit',
            align: "center",
            width: 150
        },
        {
            key: '38',
            title: "Description",
            dataIndex: 'description',
            align: "center",
            width: 150
        },
        {
            key: '39',
            title: "GrossWeight",
            dataIndex: 'grossweight',
            align: "center",
            width: 150
        },
        {
            key: '40',
            title: "Netweight",
            dataIndex: 'netweight',
            align: "center",
            width: 150
        },
        {
            key: '41',
            title: "Illinois Taxcategory",
            dataIndex: 'illinoistaxcategory',
            align: "center",
            width: 150
        },
        {
            key: '42',
            title: "Instructions",
            dataIndex: 'instructions',
            align: "center",
            width: 150
        },
        {
            key: '43',
            title: "Isactive",
            dataIndex: 'is_active', render: isMedicalOnly => isMedicalOnly ? 'True' : 'False',
            align: "center",
            width: 150
        },
        {
            key: '44',
            title: "Ismedicalonly",
            dataIndex: 'ismedicalonly',  render: isMedicalOnly => isMedicalOnly ? 'True' : 'False',
            align: "center",
            width: 150
        },
        {
            key: '45',
            title: "NDC",
            dataIndex: 'ndc',
            align: "center",
            width: 150
        },
        {
            key: '46',
            title: "Online Description",
            dataIndex: 'online_description',
            align: "center",
            width: 150
        },
        {
            key: '47',
            title: "Recflower Equivalent",
            dataIndex: 'recflowerequivalent',
            align: "center",
            width: 150
        },
        {
            key: '48',
            title: "Regulatory Catagory",
            dataIndex: 'regulatorycatagory',
            align: "center",
            width: 150
        },
        {
            key: '49',
            title: "Unit Type",
            dataIndex: 'unittype',
            align: "center",
            width: 150
        },


        {
          key: '50',
          title: 'Actions',
          fixed: "right",
          width: 100,
          render: (record) => {
              return <>
                  <div className='action-button'>
                      <EditOutlined onClick={() => { handle_edit(record); store_name_edit_handleChange(record);}} className='table-icon' />
                      <DeleteOutlined onClick={() => handle_delete(record)} className='table-delete-icon' />
                  </div>
              </>
          }
      },
    ];

      const UserDatas = [
        {
          type: "dropdown",
          label: "Store",
          placeholder: "Store",
          width: 200,
          className: "form-field-lg",
          options: storeDrobdown,
          value: addRecord.storeName, // Here, 'store.storeName' corresponds to 'storeName'
          onChange: (value) => {
            setAddRecord({ ...addRecord,storeName:value }); // Assuming you have a 'setStore' function for updating the 'storeName'
        
            store_name_add_handleChange(value);
          },
        },
        {
          type: "input",
          label: "SKU",
          inputType:"number",
          className: "form-field-lg",
          placeholder: "SKU",
          onChange: (e) => {setAddRecord({ ...addRecord, sku: e.target.value });
          fetchId()},
          required: true,
          value: addRecord.sku, // Use 'store.sku' here
        },
        {
          type: "input",
          label: "Product Id",
          className: "form-field-lg",
          placeholder: "Product Id",
          onChange: (e) => setAddRecord({ ...addRecord, productId: e.target.value }), // Update 'store.productId'
          disabled: addRecord.productidActive,
          value: addRecord.productId, // Use 'store.productId'
        },
        {
          type: "input",
          label: "Product Name",
          className: "form-field-lg",
          placeholder: "Product Name",
          onChange: (e) => setAddRecord({ ...addRecord, productName: e.target.value }),
          disabled: addRecord.productidActive,
          value: addRecord.productName,
        },
        {
          type: "input",
          label: "Online Title",
          className: "form-field-lg",
          placeholder: "Online Title",
          onChange: (e) => setAddRecord({ ...addRecord, onlineTitle: e.target.value }),
          disabled: addRecord.productidActive,
          value: addRecord.onlineTitle,
        },
        {
          type: "input",
          label: "Description",
          className: "form-field-lg",
          onChange: (e) => setAddRecord({ ...addRecord, description: e.target.value }),
          disabled: addRecord.productidActive,
          value: addRecord.description,
        },
        {
          type: "input",
          label: "Online Description",
          className: "form-field-lg",
          placeholder: "Online Description",
          onChange: (e) => setAddRecord({ ...addRecord, onlineDescription: e.target.value }),
          disabled: addRecord.productidActive,
          value: addRecord.onlineDescription,
        },
        {
          type: "dropdown",
          label: "Online Available",
          placeholder: "Online Available",
          className: "form-field-lg",
          options: [
            {
              value: "True",
              label: "True",
            },
            {
              value: "False",
              label: "False",
            },
          ],
          onChange: (value) => setAddRecord({ ...addRecord, onlineAvailable: value }),
          disabled: addRecord.productidActive,
          value: addRecord.onlineAvailable,
        },
        {
          type: "dropdown",
          label: "Master Category",
          placeholder: "Master Category",
          width: 200,
          className: "form-field-lg",
          options: catagoryDrobdown,
          onChange: (value) => CatagoryAdd_handlechance(value),
          disabled: addRecord.productidActive,
          value: addRecord.masterCategory,
        },
        {
          type: "dropdown",
          label: "Catalog Category",
          placeholder: "Catalog Category",
          width: 200,
          className: "form-field-lg",
          options: subCatagoryDrobdown,
          onChange: (value) => setAddRecord({ ...addRecord, catalogCategory: value }),
          disabled: addRecord.productidActive,
          value: addRecord.catalogCategory,
        },
        {
          type: "input",
          label: "Regulatory Category",
          className: "form-field-lg",
          placeholder: "Regulatory Category",
          onChange: (e) => setAddRecord({ ...addRecord,regulatoryCatagory:e.target.value }),
          disabled: addRecord.productidActive,
          value: addRecord.regulatoryCatagory,
        },
        {
          type: "dropdown",
          label: "Brand",
          placeholder: "Brand",
          width: 200,
          className: "form-field-lg",
          options: brandDrobdown,
          onChange: (value) => setAddRecord({ ...addRecord, brand: value }),
          disabled: addRecord.productidActive,
          value: addRecord.brand,
        },
        {
          type: "dropdown",
          label: "Vendor",
          placeholder: "Vendor",
          width: 200,
          className: "form-field-lg",
          options: vendorDrobdown,
          onChange: (value) => setAddRecord({ ...addRecord, productVendor: value }),
          disabled: addRecord.productidActive,
          value: addRecord.productVendor,
        },
        {
          type: "dropdown",
          label: "Size",
          placeholder: "Size",
          width: 200,
          className: "form-field-lg",
          options: sizeDropdown,
          onChange: (value) => setAddRecord({...addRecord,size:value}),
          value: addRecord.size,
        },
        {
          type: "input",
          label: "Serving Size Per Unit",
          className: "form-field-lg",
          placeholder: "Serving Size Per Unit",
          onChange: (e) => setAddRecord({ ...addRecord, servingSizePerUnit: e.target.value }),
          disabled: addRecord.productidActive,
          value: addRecord.servingSizePerUnit,
        },
        {
          type: "input",
          label: "Serving Size",
          className: "form-field-lg",
          placeholder: "Serving Size",
          onChange: (e) => setAddRecord({ ...addRecord, servingSize: e.target.value }),
          disabled: addRecord.productidActive,
          value: addRecord.servingSize,
        },
        {
          type: "input",
          label: "Grams / Concentration",
          className: "form-field-lg",
          placeholder: "Grams Concentration",
          onChange: (e) => setAddRecord({ ...addRecord, gramConcentrate: e.target.value }),
          disabled: addRecord.productidActive,
          value: addRecord.gramConcentrate,
        },
        {
          type: "input",
          label: "Price",
        inputType:"number",
          className: "form-field-lg",
          placeholder: "Price",
          onChange: (e) => setAddRecord({ ...addRecord,productUnitPrice: e.target.value }),
          disabled: addRecord.productidActive,
          value: addRecord.productUnitPrice,
        },
        {
          type: "input",
          label: "Cost",
          inputType:"number",
          className: "form-field-lg",
          placeholder: "Cost",
          onChange: (e) => setAddRecord({ ...addRecord, cost: e.target.value }),
          disabled: addRecord.productidActive,
          value: addRecord.cost,
        },
        {
          type: "input",
          label: "Unit Type",
          className: "form-field-lg",
          placeholder: "Unit Type",
          onChange: (e) => setAddRecord({ ...addRecord, unitType: e.target.value }),
          disabled: addRecord.productidActive,
          value: addRecord.unitType,
        },
        {
          type: "dropdown",
          label: "Strain",
          placeholder: "Strain",
          width: 200,
          className: "form-field-lg",
          options: strainNameDrobdown, // Replace with your actual data source
          onChange: (value) => StrainAdd_handlechance(value),
          value: addRecord.productStrain, 
        },
        strainTypeDrobdown === "" || strainTypeDrobdown === undefined || strainTypeDrobdown === null
          ? {
              type: "input",
              label: "Lineage",
              className: "form-field-lg",
              placeholder: "Lineage",
              onChange: (e) => setAddRecord({...addRecord,lineage:e.target.value}),
              value: addRecord.lineage,
            }
          : {
              type: "dropdown",
              label: "Lineage",
              placeholder: "Lineage",
              width: 200,
              className: "form-field-lg",
              options: strainTypeDrobdown, 
              onChange: (value) => setAddRecord({...addRecord,lineage:value}),
              value: addRecord.lineage, 
            },
        {
          type: "input",
          label: "Alternate Name",
          className: "form-field-lg",
          placeholder: "Alternate Name",
          onChange: (e) => setAddRecord({ ...addRecord,alternateName:e.target.value }),
          disabled: addRecord.productidActive,
          value: addRecord.alternateName,
        },
        {
          type: "input",
          label: "THC Content",
          inputType:"number",
          className: "form-field-lg",
          placeholder: "THC Content",
          defaulttype: "number",
          onChange: (e) => setAddRecord({ ...addRecord,thcContent: e.target.value }),
          disabled: addRecord.productidActive,
          value: addRecord.thcContent,
        },
        {
          type: "input",
          label: "THC Content Unit",
          className: "form-field-lg",
          placeholder: "THC Content Unit",
          onChange: (e) => setAddRecord({ ...addRecord, thcContentunit: e.target.value }),
          disabled: addRecord.productidActive,
          value: addRecord.thcContentunit,
        },
        {
          type: "input",
          label: "CBD Content",
          inputType:"number",
          className: "form-field-lg",
          placeholder: "CBD Content",
          defaulttype: "number",
          onChange: (e) => setAddRecord({ ...addRecord, cbdContent: e.target.value }),
          disabled: addRecord.productidActive,
          value: addRecord.cbdContent,
        },
        {
          type: "input",
          label: "CBD Content Unit",
          className: "form-field-lg",
          placeholder: "CBD Content Unit",
          onChange: (e) => setAddRecord({ ...addRecord, cbdContentunit: e.target.value }),
          disabled: addRecord.productidActive,
          value: addRecord.cbdContentunit,
        },
        {
          type: "input",
          label: "Flavor",
          className: "form-field-lg",
          placeholder: "Flavor",
          onChange: (e) => setAddRecord({ ...addRecord, flavor: e.target.value }),
          disabled: addRecord.productidActive,
          value: addRecord.flavor,
        },
       
        {
          type: "input",
          label: "Default Unit",
          className: "form-field-lg",
          placeholder: "Default Unit",
          value: addRecord.productDefaultUnit,
          onChange: (e) => setAddRecord({ ...addRecord, productDefaultUnit: e.target.value }),
          disabled: addRecord.productidActive,
 
        },
        {
          type: "dropdown",
          label: "Cannabis Product",
          placeholder: "Cannabis Product",
          width: 200,
          className: "form-field-lg",
          options: [
            {
              value: "True",
              label: "True",
            },
            {
              value: "False",
              label: "False",
            },
          ],
          onChange: (value) => setAddRecord({ ...addRecord, cannabisProduct: value }),
          disabled: addRecord.productidActive,
          value: addRecord.cannabisProduct,
        },
        {
          type: "dropdown",
          label: "Is Medical Only",
          placeholder: "Is Medical Only",
          width: 200,
          className: "form-field-lg",
          options: [
            {
              value: "True",
              label: "True",
            },
            {
              value: "False",
              label: "False",
            },
          ],
          onChange: (value) => setAddRecord({ ...addRecord, isMedicalonly: value }),
          disabled: addRecord.productidActive,
          value: addRecord.isMedicalonly,
        },
        {
          type: "dropdown",
          label: "POS Available",
          placeholder: "POS Available",
          width: 200,
          className: "form-field-lg",
          options: [
            {
              value: "True",
              label: "True",
            },
            {
              value: "False",
              label: "False",
            },
          ],
          onChange: (value) => setAddRecord({ ...addRecord, posAvailable: value }),
          disabled: addRecord.productidActive,
          value: addRecord.posAvailable,
        },
        {
          type: "dropdown",
          label: "Product Taxable",
          placeholder: "Product Taxable",
          width: 200,
          className: "form-field-lg",
          options: [
            {
              value: "True",
              label: "True",
            },
            {
              value: "False",
              label: "False",
            },
          ],
          onChange: (value) => setAddRecord({ ...addRecord, productTaxable: value }),
          disabled: addRecord.productidActive,
          value: addRecord.productTaxable,
        },
        // {
        //   type: "dropdown",
        //   label: "Size",
        //   placeholder: "Size",
        //   width: 200,
        //   className: "form-field-lg",
        //   options: sizeDropdown,
        //   onChange: (value) => setAddRecord({ ...addRecord, size: value }),
        //   disabled: addRecord.productidActive,
        //   value: addRecord.size,
        // },
        {
          type: "input",
          label: "Flower Equivalent",
          className: "form-field-lg",
          placeholder: "Flower Equivalent",
          defaulttype: "number",
          onChange: (e) => setAddRecord({ ...addRecord, flowerEquivalent: e.target.value }),
          disabled: addRecord.productidActive,
          value: addRecord.flowerEquivalent,
        },

        {
          type: "input",
          label: "Recflower Equivalent",
          inputType:"number",
          className: "form-field-lg",
          placeholder: "Recflower Equivalent",
          defaulttype: "number",
          onChange: (e) => setAddRecord({ ...addRecord, recFloweRequivalent: e.target.value }),
          disabled: addRecord.productidActive,
          value: addRecord.recFloweRequivalent,
        },
        {
          type: "dropdown",
          label: "Tax Categories",
          placeholder: "Tax Categories",
          width: 200,
          className: "form-field-lg",
          options: taxCategoryDropdown,
          onChange: (value) => setAddRecord({ ...addRecord, taxCategories: value }),
          disabled: addRecord.productidActive,
          value: addRecord.taxCategories,
        },
        {
          type: "input",
          label: "Illinois Tax Categories",
          className: "form-field-lg",
          placeholder: "Illinois Tax Categories",
          onChange: (e) => setAddRecord({ ...addRecord, illinoistaxcategory: e.target.value }),
          disabled: addRecord.productidActive,
          value: addRecord.illinoistaxcategory,
        },
        {
          type: "input",
          label: "UPC",
          className: "form-field-lg",
          placeholder: "UPC",
          onChange: (e) => setAddRecord({ ...addRecord, upc: e.target.value }),
          value: addRecord.upc,
        },

        {
          type: "input",
          label: "Med Price",
       
          className: "form-field-lg",
          placeholder: "Med Price",
          onChange: (e) => setAddRecord({ ...addRecord, medPrice: e.target.value }),
          disabled: addRecord.productidActive,
          value: addRecord.medPrice,
        },
        {
          type: "input",
          label: "Rec Price",
          className: "form-field-lg",
          placeholder: "Rec Price",
          onChange: (e) => setAddRecord({ ...addRecord, recPrice: e.target.value }),
          disabled: addRecord.productidActive,
          value: addRecord.recPrice,
        },
        {
          type: "input",
          label: "Pricingtier Name",
          className: "form-field-lg",
          placeholder: "Pricingtier Name",
          onChange: (e) => setAddRecord({ ...addRecord, pricingtierName: e.target.value }),
          disabled: addRecord.productidActive,
          value: addRecord.pricingtierName,
        },
        {
          type: "dropdown",
          label: "Tags",
          className: "form-field-lg",
          placeholder: "Tags",
          value: addRecord.tags,
          options: tagsDrobdown,
          mode:"multiple",
          onChange: (value) => setAddRecord({ ...addRecord,tags:value }),
          disabled: addRecord.productidActive,
       
         
        },
        {
          type: "input",
          label: "Gross Weight",
          inputType:"number",
          className: "form-field-lg",
          placeholder: "Gross Weight",
          onChange: (e) => setAddRecord({ ...addRecord, grossWeight: e.target.value }),
          disabled: addRecord.productidActive,
          value: addRecord.grossWeight,
        },
        {
          type: "input",
          label: "Net Weight",
          className: "form-field-lg",
          inputType:"number",
          placeholder: "Net Weight",
          onChange: (e) => setAddRecord({ ...addRecord, netWeight: e.target.value }),
          disabled: addRecord.productidActive,
          value: addRecord.netWeight,
        },
        {
          type: "input",
          label: "NDC",
          className: "form-field-lg",
          placeholder: "NDC",
          onChange: (e) => setAddRecord({ ...addRecord, ndc: e.target.value }),
          value: addRecord.ndc,
        },
        {
          type: "input",
          label: "Days Supply",
          inputType:"number",
          className: "form-field-lg",
          placeholder: "Days Supply",
          onChange: (e) => setAddRecord({ ...addRecord, daysSupply: e.target.value }),
          disabled: addRecord.productidActive,
          value: addRecord.daysSupply,
        },
        {
          type: "input",
          label: "Instructions",
          className: "form-field-lg",
          placeholder: "Instructions",
          onChange: (e) => setAddRecord({ ...addRecord, instructions: e.target.value }),
          disabled: addRecord.productidActive,
          value: addRecord.instructions,
        },
        // {
        //   type: "input",
        //   label: "Regulatory Category",
        //   className: "form-field-lg",
        //   placeholder: "Regulatory Category",
        //   onChange: (e) => setAddRecord({ ...addRecord, regulatoryCatagory: e.target.value }), // Corrected spelling
        //   disabled: addRecord.productidActive,
        //   value: addRecord.regulatoryCatagory,
        // },
        {
          type: "dropdown",
          label: "Is Active",
          placeholder: "Is Active",
          width: 200,
          className: "form-field-lg",
          options: [
            {
              value: "True",
              label: "True",
            },
            {
              value: "False",
              label: "False",
            },
          ],
          onChange: (value) => setAddRecord({ ...addRecord, isActive: value }),
          disabled: addRecord.productidActive,
          value: addRecord.isActive,
        },

        {
          type: "button",
          className: "bottom-btn",
          label: "Save",
          name: "Save",
          valuePropName: "checked",
          wrapperCol: {
            offset: 0,
            span: 24,
          },
          onClick: (e) => handle_save_add_record(),
          loading:saveLoading
        },

        {
          className: "bottom-btn",
          type: "button",
          label: "Cancel",
          name: "Cancel",
          valuePropName: "checked",
          wrapperCol: {
            offset: 0,
            span: 24,
          },
          onClick: () =>{ setAddRecord({...addRecord,isActive:false}); setIsModalAdd(false)},
        },
      ];

      const UserDatasEdit = [
        {
          type: "dropdown",
          label: "Store",
          placeholder: "Store",
          width: 200,
          className: "form-field-lg",
          options: storeDrobdown,
          value: editRecord.storeName,
          onChange: (value) => {
            setEditRecord({ ...editRecord, storeName: value });

            store_name_add_handleChange(value);
          },
        },
        {
          type: "input",
          label: "SKU",
          inputType:"number",
          className: "form-field-lg",
          placeholder: "SKU",
          onChange: (e) => setEditRecord({ ...editRecord, sku: e.target.value }),
          required: true,
          value: editRecord.sku,
        },
        {
          type: "input",
          label: "Product Id",
          className: "form-field-lg",
          placeholder: "Product Id",
          onChange: (e) => setEditRecord({ ...editRecord, productId: e.target.value }),
          disabled: editRecord.productidActive,
          value: editRecord.productId,
        },
        {
          type: "input",
          label: "Product Name",
          className: "form-field-lg",
          placeholder: "Product Name",
          onChange: (e) => setEditRecord({ ...editRecord, productName: e.target.value }),
          disabled: editRecord.productidActive,
          value: editRecord.productName,
        },
        {
          type: "input",
          label: "Online Title",
          className: "form-field-lg",
          placeholder: "Online Title",
          onChange: (e) => setEditRecord({ ...editRecord, onlineTitle: e.target.value }),
          disabled: editRecord.productidActive,
          value: editRecord.onlineTitle,
        },
        {
          type: "input",
          label: "Description",
          className: "form-field-lg",
          onChange: (e) => setEditRecord({ ...editRecord, description: e.target.value }),
          disabled: editRecord.productidActive,
          value: editRecord.description,
        },
        {
          type: "input",
          label: "Online Description",
          className: "form-field-lg",
          placeholder: "Online Description",
          onChange: (e) => setEditRecord({ ...editRecord, onlineDescription: e.target.value }),
          disabled: editRecord.productidActive,
          value: editRecord.onlineDescription,
        },
        {
          type: "dropdown",
          label: "Online Available",
          placeholder: "Online Available",
          className: "form-field-lg",
          options: [
            {
              value: "True",
              label: "True",
            },
            {
              value: "False",
              label: "False",
            },
          ],
          onChange: (value) => setEditRecord({ ...editRecord, onlineAvailable: value }),
          disabled: editRecord.productidActive,
          value: editRecord.onlineAvailable,
        },
        {
          type: "dropdown",
          label: "Master Category",
          placeholder: "Master Category",
          width: 200,
          className: "form-field-lg",
          options: catagoryDrobdown,
          onChange: (value) => CatagoryEdit_handlechance(value),
          disabled: editRecord.productidActive,
          value: editRecord.masterCategory,
        },
        {
          type: "dropdown",
          label: "Catalog Category",
          placeholder: "Catalog Category",
          width: 200,
          className: "form-field-lg",
          options: subCatagoryDrobdown,
          onChange: (value) => setEditRecord({ ...editRecord, catalogCategory: value }),
          disabled: editRecord.productidActive,
          value: editRecord.catalogCategory,
        },
        {
          type: "input",
          label: "Regulatory Category",
          className: "form-field-lg",
          placeholder: "Regulatory Category",
          onChange: (e) => setEditRecord({ ...editRecord, regulatoryCatagory: e.target.value }),
          disabled: editRecord.productidActive,
          value: editRecord.regulatoryCatagory,
        },
        {
          type: "dropdown",
          label: "Brand",
          placeholder: "Brand",
          width: 200,
          className: "form-field-lg",
          options: brandDrobdown,
          onChange: (value) => setEditRecord({ ...editRecord, brand: value }),
          disabled: editRecord.productidActive,
          value: editRecord.brand,
        },
        {
          type: "dropdown",
          label: "Vendor",
          placeholder: "Vendor",
          width: 200,
          className: "form-field-lg",
          options: vendorDrobdown,
          onChange: (value) => setEditRecord({ ...editRecord, productVendor: value }),
          disabled: editRecord.productidActive,
          value: editRecord.productVendor,
        },
        {
          type: "dropdown",
          label: "Size",
          placeholder: "Size",
          width: 200,
          className: "form-field-lg",
          options: sizeDropdown,
          onChange: (value) => setEditRecord({...editRecord,size:value}),
          value: editRecord.size,
        },
        {
          type: "input",
          label: "Serving Size Per Unit",
          className: "form-field-lg",
          placeholder: "Serving Size Per Unit",
          onChange: (e) => setEditRecord({ ...editRecord, servingSizePerUnit: e.target.value }),
          disabled: editRecord.productidActive,
          value: editRecord.servingSizePerUnit,
        },
        {
          type: "input",
          label: "Serving Size",
          className: "form-field-lg",
          placeholder: "Serving Size",
          onChange: (e) => setEditRecord({ ...editRecord, servingSize: e.target.value }),
          disabled: editRecord.productidActive,
          value: editRecord.servingSize,
        },
        {
          type: "input",
          label: "Grams / Concentration",
          className: "form-field-lg",
          placeholder: "Grams Concentration",
          onChange: (e) => setEditRecord({ ...editRecord, gramConcentrate: e.target.value }),
          disabled: editRecord.productidActive,
          value: editRecord.gramConcentrate,
        },
        {
          type: "input",
          label: "Price",
          inputType:"number",
          className: "form-field-lg",
          placeholder: "Price",
          onChange: (e) => setEditRecord({ ...editRecord, productUnitPrice: e.target.value }),
          disabled: editRecord.productidActive,
          value: editRecord.productUnitPrice,
        },
        {
          type: "input",
          label: "Cost",
          className: "form-field-lg",
          inputType:"number",
          placeholder: "Cost",
          onChange: (e) => setEditRecord({ ...editRecord, cost: e.target.value }),
          disabled: editRecord.productidActive,
          value: editRecord.cost,
        },
        {
          type: "input",
          label: "Unit Type",
          className: "form-field-lg",
          placeholder: "Unit Type",
          onChange: (e) => setEditRecord({ ...editRecord, unitType: e.target.value }),
          disabled: editRecord.productidActive,
          value: editRecord.unitType,
        },
        {
          type: "dropdown",
          label: "Strain",
          placeholder: "Strain",
          width: 200,
          className: "form-field-lg",
          options: strainNameDrobdown,
          onChange: (value) => StrainEdit_handlechance(value),
          value: editRecord.productStrain,
        },
        strainTypeDrobdown === "" || strainTypeDrobdown === undefined || strainTypeDrobdown === null
          ? {
              type: "input",
              label: "Lineage",
              className: "form-field-lg",
              placeholder: "Lineage",
              onChange: (e) => setEditRecord({ ...editRecord, lineage: e.target.value }),
              value: editRecord.lineage,
            }
          : {
              type: "dropdown",
              label: "Lineage",
              placeholder: "Lineage",
              width: 200,
              className: "form-field-lg",
              options: strainTypeDrobdown,
              onChange: (value) => setEditRecord({ ...editRecord, lineage: value }),
              value: editRecord.lineage,
            },
        {
          type: "input",
          label: "Alternate Name",
          className: "form-field-lg",
          placeholder: "Alternate Name",
          onChange: (e) => setEditRecord({ ...editRecord, alternateName:e.target.value }),
          disabled: editRecord.productidActive,
          value: editRecord.alternateName,
        },
        {
          type: "input",
          label: "THC Content",
          inputType:"number",
          className: "form-field-lg",
          placeholder: "THC Content",
          defaulttype: "number",
          onChange: (e) => setEditRecord({ ...editRecord, thcContent: e.target.value }),
          disabled: editRecord.productidActive,
          value: editRecord.thcContent,
        },
        {
          type: "input",
          label: "THC Content Unit",
          className: "form-field-lg",
          placeholder: "THC Content Unit",
          onChange: (e) => setEditRecord({ ...editRecord, thcContentunit: e.target.value }),
          disabled: editRecord.productidActive,
          value: editRecord.thcContentunit,
        },
        {
          type: "input",
          label: "CBD Content",
          inputType:"number",
          className: "form-field-lg",
          placeholder: "CBD Content",
          defaulttype: "number",
          onChange: (e) => setEditRecord({ ...editRecord, cbdContent: e.target.value }),
          disabled: editRecord.productidActive,
          value: editRecord.cbdContent,
        },
        {
          type: "input",
          label: "CBD Content Unit",
          className: "form-field-lg",
          placeholder: "CBD Content Unit",
          onChange: (e) => setEditRecord({ ...editRecord, cbdContentunit: e.target.value }),
          disabled: editRecord.productidActive,
          value: editRecord.cbdContentunit,
        },
        {
          type: "input",
          label: "Flavor",
          className: "form-field-lg",
          placeholder: "Flavor",
          onChange: (e) => setEditRecord({ ...editRecord, flavor: e.target.value }),
          disabled: editRecord.productidActive,
          value: editRecord.flavor,
        },
      
        {
          type: "input",
          label: "Default Unit",
          className: "form-field-lg",
          placeholder: "Default Unit",
          value: editRecord.productDefaultUnit,
          onChange: (e) => setEditRecord({ ...editRecord, productDefaultUnit: e.target.value }),
          disabled: editRecord.productidActive,
        },
        {
          type: "dropdown",
          label: "Cannabis Product",
          placeholder: "Cannabis Product",
          width: 200,
          className: "form-field-lg",
          options: [
            {
              value: "True",
              label: "True",
            },
            {
              value: "False",
              label: "False",
            },
          ],
          onChange: (value) => setEditRecord({ ...editRecord, cannabisProduct: value }),
          disabled: editRecord.productidActive,
          value: editRecord.cannabisProduct,
        },
        {
          type: "dropdown",
          label: "Is Medical Only",
          placeholder: "Is Medical Only",
          width: 200,
          className: "form-field-lg",
          options: [
            {
              value: "True",
              label: "True",
            },
            {
              value: "False",
              label: "False",
            },
          ],
          onChange: (value) => setEditRecord({ ...editRecord, isMedicalonly: value }),
          disabled: editRecord.productidActive,
          value: editRecord.isMedicalonly,
        },
        {
          type: "dropdown",
          label: "POS Available",
          placeholder: "POS Available",
          width: 200,
          className: "form-field-lg",
          options: [
            {
              value: "True",
              label: "True",
            },
            {
              value: "False",
              label: "False",
            },
          ],
          onChange: (value) => setEditRecord({ ...editRecord, posAvailable: value }),
          disabled: editRecord.productidActive,
          value: editRecord.posAvailable,
        },
        {
          type: "dropdown",
          label: "Product Taxable",
          placeholder: "Product Taxable",
          width: 200,
          className: "form-field-lg",
          options: [
            {
              value: "True",
              label: "True",
            },
            {
              value: "False",
              label: "False",
            },
          ],
          onChange: (value) => setEditRecord({ ...editRecord, productTaxable: value }),
          disabled: editRecord.productidActive,
          value: editRecord.productTaxable,
        },
       
        {
          type: "input",
          label: "Flower Equivalent",
          className: "form-field-lg",
          placeholder: "Flower Equivalent",
          defaulttype: "number",
          onChange: (e) => setEditRecord({ ...editRecord, flowerEquivalent: e.target.value }),
          disabled: editRecord.productidActive,
          value: editRecord.flowerEquivalent,
        },
        {
          type: "input",
          label: "Recflower Equivalent",
          inputType:"number",
          className: "form-field-lg",
          placeholder: "Recflower Equivalent",
      
          defaulttype: "number",
          onChange: (e) => setEditRecord({ ...editRecord, recFloweRequivalent: e.target.value }),
          disabled: editRecord.productidActive,
          value: editRecord.recFloweRequivalent,
        },
        {
          type: "dropdown",
          label: "Tax Categories",
          placeholder: "Tax Categories",
          width: 200,
          className: "form-field-lg",
          options: taxCategoryDropdown,
          onChange: (value) => setEditRecord({ ...editRecord, taxCategories: value }),
          disabled: editRecord.productidActive,
          value: editRecord.taxCategories,
        },
      
        {
          type: "input",
          label: "Illinois Tax Categories",
          className: "form-field-lg",
          placeholder: "Illinois Tax Categories",
          onChange: (e) => setEditRecord({ ...editRecord, illinoistaxcategory: e.target.value }),
          disabled: editRecord.productidActive,
          value: editRecord.illinoistaxcategory,
        },
        {
          type: "input",
          label: "UPC",
          className: "form-field-lg",
          placeholder: "UPC",
          onChange: (e) => setEditRecord({ ...editRecord, upc: e.target.value }),
          value: editRecord.upc,
        },
        {
          type: "input",
          label: "Med Price",
          className: "form-field-lg",
          placeholder: "Med Price",
          onChange: (e) => setEditRecord({ ...editRecord, medPrice: e.target.value }),
          disabled: editRecord.productidActive,
          value: editRecord.medPrice,
        },
        {
          type: "input",
          label: "Rec Price",
          className: "form-field-lg",
          placeholder: "Rec Price",
          onChange: (e) => setEditRecord({ ...editRecord, recPrice: e.target.value }),
          disabled: editRecord.productidActive,
          value: editRecord.recPrice,
        },
        {
          type: "input",
          label: "Pricingtier Name",
          className: "form-field-lg",
          placeholder: "Pricingtier Name",
          onChange: (e) => setEditRecord({ ...editRecord, pricingtierName: e.target.value }),
          disabled: editRecord.productidActive,
          value: editRecord.pricingtierName,
        },
        {
          type: "dropdown",
          label: "Tags",
          className: "form-field-lg",
          placeholder: "Tags",
          value: editRecord.tags,
          options: tagsDrobdown,
          mode:"multiple",
          onChange: (value) => setEditRecord({ ...editRecord,tags:value }),
          disabled: editRecord.productidActive,
        },
        {
          type: "input",
          label: "Gross Weight",
          inputType:"number",
          className: "form-field-lg",
          placeholder: "Gross Weight",
          onChange: (e) => setEditRecord({ ...editRecord, grossWeight: e.target.value }),
          disabled: editRecord.productidActive,
          value: editRecord.grossWeight,
        },
        {
          type: "input",
          label: "Net Weight",
          inputType:"number",
          className: "form-field-lg",
          placeholder: "Net Weight",
          onChange: (e) => setEditRecord({ ...editRecord, netWeight: e.target.value }),
          disabled: editRecord.productidActive,
          value: editRecord.netWeight,
        },
        {
          type: "input",
          label: "NDC",
          className: "form-field-lg",
          placeholder: "NDC",
          onChange: (e) => setEditRecord({ ...editRecord, ndc: e.target.value }),
          value: editRecord.ndc,
        },
        {
          type: "input",
          label: "Days Supply",
          inputType:"number",
          className: "form-field-lg",
          placeholder: "Days Supply",
          onChange: (e) => setEditRecord({ ...editRecord, daysSupply: e.target.value }),
          disabled: editRecord.productidActive,
          value: editRecord.daysSupply,
        },
        {
          type: "input",
          label: "Instructions",
          className: "form-field-lg",
          placeholder: "Instructions",
          onChange: (e) => setEditRecord({ ...editRecord, instructions: e.target.value }),
          disabled: editRecord.productidActive,
          value: editRecord.instructions,
        },
        // {
        //   type: "input",
        //   label: "Regulatory Category",
        //   className: "form-field-lg",
        //   placeholder: "Regulatory Category",
        //   onChange: (e) => setEditRecord({ ...editRecord, regulatoryCatagory: e.target.value }),
        //   disabled: editRecord.productidActive,
        //   value: editRecord.regulatoryCatagory,
        // },
        {
          type: "dropdown",
          label: "Is Active",
          placeholder: "Is Active",
          width: 200,
          className: "form-field-lg",
          options: [
            {
              value: "True",
              label: "True",
            },
            {
              value: "False",
              label: "False",
            },
          ],
          onChange: (value) => setEditRecord({ ...editRecord, isActive: value }),
          disabled: editRecord.productidActive,
          value: editRecord.isActive,
        },
        {
          type: "button",
          className: "bottom-btn",
          label: "Save",
          name: "Save",
          valuePropName: "checked",
          wrapperCol: {
            offset: 0,
            span: 24,
          },
          onClick: (e) => handle_update_record(),
         loading:saveLoading
        },
        {
          className: "bottom-btn",
          type: "button",
          label: "Cancel",
          name: "Cancel",
          valuePropName: "checked",
          wrapperCol: {
            offset: 0,
            span: 24,
          },
          onChange: () =>{ setEditRecord({ ...editRecord, isActive: false }); setIsModalEdit(false)},
        },
   
        
      
      
      
      
      
      
      
      
      
      
      ]
      
              
          
            
        
          




















      const Test = () => {
        const notify = () => toast.info("Product Id available for the store Use Edit Option!");
        notify()
    }








    useEffect(() => {
  
        async function FetchStore() {
            try {
                let Storeresponse = await request("post", {
                    "requesttype": "fetchmasters",
                    "master_type": "stores"
                });

                let StoreValue = []
                let FetchStore = JSON.parse(Storeresponse.data.data)
                const key = "store_name";
                const StoreUniqueByKey = [...new Map(FetchStore.map(item =>
                    [item[key], item])).values()];


                if (StoreUniqueByKey.length > 0) {

                    StoreUniqueByKey.map((res) => {
                        if (res.store !== "") {
                            StoreValue.push({
                                text: res.store_name,
                                value: res.store_name
                            })
                        }
                        return StoreValue
                    }
                    )
                }


                setStoreDrobdown(StoreValue);





            }



            catch (err) {
             console.log(err)
            }
        }

        FetchStore()

        async function FetchStrainName() {
            try {
                let res =  await request("post", {
                    "requesttype": "fetchmasters",
                    "master_type": "strainname"
                });

                let StrainName = []

                if (res !== "") {

                    const data = res.data.data
                    if (data != null && data.length > 0) {
                        const StrainNameVlaue = JSON.parse(data);


                        StrainNameVlaue.map((res) => {

                            if (res.strainname !== "") {
                                StrainName.push({
                                    text: res.strainname,
                                    value: res.strainname
                                },)
                            }
                            return StrainName
                        })
                        setStrainNameDrobdown(StrainName);
                    }


                }
            } catch (err) {
                if (err.response) {
                    // The client was given an error response (5xx, 4xx)
                    console.log('Error', err.response);

                } else if (err.request) {
                    console.log('Error', err.request);
                    // The client never received a response, and the request was never left

                } else {
                    // Anything else
                    console.log('Error', err.message);
                }
            }
        }

        FetchStrainName()

        async function FetchTags() {
            try {
                let res =  await request("post", {
                    "requesttype": "fetchmasters",
                    "master_type": "tags"
                });


                let Tags = []

                if (res !== "") {

                    const data = res.data.data
                    if (data != null && data.length > 0) {
                        const TagsVlaue = JSON.parse(data);


                        TagsVlaue.map((res) => {

                            if (res.tag_id !== "") {
                                Tags.push({
                                    text: res.tag_id,
                                    value: res.tag_id
                                },)
                            }
                            return Tags
                        })


                        setTagsDrobdown(Tags);





                    }


                }
            } catch (err) {
                if (err.response) {
                    // The client was given an error response (5xx, 4xx)
                    console.log('Error', err.response);

                } else if (err.request) {
                    console.log('Error', err.request);
                    // The client never received a response, and the request was never left

                } else {
                    // Anything else
                    console.log('Error', err.message);
                }
            }
        }

        FetchTags()


        async function TaxCategories() {
            try {
                let res =  await request("post", {
                    "requesttype": "fetchmasters",
                    "master_type": "tax_categories",
                });
                let TaxCategory = []
                if (res !== "") {
                    const data = res.data.data
                    if (data != null && data.length > 0) {
                        const Taxcategories = JSON.parse(data);
                        Taxcategories.map((res) => {
                            if (res.tax_category !== "") {
                                TaxCategory.push({
                                    text: res.tax_category,
                                    value: res.tax_category
                                },)
                            }
                            return TaxCategory
                        })
                        setTaxCategoryDropdown(TaxCategory);
                    }
                }
            }
            catch (err) {
                if (err.response) {
                    // The client was given an error response (5xx, 4xx)
                    console.log('Error', err.response);

                } else if (err.request) {
                    console.log('Error', err.request);
                    // The client never received a response, and the request was never left

                } else {
                    // Anything else
                    console.log('Error', err.message);
                }
            }
        }
        TaxCategories()
        
    }, [])





    const handleclear=()=>{
    return setAddRecord({
        storeAdd: "",
        skuAdd: "",
        productidAdd: "",
        descriptionAdd: "",
        productNameAdd: "",
        onlineTitleAdd: "",
        onlineDescriptionAdd: "",
        masterCategoryAdd: "",
        catalogCategoryAdd: "",
        regulatoryCatagoryAdd: "",
        brandAdd: "",
        vendorAdd: "",
        servingSizePerUnitAdd: "",
        GramsAdd: "",
        priceAdd: "",
        costAdd: "",
        unitTypeAdd: "",
        servingSizeAdd: "",
        strainAdd: "",
        lineageAdd: "",
        alternateNameAdd: "",
        THC_ContentAdd: "",
        THC_ContentUnitAdd: "",
        CBD_ContentAdd: "",
        CBD_ContentUnitAdd: "",
        flavorAdd: "",
        defaultUnitAdd: "",
        cannabisProductAdd: "",
        POS_AvailableAdd: "",
        isMedicalonlyAdd: "",
        onlineAvailableAdd: "",
        productTaxableAdd: "",
        sizeAdd: "",
        flowerEquivalentAdd: "",
        recFlowerEquivalentAdd: "",
        taxCategoriesAdd: "",
        illnoisTaxcategoriesAdd: "",
        upcAdd: "",
        ndcAdd: "",
        MedPriceAdd: "",
        RecPriceAdd: "",
        pricingTierNameAdd: "",
        tagsAdd: "",
        netWeightAdd: "",
        grossWeightAdd: "",
        daysupplyAdd: "",
        instructionsAdd: "",
        productidActive: "",
   })







    }




    const CatagoryAdd_handlechance = (value) => {
        setSubCatagoryDrobdown("")
     
        setAddRecord({ ...addRecord, masterCategory: value })
        async function FetchStrainType() {
            try {
                let res =  await request("post", {
                    "requesttype": "fetchmasters",
                    "master_type": "subcategory",
                    "maincategory": value,
                    "store_name": addRecord.storeName
                });

              
                if (res !== "") {
                    let SubCatagory = []
                    const data = res.data.data
                   
                    if (data != null && data.length > 0 && data!=="Data unavailable") {
                        const SubCatagoryValue = JSON.parse(data);
                        SubCatagoryValue.map((res) => {
                            if (res.category !== "" && res.category != null && res.category.length > 0) {
                                SubCatagory.push({
                                    text: res.category,
                                    value: res.category
                                },)
                            }
                            return SubCatagory
                        })
                        setSubCatagoryDrobdown(SubCatagory);
                    }
                    else{
                   message.warning(data)
                    }
                }
               
            } 
            catch (err) {
              console.log(err)
                  }
        }

        FetchStrainType()
    };


    
    const CatagoryEdit_handlechance = (value) => {
        setSubCatagoryDrobdown("")
     setEditRecord({...editRecord,masterCategory:value})
        async function FetchStrainType() {
            try {
                let res =  await request("post", {
                    "requesttype": "fetchmasters",
                    "master_type": "subcategory",
                    "maincategory": value,
                    "store_name":editRecord.storeName
                });
                if (res !== "") {
                    let SubCatagory = []
                    const data = res.data.data
                    if (data != null && data.length > 0) {
                        const SubCatagoryValue = JSON.parse(data);
                        SubCatagoryValue.map((res) => {
                            if (res.category !== "" && res.category !== null && res.category.length > 0) {
                                SubCatagory.push({
                                    text: res.category,
                                    value: res.category
                                },)
                            }
                            return SubCatagory
                        })
                        setSubCatagoryDrobdown(SubCatagory);
                    }
                }
            } catch (err) {
                if (err.response) {
                    // The client was given an error response (5xx, 4xx)
                    console.log('Error', err.response);

                } else if (err.request) {
                    console.log('Error', err.request);
                    // The client never received a response, and the request was never left

                } else {
                    // Anything else
                    console.log('Error', err.message);
                }
            }
        }

        async function FetchStrainType2() {
            try {
                let res =  await request("post", {
                    "requesttype": "fetchmasters",
                    "master_type": "subcategory",
                    "maincategory": value,
                    "store_name": editRecord.storeName
                });
                if (res !== "") {
                    let SubCatagory = []
                    const data = res.data.data
                    if (data != null && data.length > 0) {
                        const SubCatagoryValue = JSON.parse(data);
                        SubCatagoryValue.map((res) => {
                            if (res.category !== "" && res.category !== null && res.category.length > 0) {
                                SubCatagory.push({
                                    text: res.category,
                                    value: res.category
                                },)
                            }
                            return SubCatagory
                        })


                        setSubCatagoryDrobdown(SubCatagory);
                    }
                }
            } catch (err) {
                if (err.response) {
                    // The client was given an error response (5xx, 4xx)
                    console.log('Error', err.response);

                } else if (err.request) {
                    console.log('Error', err.request);
                    // The client never received a response, and the request was never left

                } else {
                    // Anything else
                    console.log('Error', err.message);
                }
            }
        }
        FetchStrainType()
        FetchStrainType2()
    };

 const fetchId=() => {
        async function FetchSku() {
            try {
                let Skuvalue =  await request("post", {
                    "requesttype": "fetch_product_id",
                    "store_name": addRecord.storeName,
                    "sku": addRecord.sku
                });
                let Statuscode = Skuvalue.data.statuscode
                if (Statuscode === 404) {
                  setAddRecord({...addRecord,productId:""})
             
                    addRecord({...addRecord,productidActive:true})
                    Test()
                }
                else {
                 
                    addRecord({...addRecord,productidActive:false})
                }



            }



            catch (err) {
              console.log(err)
            }
        }

        FetchSku()



    }
    const store_name_add_handleChange = (value) => {
      setAddRecord({ ...addRecord, storeName: value }); 
    
        async function FetchVendor() {
            try {
                let res =  await request("post", {
                    "requesttype": "fetchmasters",
                    "master_type": "vendor",
                    "store_name": value
                });
             
                let VendorName = []
                if (res !== "") {
                    const data = res.data.data
                    if (data != null && data.length > 0) {
                        const VendorNameVlaue = JSON.parse(data);
                        VendorNameVlaue.map((res) => {
                            if (res.vendorname !== "") {
                                VendorName.push({
                                    text: res.vendorname,
                                    value: res.vendorname
                                },)
                            }
                            return VendorName
                        })

                        setVendorDrobdown(VendorName)
                 
       
                    }
                    if (res.data.statuscode === 404) {
                        message.warning("Data Unavailable")
                    }
                }
            } catch (err) {
        console.log(err)
            }
        }

        FetchVendor()


        async function FetchBrand() {
            try {
                let Brandresponse =  await request("post", {
                    "requesttype": "fetchmasters",
                    "master_type": "brand",
                    "store_name": value
                });
                let BrandValue = []
                let FetchBrand = JSON.parse(Brandresponse.data.data)
               

                const key = "brandname";
                const BrandUniqueByKey = [...new Map(FetchBrand.map(item =>
                    [item[key], item])).values()];


                if (BrandUniqueByKey.length > 0) {
                    BrandUniqueByKey.map((res) => {
                        if (res.brandname !== "") {
                            BrandValue.push({
                                text: res.brandname,
                                value: res.brandname
                            })
                        }
                        return BrandValue
                    }
                    )
                }
                setBrandDrobdown(BrandValue);
            }
            catch (err) {
              console.log(err)
                  }
        }
        FetchBrand()

        async function FetchSize() {
            try {
                let res = await  await request("post", {
                    "requesttype": "fetchmasters",
                    "master_type": "size",
                    "store_name": value
                });
             
                let SizeName = []
                if (res !== "") {
                    const data = res.data.data
                    if (data != null && data.length > 0) {
                        const Size = JSON.parse(data);
                        Size.map((res) => {
                            if (res.SizeName !== "") {
                                SizeName.push({
                                    text: res.sizename,
                                    value: res.sizename
                                },)
                            }
                            return SizeName
                        })
                        setSizeDropdown(SizeName);
                    }
                }
            }
            catch (err) {
              console.log(err)
                  }
        }

        FetchSize()

        async function FetchCatagory() {
            try {
                let res = await  await request("post", {
                    "requesttype": "fetchmasters",
                    "master_type": "category",
                    "store_name": value
                });
                let CatagoryAdd = []
                if (res !== "") {
                    const data = res.data.data
                    if (data != null && data.length > 0) {
                        const Catagory = JSON.parse(data);
                    
                        Catagory.map((res) => {
                            if (res.master_category !== "") {
                                CatagoryAdd.push({
                                    text: res.master_category,
                                    value: res.master_category
                                },)
                            }
                            return CatagoryAdd
                        })
                        setCatagoryDrobdown(CatagoryAdd);
                    }


                }
            }
            
            catch (err) {
              console.log(err)
                  }
        }

        FetchCatagory()

        fetchId()

 


        // console.log(

        //   {
        //     "requesttype": "form_productcatalog",
        //     "op_type": "new",
        //     // "user_name": "vijay.kumar@gtigrows.com",
        //     // "user_id": "vijay.kumar@gtigrows.com",
        //     "user_name": username,
        //     "user_id": Store_Emailid,
        //     "app_id": "4",
        //     "store_name": addRecord.storeName,
        //     "sku":addRecord.sku,
        //     "product_name":addRecord.productName,
        //     "online_title": addRecord.onlineTitle,
        //     "master_category": addRecord.masterCategory,
        //     "product_category":addRecord.catalogCategory,
        //     "brand": addRecord.brand,
        //     "product_vendor":addRecord.productVendor,
        //     "serving_size_per_unit":addRecord.servingSizePerUnit,
        //     "gram_concentrate":addRecord.gramConcentrate,
        //     "product_unit_price":addRecord.productUnitPrice,
        //     "cost":addRecord.cost,
        //     "serving_size":addRecord.servingSize,
        //     "product_strain":addRecord.productStrain,
        //     "lineage":addRecord.lineage,
        //     "alternate_name": addRecord.alternateName,
        //     "thc_content":addRecord.thcContent,
        //     "cbd_content": addRecord.cbdContent,
        //     "flavor": addRecord.flavor,
        //     // "product_type": typeAdd,
        //     "product_default_unit":addRecord.productDefaultUnit,
        //     "cannabis_product":addRecord.cannabisProduct,
        //     "pos_available":addRecord.posAvailable,
        //     "online_available":addRecord.onlineAvailable,
        //     "product_taxable":addRecord.productTaxable,
        //     "size": addRecord.size,
        //     "flower_equivalent": addRecord.flowerEquivalent,
        //     "tax_categories": addRecord.taxCategories,
        //     "upc":addRecord.upc,
        //     "medprice":addRecord.medPrice,
        //     "recprice":addRecord.recPrice,
        //     "pricingtiername":addRecord.pricingtierName,
        //     "tags": addRecord.tags.toString(),
        //     "grossweight":addRecord.grossWeight,
        //     "netweight":addRecord.netWeight,
        //     "product_id":addRecord.productId,
        //     "description":addRecord.description,
        //     "thccontentunit":addRecord.thcContentunit,
        //     "cbdcontentunit":addRecord.cbdContentunit,
        //     "recflowerequivalent":addRecord.recFloweRequivalent,
        //     "unittype":addRecord.unitType,
        //     "online_description":addRecord.onlineDescription,
        //     "ismedicalonly":addRecord.isMedicalonly,
        //     "defaultunit": addRecord.productDefaultUnit,
        //     "ndc":addRecord.ndc,
        //     "dayssupply": addRecord.daysSupply,
        //     "illinoistaxcategory":addRecord.illinoistaxcategory,
        //     "instructions":addRecord.instructions,
        //     "regulatorycatagory":addRecord.regulatoryCatagory,
        //     "is_active":addRecord.isActive
        // }
        // )
    }

 
    const StrainAdd_handlechance = (value) => {
        setStrainTypeNameDrobdown("")
   setAddRecord({...addRecord,productStrain:value})
        async function FetchStrainType() {
            try {
                let res =  await request("post", {
                    "requesttype": "fetchmasters", "master_type": "straintype", "strainname": value
                });
                if (res !== "") {
                    let StrainType = []
                    const data = res.data.data
                    if (data != null && data.length > 0) {
                        const StrainTypeVlaue = JSON.parse(data);


                        StrainTypeVlaue.map((res) => {

                            if (res.straintype !== "" && res.straintype != null && res.straintype.length > 0) {
                                StrainType.push({
                                    text: res.straintype,
                                    value: res.straintype
                                },)
                            }
                            return StrainType
                        })


                        setStrainTypeNameDrobdown(StrainType);





                    }


                }
            } catch (err) {
                if (err.response) {
                    // The client was given an error response (5xx, 4xx)
                    console.log('Error', err.response);

                } else if (err.request) {
                    console.log('Error', err.request);
                    // The client never received a response, and the request was never left

                } else {
                    // Anything else
                    console.log('Error', err.message);
                }
            }
        }

        FetchStrainType()
    };
    const handle_save_add_record = () => {
    setIsChange(true)
      setSaveLoading(true)
  request("post",
  {
    "requesttype": "form_productcatalog",
    "op_type": "new",
    // "user_name": "vijay.kumar@gtigrows.com",
    // "user_id": "vijay.kumar@gtigrows.com",
    "user_name": username,
    "user_id": Store_Emailid,
    "app_id": "4",
    "store_name": addRecord.storeName,
    "sku":addRecord.sku,
    "product_name":addRecord.productName,
    "online_title": addRecord.onlineTitle,
    "master_category": addRecord.masterCategory,
    "product_category":addRecord.catalogCategory,
    "brand": addRecord.brand,
    "product_vendor":addRecord.productVendor,
    "serving_size_per_unit":addRecord.servingSizePerUnit,
    "gram_concentrate":addRecord.gramConcentrate,
    "product_unit_price":addRecord.productUnitPrice,
    "cost":addRecord.cost,
    "serving_size":addRecord.servingSize,
    "product_strain":addRecord.productStrain,
    "lineage":addRecord.lineage,
    "alternate_name":addRecord.alternateName,
    "thc_content":addRecord.thcContent,
    "cbd_content": addRecord.cbdContent,
    "flavor": addRecord.flavor,
    // "product_type": typeAdd,
    "product_default_unit":addRecord.productDefaultUnit,
    "cannabis_product":addRecord.cannabisProduct,
    "pos_available":addRecord.posAvailable,
    "online_available":addRecord.onlineAvailable,
    "product_taxable":addRecord.productTaxable,
    "size": addRecord.size,
    "flower_equivalent": addRecord.flowerEquivalent,
    "tax_categories": addRecord.taxCategories,
    "upc":addRecord.upc,
    "medprice":addRecord.medPrice,
    "recprice":addRecord.recPrice,
    "pricingtiername":addRecord.pricingtierName,
    "tags": addRecord.tags.toString(),
    "grossweight":addRecord.grossWeight,
    "netweight":addRecord.netWeight,
    "product_id":addRecord.productId,
    "description":addRecord.description,
    "thccontentunit":addRecord.thcContentunit,
    "cbdcontentunit":addRecord.cbdContentunit,
    "recflowerequivalent":addRecord.recFloweRequivalent,
    "unittype":addRecord.unitType,
    "online_description":addRecord.onlineDescription,
    "ismedicalonly":addRecord.isMedicalonly,
    "defaultunit":addRecord.productDefaultUnit,
    "ndc":addRecord.ndc,
    "dayssupply": addRecord.daysSupply,
    "illinoistaxcategory":addRecord.illinoistaxcategory,
    "instructions":addRecord.instructions,
    "regulatorycatagory":addRecord.regulatoryCatagory,
    "is_active":addRecord.isActive
}).then((res) => {
          const statuscode = res.data.statuscode
          if (statuscode === 200) {
              setGettableData(gettableData + 1)
              setIsModalAdd(false)
              setSaveLoading(false)
              message.success("Data has been processed Successfully")
           
          }
      }).catch((err)=>{
       
console.log(err)
setSaveLoading(false)
      })

      .finally(() => {
   
        setSaveLoading(false);
    });
  

}



    const store_name_edit_handleChange = (value) => {
        async function FetchVendor() {
            try {
                let res =  await request("post", {
                    "requesttype": "fetchmasters",
                    "master_type": "vendor",
                    "store_name": value.store_name
                });
                let VendorName = []
                if (res !== "") {
                    const data = res.data.data
                    if (data != null && data.length > 0) {
                        const VendorNameVlaue = JSON.parse(data);
                        VendorNameVlaue.map((res) => {
                            if (res.vendorname !== "") {
                                VendorName.push({
                                    text: res.vendorname,
                                    value: res.vendorname
                                },)
                            }
                            return VendorName
                        })
                        setVendorDrobdown(VendorName);
                    }


                }
            }
            catch (err) {
              console.log(err)
                  }
        }

        FetchVendor()


        async function FetchBrand() {
            try {
                let Brandresponse =  await request("post", {
                    "requesttype": "fetchmasters",
                    "master_type": "brand",
                    "store_name": value.store_name
                });
                let BrandValue = []
                let FetchBrand = JSON.parse(Brandresponse.data.data)
           

                const key = "brandname";
                const BrandUniqueByKey = [...new Map(FetchBrand.map(item =>
                    [item[key], item])).values()];


                if (BrandUniqueByKey.length > 0) {
                    BrandUniqueByKey.map((res) => {
                        if (res.brandname !== "") {
                            BrandValue.push({
                                text: res.brandname,
                                value: res.brandname
                            })
                        }
                        return BrandValue
                    }
                    )
                }
                setBrandDrobdown(BrandValue);
            }
            catch (err) {
              console.log(err)
                  }
        }
        FetchBrand()

        async function FetchSize() {
            try {
                let res = await request("post", {
                    "requesttype": "fetchmasters",
                    "master_type": "size",
                    "store_name": value.store_name
                });
             
                let SizeName = []
                if (res !== "") {
                    const data = res.data.data
                    if (data != null && data.length > 0) {
                        const Size = JSON.parse(data);
                        Size.map((res) => {
                            if (res.SizeName !== "") {
                                SizeName.push({
                                    text: res.sizename,
                                    value: res.sizename
                                },)
                            }
                            return SizeName
                        })
                        setSizeDropdown(SizeName);
                    }
                }
            }
            catch (err) {
              console.log(err)
                  }
        }

        FetchSize()

        async function FetchCatagory() {
            try {
                let res =  await request("post", {
                    "requesttype": "fetchmasters",
                    "master_type": "category",
                    "store_name": value.store_name
                });
                let CatagoryAdd = []
                if (res !== "") {
                    const data = res.data.data
                    if (data != null && data.length > 0) {
                        const Catagory = JSON.parse(data);
                 
                        Catagory.map((res) => {
                            if (res.master_category !== "") {
                                CatagoryAdd.push({
                                    text: res.master_category,
                                    value: res.master_category
                                },)
                            }
                            return CatagoryAdd
                        })
                        setCatagoryDrobdown(CatagoryAdd);
                    }


                }
            }
            catch (err) {
              console.log(err)
                  }
        }

        FetchCatagory()


       





    }
    const StrainEdit_handlechance = (value) => {
     setEditRecord({...editRecord,productStrain:value})
        setEditRecord({...editRecord,productStrain:value})
        setStrainTypeNameDrobdown("")
        async function FetchStrainType() {
            try {
                let res =  await request("post", {
                    "requesttype": "fetchmasters", "master_type": "straintype", "strainname": value
                });
                if (res !== "") {
                    let StrainType = []
                    const data = res.data.data
                    if (data != null && data.length > 0) {
                        const StrainTypeVlaue = JSON.parse(data);
                        StrainTypeVlaue.map((res) => {

                            if (res.straintype !== "" && res.straintype != null && res.straintype.length > 0) {
                                StrainType.push({
                                    text: res.straintype,
                                    value: res.straintype
                                },)
                            }
                            return StrainType
                        })


                        setStrainTypeNameDrobdown(StrainType);
                    }
                }
            } catch (err) {
          console.log(err)
            }
        }

        FetchStrainType()


    };


    const handle_edit = (value) => {
      
      setIsModalEdit(true)
 

        let ismedicalonly = value.ismedicalonly ? 'True' : 'False';
        let isactive = value.is_active ? 'True' : 'False';
  
       
       setEditRecord( {...editRecord,
    storeName: value.store_name,
    oldSku:value.sku,
    sku: value.sku,
    productsUploadKey:value.products_upload_key,
    productName: value.product_name,
    onlineTitle: value.online_title,
    masterCategory:value.master_category,
    catalogCategory: value.product_category,
    brand: value.brand,
    productVendor: value.product_vendor,
    servingSizePerUnit:value.serving_size_per_unit,
    gramConcentrate: value.gram_concentrate,
    productUnitPrice: value.product_unit_price,
    cost:value.cost,
    servingSize:value.serving_size,
    productStrain: value.product_strain,
    lineage:value.lineage,
    alternateName:value.alternate_name,
    thcContent:  value.thc_content,
    cbdContent: value.cbd_content,
    flavor: value.flavor,
    productDefaultUnit: value.product_default_unit,
    cannabisProduct: value.cannabis_product,
    posAvailable: value.pos_available,
    onlineAvailable: value.online_available,
    productTaxable:  value.product_taxable,
    size: value.size,
    flowerEquivalent:  value.flower_equivalent,
    taxCategories: value.tax_categories,
    upc:  value.upc,
    medPrice: value.medprice,
    recPrice:  value.recprice,
    pricingtierName:  value.pricingtiername,
    tags:value.tags || undefined,
    grossWeight: value.grossweight,
    netWeight:  value.netweight,
    productId: value.product_id,
    description: value.description,
    thcContentunit:  value.thccontentunit,
    cbdContentunit:  value.cbdcontentunit,
    recFloweRequivalent:  value.recflowerequivalent,
    unitType: value.unittype,
    onlineDescription:value.online_description,
    isMedicalonly: ismedicalonly,
    defaultUnit: value.product_default_unit,
    ndc: value.ndc,
    daysSupply: value.dayssupply,
    illinoistaxcategory: value.illinoistaxcategory,
    instructions:value.instructions,
    regulatoryCatagory:  value.regulatorycatagory,
    isActive: isactive,
  })





    }




    const handle_update_record = () => {
      setSaveLoading(true)
      setIsChange(false)
       request("post", {
            "requesttype": "form_productcatalog",
            "op_type": "update",
            "user_name": username,
            "user_id": Store_Emailid,
            // "user_name": "vijay.kumar@gtigrows.com",
            // "user_id": "vijay.kumar@gtigrows.com",
            "app_id": "4",
            "store_name": editRecord.storeName,
            "sku": editRecord.oldSku,
            "new_sku": editRecord.sku,
            "products_upload_key": editRecord.productsUploadKey,
            "product_name": editRecord.productName,
            "online_title": editRecord.onlineTitle,
            "master_category": editRecord.masterCategory,
            "product_category": editRecord.catalogCategory,
            "brand": editRecord.brand,
            "product_vendor": editRecord.productVendor,
            "serving_size_per_unit": editRecord.servingSizePerUnit,
            "gram_concentrate": editRecord.gramConcentrate,
            "product_unit_price": editRecord.productUnitPrice,
            "cost": editRecord.cost,
            "serving_size": editRecord.servingSize,
            "product_strain": editRecord.productStrain,
            "lineage": editRecord.lineage,
            "alternate_name": editRecord.alternateName,
            "thc_content": editRecord.thcContent,
            "cbd_content": editRecord.cbdContent,
            "flavor": editRecord.flavor,
            // "product_type": typeAdd,
            "product_default_unit": editRecord.defaultUnit,
            "cannabis_product": editRecord.cannabisProduct,
            "pos_available": editRecord.posAvailable,
            "online_available": editRecord.onlineAvailable,
            "product_taxable": editRecord.productTaxable,
            "size": editRecord.size,
            "flower_equivalent": editRecord.flowerEquivalent,
            "tax_categories": editRecord.taxCategories,
            "upc": editRecord.upc,
            "medprice": editRecord.medPrice,
            "recprice": editRecord.recPrice,
            "pricingtiername": editRecord.pricingtierName,
            "tags": editRecord.tags?editRecord.tags.toString():"",
            "grossweight": editRecord.grossWeight,
            "netweight": editRecord.netWeight,
            "product_id": editRecord.productId,
            "description": editRecord.description,
            "thccontentunit": editRecord.thcContentunit,
            "cbdcontentunit": editRecord.cbdContentunit,
            "recflowerequivalent": editRecord.recFloweRequivalent,
            "unittype": editRecord.unitType,
            "online_description": editRecord.onlineDescription,
            "ismedicalonly": editRecord.isMedicalonly,
            "defaultunit": editRecord.defaultUnit,
            "ndc": editRecord.ndc,
            "dayssupply": editRecord.daysSupply,
            "illinoistaxcategory": editRecord.illinoistaxcategory,
            "instructions": editRecord.instructions,
            "regulatorycatagory": editRecord.regulatoryCatagory,
            "is_active": editRecord.isActive
        }).then((res) => {
            const statuscode = res.data.statuscode
          
            if (statuscode === 200) {
                setGettableData(gettableData + 1)
                setIsModalEdit(false)
                setSaveLoading(false)
                message.success("Data has been Updated Successfully")
          
            }
        }).catch((err)=>{
          console.log(err)
          setSaveLoading(false)
        })
        .finally(() => {
  
          setSaveLoading(false);
      });
    }

    const handle_delete = ( record) => {
 
        // setDeleteStoreName(true)
        Modal.confirm({
            title: "Are you sure you want to delete this",
            okText: "Yes",
            onOk: () => {
                // formtwo.resetFields();
            
                request("post", {
                  "requesttype": "form_productcatalog",
                  store_email: Store_Emailid,
                  user_name: username,
                  "app_id": "4",
                  // user_id: "vijay.kumar@gtigrows.com",
                  // user_name: "vijay.kumar@gtigrows.com",
                  "op_type": "delete",
                 "products_upload_key":record.products_upload_key?record.products_upload_key.toString():""

                }).then((res) => {
                  const statuscode = res.data.statuscode
                
                  if (statuscode === 200) {
                      setGettableData(gettableData + 1)
                  
                      message.success("Data has been Deleted Successfully")
                
                  }
              }).catch((err)=>{
                console.log(err)
              
              })
            },
            onCancel: () => { }
        });
    }


const handle_addRecord=()=>{
  handleclear()
  setIsModalAdd(true)

}






    


    return (
<>



      
      

        <div className='productcatalog-container'>
        <DynamicModal visible={isModalAdd} 
        content={   <UserInfo fieldData={UserDatas} />}
        title={<h3 style={{color:"#1677ff"}}>Add Record</h3>} onClose={() => setIsModalAdd(false)}
        width={1100}
        />
        <DynamicModal visible={isModalEdit} 
        content={<UserInfo fieldData={UserDatasEdit} />}
        title={<h3 style={{color:"#1677ff"}}>Edit Record</h3>} onClose={() => setIsModalEdit(false)}
        width={1100}
        
        />
    

        {/* <section className="puchase-order">
        <h3 className="page-header">PRODUCTCATALOG </h3>
    
        <div className='addrecord-btn'>
        <Button
                onClick={handle_addRecord }
                label={"Add Record"}
                className={"third-btn"}
            />
   
        </div>
     
      </section> */}

      <div className='header-session'>
                <h3 className={DarkMode==="true"||DarkMode===true?"page-header-dark":"page-header"}>PRODUCTCATALOG</h3>
                <Button
                     onClick={handle_addRecord }
                    label={"Add Record"}
                    className={"third-btn"}
                />
            </div>

            <div>
            <Table loading={pageLoader} dataSource={productCatalog} columns={columns} />
        </div>

      

    </div>
      
      </> 
    )
}

export default ProductCatalog