// In your context file (useContext/Usecontext.js)

import React, { createContext, useReducer, useEffect } from "react";

const initialState = {
  theme: [],
  store: [],
  darkMode: false, // New state for dark mode
};

const ThemeReducer = (state, action) => {
  switch (action.type) {
    case "ADD_SCANEED_DATA":
      return {
        ...state,
        theme: [...state.theme, action.payload],
      };
    case "STORE_KEY":
      return {
        ...state,
        store: [...state.store, action.payload],
      };
      case "TOGGLE_DARK_MODE":
        const newDarkMode = !state.darkMode;
        // localStorage.setItem("darkmode", newDarkMode); // Store dark mode in local storage
        const DarkMode=localStorage.getItem("darkmode")
        return {
          ...state,
          darkMode: DarkMode,
        };
 
    default:
      return state;
  }
};

export const ThemeContext = createContext();

export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ThemeReducer, initialState);

  useEffect(() => {
  
    dispatch({ type: "TOGGLE_DARK_MODE" });
  }, []);



  return (
    <ThemeContext.Provider value={{ state, dispatch }}>
      {children}
    </ThemeContext.Provider>
  );
};
