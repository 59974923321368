import { useContext, useEffect, useState } from "react";
import { UserInfo, CommentsData } from "../../organisms/UserInfo/UserInfo";
import "./CcrSubmit.css";
import Button from "../../atoms/Button/Button";
import { request } from "../../../customHook/request";
import { useMsal } from "@azure/msal-react";
import { InfoCircleFilled  } from "@ant-design/icons";
import DynamicModal from "../../organisms/DynamicModel/DynamicModel";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import Spinner from "../../atoms/spinner/Spinner";
import { fetchOptions } from "../../../customHook/FetchOptions";
import { ThemeContext } from "../../../customHook/useContext/Usecontext";

function CcrSubmit() {
  const [pageLoader, setPageLoader] = useState(false);
  const [error, setError] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [storeName, setStoreName] = useState("");
  const [defaultStoreName, setDefaultStoreName] = useState("");
  const [isReader, setIsReader] = useState(false);
  const [dropDownChange, setDropDownChange] = useState(false);
  const [storeId, setStoreId] = useState("");
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [cardLoading, setCardLoading] = useState(false);
  const [messageBoxValue, setMessageBoxValue] = useState("");
  const [updatedBy, setUpdatedBy] = useState("");
  const [dimkey, setDimkey] = useState();
  const [commentResponse, setCommentResponse] = useState([]);
  const { accounts } = useMsal();
  const username = accounts[0] && accounts[0].name;
  const Store_Emailid = accounts[0] && accounts[0].username;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const { state } = useContext(ThemeContext);
  const DarkMode = state.darkMode;
  const [infoComments, setInfoComments] = useState({
    sales: "",
    customer: "",
    staffing: "",
    inventory: "",
    salesOpportunities: "",
    rewards: "",
  });

  const [comments, setComments] = useState({
    sales: "",
    customer: "",
    staffing: "",
    inventory: "",
    salesOpportunities: "",
    rewards: "",
  });


  const navigate = useNavigate();

  //model -popup 
  const openModal = (title, content) => {
    setModalTitle(
      <p className="info-header">
      <InfoCircleFilled className="info-svg" />
        {title}<p className="info-title">( Yesterday comment )</p>
      </p>
    );
    setModalContent(content);
    setIsModalVisible(true);
  };
  //close model
  const handleModalClose = () => {
    setIsModalVisible(false);
  };


//handle exisiting comments
  const responseHandle = (res) => {
    if (res.data.existing_comments_status === 200) {
      let data_comments = JSON.parse(res.data.existing_comments);

      if (data_comments.is_submitted) {
        setIsReader(true);
        setMessageBoxValue("Store Comments have been processed");
      }
      setUpdatedBy(data_comments.updated_by);
      setComments((prevComments) => ({
        ...prevComments,
        sales: data_comments.sales_comments,
        customer: data_comments.customer_service_comments,
        staffing: data_comments.staffing_comments,
        inventory: data_comments.inventory_comments,
        rewards: data_comments.loyalty_comments,
        salesOpportunities: data_comments.store_opportunities_comments,
      }));
    } else {
      setUpdatedBy("");
      setComments((prevComments) => ({
        ...prevComments,
        sales: "",
        customer: "",
        staffing: "",
        inventory: "",
        salesOpportunities: "",
        rewards: "",
      }));
    }
  };


  // handle store dropdown options handle
  const storeResponseHandle = async (res, options) => {
    const storeNameOptions = await JSON.parse(res.data.stores);
    setCommentResponse(storeNameOptions);
    setDropdownOptions(options);
 
    localStorage.setItem("storename",options[0].value);

    if (options) {
   

      setDimkey(options[0].storekey)
    }

    setStoreId(storeNameOptions[0].dim_store_key);
    setDefaultStoreName(storeNameOptions[0].store_name);

    if (storeNameOptions[0].user_role === "Reader") {
      setIsReader(true);
      setMessageBoxValue("You are not allowed to submit comments");
    }
  };


  //fetching comments
  const fetchComments = async () => {
    setPageLoader(true);
    let StoreKey;
    try {
      const { res, DropdownValues: storeNameOptions } = await fetchOptions({
        requesttype: "storeinfobyemail",
        store_email: Store_Emailid,
        user_name: username,
        // store_email: "vijay.kumar@gtigrows.com",
        // user_name: "vijay.kumar@gtigrows.com",
      });

      localStorage.setItem("documenturl", res.data.documentation_url);
     
      if (res) {
        setPageLoader(false);
        setDropdownOptions(storeNameOptions);
        

    localStorage.setItem("storename",storeNameOptions[0].value);
        storeResponseHandle(res, storeNameOptions);
        responseHandle(res);
        StoreKey=storeNameOptions[0].storekey
      }
    } catch (error) {
      setError(true);
      setPageLoader(false);
      console.error("Error fetching data:", error.message);
    } finally {
    
      fetchPrevComments(StoreKey);
      setPageLoader(false);
    }
  };
  //fetching  previous comments
  const fetchPrevComments = async (value) => {
    localStorage.setItem("storeid", value ? value : Number(dimkey))
    try {
      var d = new Date();
      d.setDate(d.getDate() - 2);
      var reported_dt = d.toISOString().slice(0, 10);
     
      const response = await request("Post", {
        requesttype: "fetchcommentsbydate",
        reported_dt: reported_dt,
        store_id: value ? value : Number(dimkey),
      });

      if (response.data.statuscode === 200) {
        const previous_day_comments_data = response.data.data;
        let previous_day_comments = JSON.parse(previous_day_comments_data);

        setInfoComments((prev) => ({
          ...prev,
          sales: previous_day_comments.sales_comments,
          customer: previous_day_comments.customer_service_comments,
          staffing: previous_day_comments.staffing_comments,
          inventory: previous_day_comments.inventory_comments,
          rewards: previous_day_comments.loyalty_updates,
          salesOpportunities: previous_day_comments.store_opportunities_comments,
        }));
      } else {
        setInfoComments((prev) => ({
          ...prev,
          sales: "",
          customer: "",
          staffing: "",
          inventory: "",
          rewards: "",
          salesOpportunities: "",
        }));
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
    }
  };





//Storename onChange
  const handleStoreNameChange = (value, record) => {
// console.log("first",record)
    setCardLoading(true);
    localStorage.setItem("storename", record.value);
    setStoreName(record.value);
    setDropDownChange(true);

    let store_info = commentResponse.filter(
      (data) => data.dim_store_key === record.storekey
    );

    setStoreId(store_info[0].dim_store_key);
    localStorage.setItem("storeid", store_info[0].dim_store_key);
    localStorage.setItem("storename", store_info[0].store_name);

    if (store_info[0].user_role === "Reader") {
      setIsReader(true);
      setMessageBoxValue("You are not allowed to submit comments");
    }
    else{
      setIsReader(false);
      setMessageBoxValue("");
    }
    const getAllCommets = async () => {
      try {
        const responce = await request("Post", {
          requesttype: "storeinfobykey",
          store_id: record.storekey,
        });

        let res = await responce;

        setCardLoading(false);
        fetchPrevComments(record.storekey);
        responseHandle(res);
      } catch (error) {
        setCardLoading(false);
        console.error("Error fetching data:", error.message);
      } finally {
        setCardLoading(false);
      }
    };
    if (value) {
      getAllCommets();
    }
  };
  //call fetch comments function
  useEffect(() => {
    fetchComments();

  }, []);

  //navigate summary app-(summary-btn)
  const handle_summary = (e) => {
    navigate("/summary");
    setCardLoading(true);
  };


    //Save comments-(save-btn)
  const handle_submit = async (value) => {
    setSaveBtnLoading(true);
// console.log("2",commentResponse)
// console.log("3",storeId)
    let store_info = commentResponse.filter(
      (key) => key.dim_store_key == storeId
    );
    try {
      const response = await request("post", {
        requesttype: "save",
        store_timezone: store_info[0].store_timezone,
        store_id: store_info[0].dim_store_key,
        user_id: store_info[0].user_id,
        user_name: username,
        // user_name:"vijay.kumar@gtigrows.com",
        store_name: store_info[0].store_name,
        sales_comments: comments.sales,
        staffing_comments: comments.staffing,
        inventory_comments: comments.inventory,
        store_opportunities_comments: comments.salesOpportunities,
        customer_service_comments: comments.customer,
        loyalty_updates: comments.rewards,
      });

      const data = await response.data.statuscode;
      if (data === 200) {
     
        setSaveBtnLoading(false);
        message.success(response.data.data);
        setMessageBoxValue("");
      } else if (data === 403) {
        setSaveBtnLoading(false);

        setMessageBoxValue("Store Comments have been processed");
        setIsReader(true);
      }
    } catch (error) {
      message.error("Data Save Failed!");
      console.error("Error fetching data:", error.message);
      setSaveBtnLoading(false);
    } finally {
      setSaveBtnLoading(false);
    }
  };


  //user fields
  const UserDatas = [
    {
      type: "paragraph",
      label: "Store Email Id",
      className: "form-input-noborder",
      value: username,
    },
    {
      type: "dropdown",
      label: "Store Name",
      placeholder: "storename",
      width: 200,
      className: "form-field-lg",
      onChange: (value, record) => handleStoreNameChange(value, record),
      options: dropdownOptions,
      value: dropDownChange === true ? storeName : defaultStoreName,
    },
    {
      type: "paragraph",
      label: "Updated By",
      className: "form-input-noborder",
      value: updatedBy ? updatedBy : "",
    },
    // {
    //   type: "button",
    //   onClick: handle_summary,
    //   disabled: false,
    //   className:  DarkMode==="true"||DarkMode===true?"primary-btn-dark":"primary-btn",
    //   label: "Summary",
    //   loading: saveBtnLoading,
    // },
  ];

    //commentbox fields
  const data = [
    {
      type: "textarea",
      rows: 4,
      maxLength: 500,
      readOnly: isReader,
      value: comments.sales,
      title: "Sales Highlights",
      showCount: true,
      onChange: (e) =>
        setComments((prev) => ({
          ...prev,
          sales: e.target.value,
        })),
      loading: cardLoading,
      IconClick: () => openModal("Sales Highlights", infoComments.sales),
    },
    {
      type: "textarea",
      rows: 4,
      maxLength: 500,
      readOnly: isReader,
      value: comments.customer,
      title: "Customer",
      showCount: true,
      onChange: (e) =>
        setComments((prev) => ({
          ...prev,
          customer: e.target.value,
        })),
      loading: cardLoading,
      IconClick: () => openModal("Customer", infoComments.customer),
    },
    {
      type: "textarea",
      rows: 4,
      maxLength: 500,
      readOnly: isReader,
      value: comments.staffing,
      title: "Staffing",
      showCount: true,
      onChange: (e) =>
        setComments((prev) => ({
          ...prev,
          staffing: e.target.value,
        })),
      loading: cardLoading,
      IconClick: () => openModal("Staffing", infoComments.staffing),
    },
    {
      type: "textarea",
      rows: 4,
      maxLength: 500,
      readOnly: isReader,
      value: comments.inventory,
      title: "Inventory",
      showCount: true,
      onChange: (e) =>
        setComments((prev) => ({
          ...prev,
          inventory: e.target.value,
        })),
      loading: cardLoading,
      IconClick: () => openModal("Inventory", infoComments.inventory),
    },
    {
      type: "textarea",
      rows: 4,
      maxLength: 500,
      readOnly: isReader,
      value: comments.rewards,
      title: "Rewards",
      showCount: true,
      onChange: (e) =>
        setComments((prev) => ({
          ...prev,
          rewards: e.target.value,
        })),
      loading: cardLoading,
      IconClick: () => openModal("Rewards", infoComments.rewards),
    },
    {
      type: "textarea",
      rows: 4,
      maxLength: 500,
      readOnly: isReader,
      value: comments.salesOpportunities,
      title: "Sale Opportunities",
      showCount: true,
      onChange: (e) =>
        setComments((prev) => ({
          ...prev,
          salesOpportunities: e.target.value,
        })),
      loading: cardLoading,
      IconClick: () =>
        openModal("Sale Opportunities", infoComments.salesOpportunities),
    },
  ];

  return (
    pageLoader ? (
      <Spinner />
    ) : error === true ? (
      navigate("error")
    ) : (
      <div className="ccr-submit-container">
        <DynamicModal
          visible={isModalVisible}
          onClose={handleModalClose}
          title={modalTitle}
          content={<p className="info-msg">{modalContent}</p>}
        />

        <section className="ccr-header">
          <h3 className={DarkMode==="true"||DarkMode===true?"page-header-dark":"page-header"}>CLOSING COMMENTS APP</h3>
          <section className="userinfo-card">
          <UserInfo fieldData={UserDatas} />
          </section>
       
        </section>

          <section className="comment-section" >
            <CommentsData fieldData={data} className={"cmt-section"} />
          </section>
      

        <div className="cmt-save-btn">
          <div className="access-msg">
            {messageBoxValue ? messageBoxValue : ""}
          </div>
          <Button
            onClick={handle_submit}
            loading={saveBtnLoading}
            label={"Submit"}
            className={isReader?"disabled":DarkMode==="true"||DarkMode===true?"primary-btn-dark":"primary-btn"}
           
            disabled={isReader}
            
          />
        </div>
      </div>
    )
  );
}

export default CcrSubmit;
