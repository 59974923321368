import React from 'react'
import { Table as AntTable } from "antd";
import "./Table.css"
function Table({loading,className,onChange,columns,dataSource}) {


  return (
    <div className='data-table'>
    <AntTable
    loading={loading}
    className={`table ${className}`}
    onChange={onChange}
    dataSource={dataSource}
    columns={columns}
    bordered
    scroll={{
        x: 1500,
        y: 400,
        scrollToFirstRowOnChange: true, 
      
      }}
      scrollToFirstRowOnChange={true} 
      
    pagination={{
      pageSize: 5,
      showSizeChanger: false,
    }}
  />
    </div>

  )
}

export default Table