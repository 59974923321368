import { Select } from 'antd';
import React from 'react';
import "./Dropdown.css"

function Dropdown({ showSearch, value, placeholder, allowClear, onChange, options, className,mode ,disabled, defaultValue}) {
  return (
    <div>
      <Select
        disabled={disabled}
        className={className}
        allowClear={allowClear}
        showSearch={showSearch}
        placeholder={placeholder}
        value={value}
        mode={mode}
        onChange={onChange}
        defaultValue={defaultValue}
        optionFilterProp="children"
        filterOption={(input, option) =>
          String(option?.value).toLowerCase().includes(input.toLowerCase())
        }
        filterSort={(optionA, optionB) =>
          String(optionA?.value).toLowerCase().localeCompare(String(optionB?.label).toLowerCase())
        }
        options={options}
      />
    </div>
  );
}

export default Dropdown;
