import React, { useContext, useEffect, useState } from 'react'
import { DeleteOutlined, EditOutlined, SearchOutlined, InfoCircleFilled } from '@ant-design/icons';
import { useMsal } from '@azure/msal-react';
import { Space, Form, message } from 'antd';
import { useRef } from 'react';
import Highlighter from 'react-highlight-words';
import "./storekeymaster.css"
import { request } from '../../../customHook/request';

import DynamicModal from '../../organisms/DynamicModel/DynamicModel';
import Table from '../../atoms/Table/Table';
import Input from '../../atoms/Input/Input';
import Button from '../../atoms/Button/Button';
import { UserInfo } from '../../organisms/UserInfo/UserInfo';

import 'dayjs/locale/es'; // Import the desired locale
import { ThemeContext } from '../../../customHook/useContext/Usecontext';
import moment from 'moment';
import dayjs from 'dayjs';

function StoreKeyMaster() {
    const [fixedColumnLeft, setFixedColumnLeft] = useState("left")


    const dateFormat = "YYYY-MM-DD";

    const monthFormat = "YYYY-MM-DD";

    const [addRecord, setAddRecord] = useState(
        {
            storeName: "",
            storeState: "",
            llApiKey: "",
            systemCode: "",
            licenseType: "",
            storeNameAlias: "",
            storePhone: "",
            storeManager: "",
            storeEmail: "",
            storeHours: "",
            storeAddress: "",
            storeZip: "",
            storeCity: "",
            storeRegion: "",
            storeStreet: "",
            storeTimeZone: "",
            gmEmail: "",
            gmPhoneNumber: "",
            districtManager: "",
            dmPhoneNumber: "",
            regionalManager: "",
            rmPhoneNumber: "",
            startDate: "",
            intacctLocationId: "",
            currentKey: "",
            apiInterval: ""
        }
    )

    const [editRecord, setEditRecord] = useState(
        {
            storeName: "",
            storeState: "",
            llApiKey: "",
            newllApiKey: "",
            systemCode: "",
            licenseType: "",
            storeNameAlias: "",
            storePhone: "",
            storeManager: "",
            storeEmail: "",
            storeHours: "",
            storeAddress: "",
            storeZip: "",
            storeCity: "",
            storeRegion: "",
            storeStreet: "",
            storeTimeZone: "",
            gmEmail: "",
            gmPhoneNumber: "",
            districtManager: "",
            dmPhoneNumber: "",
            regionalManager: "",
            rmPhoneNumber: "",
            startDate: "",
            intacctLocationId: "",
            currentKey: "",
            apiInterval: ""
        }
    )

   


    const [storeStateFilter, setStoreStateFilter] = useState([])
    const [licenseTypeFilter, setLicenseTypeFilter] = useState([]);
    const [storeSystemFilter, setstoreSystemFilter] = useState([])
    const [loading, setLoading] = useState(true)
    const [saveLoading, setSaveLoading] = useState(false)
    const [updateLoading, setUpdateLoading] = useState(false)
    const [fetchStoreKeys, setFetchStoreKeys] = useState([])
    const [isModalAdd, setIsModalAdd] = useState(false)
    const [isModalEdit, setIsModalEdit] = useState(false)
    const [systemDropDown, setSystemDropDown] = useState([])
    const [licenseTypeDropDown, setLicenseTypeDropDown] = useState([])
    const { accounts } = useMsal();
    const username = accounts[0] && accounts[0].name;
    const Store_Emailid = accounts[0] && accounts[0].username;
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [pageLoader, setPageLoader] = useState(false);
    const { state } = useContext(ThemeContext);
    const DarkMode = state.darkMode;

    useEffect(() => {
        const handleResize = () => {


            if (window.innerWidth < 1000) {
                setFixedColumnLeft("")

                // setFixedColumnRight("")
            } else {
                setFixedColumnLeft("left")

                // setFixedColumnRight("right")
            }
        };

        window.addEventListener("resize", handleResize);

        // Call handleResize initially to set the class based on the initial window width
        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                    maxWidth: "20rem"
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >

                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}

                        size="small"
                        style={{
                            width: 90,
                        }}
                        label={"Search"}
                        Icon={SearchOutlined}
                        className={"third-btn"}
                    >

                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                        label={"Reset"}
                        className={"third-btn"}
                    >

                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                        label={"close"}
                        className={"third-btn"}
                    >

                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (

            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) => {

            if (record.store_name !== null && record.online_title !== null) {
                return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            }
        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });


    const columns = [
        {
            key: '1',
            title: "Store Name",
            dataIndex: 'store_name',
            fixed: fixedColumnLeft,
            width: 220,
            ...getColumnSearchProps('store_name'),
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => {
                const storeNameA = a.store_name || '';
                const storeNameB = b.store_name || '';
                return storeNameA.localeCompare(storeNameB);
            },

            minWidth: 60
        },
        {
            key: '2',
            title: "Store State",
            align: 'center',
            dataIndex: 'store_state',
            fixed: fixedColumnLeft,
            width: 140,
            filters:
                storeStateFilter,
            filterMode: 'tree',
            onFilter: (value, record) => (record && record.store_state && record.store_state.startsWith(value)),
            minWidth: 60
        },
        {
            key: '3',
            title: "API Key",
            align: 'center',
            dataIndex: 'llapikey',
            fixed: fixedColumnLeft,
            width: 180,
            minWidth: 60
        },
        {
            key: '4',
            title: "Store Name Alias",
            dataIndex: 'store_name_alias',
            fixed: fixedColumnLeft,
            width: 200,
            ...getColumnSearchProps('store_name_alias'),
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => {
                const storeNameA = a.store_name_alias || '';
                const storeNameB = b.store_name_alias || '';
                return storeNameA.localeCompare(storeNameB);
            },

            minWidth: 60
        },
        {
            key: '5',
            title: "License Type",
            dataIndex: 'license_type',
            align: "center",
            width: 180,
            filters: licenseTypeFilter,
            filterMode: 'tree',
            onFilter: (value, record) => {
                if (record && record.license_type) {
                    return record.license_type.startsWith(value);
                }
                return false; // Handle the case where record or license_type is null or undefined
            }

        },
        {
            key: '6',
            title: "Store System",
            dataIndex: 'system',
            align: "center",
            width: 150,
            filters:
                storeSystemFilter,
            filterMode: 'tree',
            onFilter: (value, record) => (record && record.system && record.system.startsWith(value))
        },
        {
            key: '7',
            title: "Store Phone",
            dataIndex: 'store_phone',
            width: 150,
        },
        {
            key: '8',
            title: "Store Manager",
            dataIndex: 'store_manager',
            width: 160
        },
        {
            key: '9',
            title: "Store Email",
            dataIndex: 'store_email',
            width: 280
        },
        {
            key: '10',
            title: "Store Hours",
            dataIndex: 'store_hours',
            width: 380
        },
        {
            key: '11',
            title: "Store Address",
            dataIndex: 'store_address',
            width: 300
        },
        {
            key: '12',
            title: "Store Street",
            dataIndex: 'store_street',
            // align:"center",
            width: 230
        },
        {
            key: '13',
            title: "Store City",
            dataIndex: 'store_city',
            // align:"center",
            width: 130
        },
        {
            key: '14',
            title: "Store State",
            dataIndex: 'store_state',
            align: "center",
            width: 150
        },
        {
            key: '15',
            title: "Store Zip",
            dataIndex: 'store_zip',
            align: "center",
            width: 100
        },
        {
            key: '16',
            title: "Time Zone",
            dataIndex: 'store_timezone',
            align: "center",
            width: 110
        },
        {
            key: '17',
            title: "Store Region",
            dataIndex: 'store_region',
            align: "center",
            width: 150
        },

        {
            key: '18',
            title: "GM Mail",
            dataIndex: 'gm_email',
            align: "center",
            width: 150
        },

        {
            key: '19',
            title: "GM Phone Number",
            dataIndex: 'gm_phone_number',
            align: "center",
            width: 200
        },

        {
            key: '20',
            title: "District Manager",
            dataIndex: 'district_manager',
            align: "center",
            width: 150
        },

        {
            key: '21',
            title: "DM Phone Number",
            dataIndex: 'dm_phone_number',
            align: "center",
            width: 200
        },

        {
            key: '22',
            title: "Regional Manager",
            dataIndex: 'regional_manager',
            align: "center",
            width: 200
        },

        {
            key: '23',
            title: "RM Phone Number",
            dataIndex: 'rm_phone_number',
            align: "center",
            width: 200
        },

        {
            key: '24',
            title: "Start Date",
            dataIndex: 'start_date',
            align: "center",
            width: 130
        },
        {
            key: '25',
            title: "Intacct Account",
            dataIndex: 'intacct_location_id',
            align: "center",
            width: 180
        },
        {
            key: '26',
            title: "Current Key",
            dataIndex: 'current_key',
            align: "center",
            width: 160
        },
        {
            key: '27',
            title: "API Interval",
            dataIndex: 'api_interval',
            align: "center",
            width: 110
        },

        {
            key: '28',
            title: "Actions",
            fixed: "right",
            width: fixedColumnLeft ? 120 : 100,
            render: (record) => {
                return <>
                    <div className='action-button'>
                        <EditOutlined onClick={() => handle_edit(record)} className='table-icon' />
                        <DeleteOutlined onClick={() => handle_delete(record)} className='table-delete-icon' />
                    </div>
                </>
            },
            minWidth: 100
        },
    ];

    // const handleDateChange = (date, dateString) => {
    //     // console.log(dateString);
    //     setAddRecord((prevAddRecord) => ({...prevAddRecord, startDate: dateString}))
    //     // 'date' is the moment object, 'dateString' is the string representation of the date
    //     console.log('Selected Date:', date);
    //     console.log('Formatted Date String:', dateString);
    //     // Update the state with the selected date
    //     // setAddRecord((prevAddRecord) => ({ ...prevAddRecord, startDate: dateString}));
    // };

    const handleDateChange = (date, dateString) => {
        // Log the entire date object to inspect its properties
        // console.log('Date Object:', dateString);
        // setAddRecord({...addRecord,startDate:dateString})
    
        setAddRecord((prevAddRecord) => ({...prevAddRecord,startDate:date}))
        setEditRecord((prevEditRecord) => ({...prevEditRecord,startDate:date}))
    };

    const userData = [
        {
            type: "input",
            label: "Store Name",
            placeholder: "Store Name",
            width: 200,
            className: "form-field-lg",
            value: addRecord.storeName,
            onChange: (e) => { setAddRecord({ ...addRecord, storeName: e.target.value }) }
        },

    

        {
            type: "input",
            label: "API Key",
            placeholder: "API Key",
            width: 200,
            className: "form-field-lg",
            value: addRecord.llApiKey,
            onChange: (e) => { setAddRecord({ ...addRecord, llApiKey: e.target.value }) }
        },

        {
            type: "dropdown",
            label: "System Code",
            placeholder: "System Code",
            width: 200,
            className: "form-field-lg",
            options: systemDropDown,
            value: addRecord.systemCode,
            onChange: (value) => { setAddRecord({ ...addRecord, systemCode: value }) }
        },

        {
            type: "dropdown",
            label: "License Type",
            placeholder: "License Type",
            width: 200,
            className: "form-field-lg",
            options: licenseTypeDropDown,
            value: addRecord.licenseType,
            onChange: (value) => { setAddRecord({ ...addRecord, licenseType: value }) }
        },

        {
            type: "input",
            label: "Store Name Alias",
            placeholder: "Store Name Alias",
            width: 200,
            className: "form-field-lg",
            value: addRecord.storeNameAlias,
            onChange: (e) => { setAddRecord({ ...addRecord, storeNameAlias: e.target.value }) }
        },

        {
            type: "input",
            label: "Phone",
            placeholder: "Phone",
            width: 200,
            className: "form-field-lg",
            value: addRecord.storePhone,
            onChange: (e) => { setAddRecord({ ...addRecord, storePhone: e.target.value }) }
        },

        {
            type: "input",
            label: "Manager",
            placeholder: "Manager",
            width: 200,
            className: "form-field-lg",
            value: addRecord.storeManager,
            onChange: (e) => { setAddRecord({ ...addRecord, storeManager: e.target.value }) }
        },

        {
            type: "input",
            label: "Email",
            placeholder: "Email",
            width: 200,
            className: "form-field-lg",
            value: addRecord.storeEmail,
            onChange: (e) => { setAddRecord({ ...addRecord, storeEmail: e.target.value }) }
        },

        {
            type: "input",
            label: "Hours",
            placeholder: "Hours",
            width: 200,
            className: "form-field-lg",
            value: addRecord.storeHours,
            onChange: (e) => { setAddRecord({ ...addRecord, storeHours: e.target.value }) }
        },

        {
            type: "input",
            label: "Store Address",
            placeholder: "Store Address",
            width: 200,
            className: "form-field-lg",
            value: addRecord.storeAddress,
            onChange: (e) => { setAddRecord({ ...addRecord, storeAddress: e.target.value }) }
        },

        {
            type: "input",
            label: "Store Zip",
            placeholder: "Store Zip",
            width: 200,
            max:5,
           
            className: "form-field-lg",
            // value: addRecord.storeZip,
            defaultValue:"",
            onChange: (e) => {Fetch_Zip_Code(e)}



        },
        {
            type: "input",
            label: "Store State",
            placeholder: "Store State",
            width: 200,
            className: "form-field-lg",
            value: addRecord.storeState,
            onChange: (e) => { setAddRecord({ ...addRecord, storeState: e.target.value }) }
        },

        {
            type: "input",
            label: "Store City",
            placeholder: "Store City",
            width: 200,
            className: "form-field-lg",
            value: addRecord.storeCity,
            onChange: (e) => { setAddRecord({ ...addRecord, storeCity: e.target.value }) }
        },

        {
            type: "input",
            label: "Store Region",
            placeholder: "Store Region",
            width: 200,
            className: "form-field-lg",
            value: addRecord.storeRegion,
            onChange: (e) => { setAddRecord({ ...addRecord, storeRegion: e.target.value }) }
        },

    

        {
            type: "input",
            label: "Store Time Zone",
            placeholder: "Store Time Zone",
            width: 200,
            className: "form-field-lg",
            value: addRecord.storeTimeZone,
            onChange: (e) => { setAddRecord({ ...addRecord, storeTimeZone: e.target.value }) }
        },
        {
            type: "input",
            label: "Store Street",
            placeholder: "Store Street",
            width: 200,
            className: "form-field-lg",
            value: addRecord.storeStreet,
            onChange: (e) => { setAddRecord({ ...addRecord, storeStreet: e.target.value }) }
        },

        {
            type: "input",
            label: "GM Email",
            placeholder: "GM Email",
            width: 200,
            className: "form-field-lg",
            value: addRecord.gmEmail,
            onChange: (e) => { setAddRecord({ ...addRecord, gmEmail: e.target.value }) }
        },

        {
            type: "input",
            label: "GM Phone Number",
            placeholder: "GM Phone Number",
            width: 200,
            className: "form-field-lg",
            value: addRecord.gmPhoneNumber,
            onChange: (e) => { setAddRecord({ ...addRecord, gmPhoneNumber: e.target.value }) }
        },

        {
            type: "input",
            label: "District Manager",
            placeholder: "District Manager",
            width: 200,
            className: "form-field-lg",
            value: addRecord.districtManager,
            onChange: (e) => { setAddRecord({ ...addRecord, districtManager: e.target.value }) }
        },

        {
            type: "input",
            label: "DM Phone Number",
            placeholder: "DM Phone Number",
            width: 200,
            className: "form-field-lg",
            value: addRecord.dmPhoneNumber,
            onChange: (e) => { setAddRecord({ ...addRecord, dmPhoneNumber: e.target.value }) }
        },

        {
            type: "input",
            label: "Regional Manager",
            placeholder: "Regional Manager",
            width: 200,
            className: "form-field-lg",
            value: addRecord.regionalManager,
            onChange: (e) => { setAddRecord({ ...addRecord, regionalManager: e.target.value }) }
        },

        {
            type: "input",
            label: "RM Phone Number",
            placeholder: "RM Phone Number",
            width: 200,
            className: "form-field-lg",
            value: addRecord.rmPhoneNumber,
            onChange: (e) => { setAddRecord({ ...addRecord, rmPhoneNumber: e.target.value }) }
        },

        // {
        //     type: "date",
        //     label: "Start date",
        //     placeholder: "Start Date",
        //     width: 200,
        //     className: "form-field-lg",
        //     value: addRecord.startDate,
        //     defaultValue: addRecord.startDate,
        //     onchange: (date,dateString) => handleDateChange(date,dateString)
        // },

        {
            type: "date",
            label: "Start Date",
            placeholder: "Start Date",
            width: 200,
            className: "form-field-lg",
            allowClear: false,
            value: addRecord.startDate,
            // defaultValue: addRecord.startDate,
            onChange: (date, dateString) => handleDateChange(date, dateString)
        },

  

        {
            type: "input",
            label: "Intacct Location",
            placeholder: "Intacct Location",
            width: 200,
            format: monthFormat,
            className: "form-field-lg",
            value: addRecord.intacctLocationId,
            onChange: (e) => { setAddRecord({ ...addRecord, intacctLocationId: e.target.value }) }
        },

        {
            type: "input",
            label: "Current Key",
            placeholder: "Current Key",
            width: 200,
            className: "form-field-lg",
            value: addRecord.currentKey,
            onChange: (e) => { setAddRecord({ ...addRecord, currentKey: e.target.value }) }
        },

        {
            type: "input",
            label: "Api Interval",
            placeholder: "Api Interval",
            width: 200,
            className: "form-field-lg",
            value: addRecord.apiInterval,
            onChange: (e) => { setAddRecord({ ...addRecord, apiInterval: e.target.value }) }
        },

        {
            type: "button",
            className: "primary-btn",
            label: "Save",
            name: "Save",
            valuePropName: "checked",
            loading: saveLoading,
            wrapperCol: {
                offset: 0,
                span: 24,
            },
            onClick: () => handle_save(),
        },

        {
            className: "bottom-btn",
            type: "button",
            label: "Cancel",
            name: "Cancel",
            valuePropName: "checked",
            wrapperCol: {
                offset: 0,
                span: 24,
            },
            onClick: () => { setIsModalAdd(false); handle_clear() }
        },
    ]

    const userDataEdit = [
        {
            type: "input",
            label: "Store Name",
            placeholder: "Store Name",
            width: 200,
            className: "form-field-lg",
            value: editRecord.storeName,
            onChange: (e) => { setEditRecord({ ...editRecord, storeName: e.target.value }) }
        },

  

        {
            type: "input",
            label: "API Key",
            placeholder: "API Key",
            width: 200,
            className: "form-field-lg",
            value: editRecord.llApiKey,
            onChange: (e) => { setEditRecord({ ...editRecord, newllApiKey: e.target.value }) }
        },

        {
            type: "dropdown",
            label: "System Code",
            placeholder: "System Code",
            width: 200,
            className: "form-field-lg",
            value: editRecord.systemCode,
            options: systemDropDown,
            onChange: (value) => { setEditRecord({ ...editRecord, systemCode: value }) }
        },

        {
            type: "dropdown",
            label: "License Type",
            placeholder: "License Type",
            width: 200,
            className: "form-field-lg",
            value: editRecord.licenseType,
            options: licenseTypeDropDown,
            onChange: (value) => { setEditRecord({ ...editRecord, licenseType: value }) }
        },

        {
            type: "input",
            label: "Store Name Alias",
            placeholder: "Store Name Alias",
            width: 200,
            className: "form-field-lg",
            value: editRecord.storeNameAlias,
            onChange: (e) => { setEditRecord({ ...editRecord, storeNameAlias: e.target.value }) }
        },

        {
            type: "input",
            label: "Phone",
            placeholder: "Phone",
            width: 200,
            className: "form-field-lg",
            value: editRecord.storePhone,
            onChange: (e) => { setEditRecord({ ...editRecord, storePhone: e.target.value }) }
        },

        {
            type: "input",
            label: "Manager",
            placeholder: "Manager",
            width: 200,
            className: "form-field-lg",
            value: editRecord.storeManager,
            onChange: (e) => { setEditRecord({ ...editRecord, storeManager: e.target.value }) }
        },

        {
            type: "input",
            label: "Email",
            placeholder: "Email",
            width: 200,
            className: "form-field-lg",
            value: editRecord.storeEmail,
            onChange: (e) => { setEditRecord({ ...editRecord, storeEmail: e.target.value }) }
        },

        {
            type: "input",
            label: "Hours",
            placeholder: "Hours",
            width: 200,
            className: "form-field-lg",
            value: editRecord.storeHours,
            onChange: (e) => { setEditRecord({ ...editRecord, storeHours: e.target.value }) }
        },

        {
            type: "input",
            label: "Store Address",
            placeholder: "Store Address",
            width: 200,
            className: "form-field-lg",
            value: editRecord.storeAddress,
            onChange: (e) => { setEditRecord({ ...editRecord, storeAddress: e.target.value }) }
        },

        {
         
            type: "input",
            label: "Store Zip",
            placeholder: "Store Zip",
            max:5,
            width: 200,
            className: "form-field-lg",
            value: editRecord.storeZip,
            onChange: (e) => Fetch_Zip_Code(e)
        },
        {
            type: "input",
            label: "Store State",
            placeholder: "Store State",
            width: 200,
            className: "form-field-lg",
            value: editRecord.storeState,
            onChange: (e) => { setEditRecord({ ...editRecord, storeState: e.target.value }) }
        },

        {
            type: "input",
            label: "Store City",
            placeholder: "Store City",
            width: 200,
            className: "form-field-lg",
            value: editRecord.storeCity,
            onChange: (e) => { setEditRecord({ ...editRecord, storeCity: e.target.value }) }
        },

        {
            type: "input",
            label: "Store Region",
            placeholder: "Store Region",
            width: 200,
            className: "form-field-lg",
            value: editRecord.storeRegion,
            onChange: (e) => { setEditRecord({ ...editRecord, storeRegion: e.target.value }) }
        },

   

        {
            type: "input",
            label: "Store Time Zone",
            placeholder: "Store Time Zone",
            width: 200,
            className: "form-field-lg",
            value: editRecord.storeTimeZone,
            onChange: (e) => { setEditRecord({ ...editRecord, storeTimeZone: e.target.value }) }
        },

        {
            type: "input",
            label: "Store Street",
            placeholder: "Store Street",
            width: 200,
            className: "form-field-lg",
            value: editRecord.storeStreet,
            onChange: (e) => { setEditRecord({ ...editRecord, storeStreet: e.target.value }) }
        },

        {
            type: "input",
            label: "GM Email",
            placeholder: "GM Email",
            width: 200,
            className: "form-field-lg",
            value: editRecord.gmEmail,
            onChange: (e) => { setEditRecord({ ...editRecord, gmEmail: e.target.value }) }
        },

        {
            type: "input",
            label: "GM Phone Number",
            placeholder: "GM Phone Number",
            width: 200,
            className: "form-field-lg",
            value: editRecord.gmPhoneNumber,
            onChange: (e) => { setEditRecord({ ...editRecord, gmPhoneNumber: e.target.value }) }
        },

        {
            type: "input",
            label: "District Manager",
            placeholder: "District Manager",
            width: 200,
            className: "form-field-lg",
            value: editRecord.districtManager,
            onChange: (e) => { setEditRecord({ ...editRecord, districtManager: e.target.value }) }
        },

        {
            type: "input",
            label: "DM Phone Number",
            placeholder: "DM Phone Number",
            width: 200,
            className: "form-field-lg",
            value: editRecord.dmPhoneNumber,
            onChange: (e) => { setEditRecord({ ...editRecord, dmPhoneNumber: e.target.value }) }
        },

        {
            type: "input",
            label: "Regional Manager",
            placeholder: "Regional Manager",
            width: 200,
            className: "form-field-lg",
            value: editRecord.regionalManager,
            onChange: (e) => { setEditRecord({ ...editRecord, regionalManager: e.target.value }) }
        },

        {
            type: "input",
            label: "RM Phone Number",
            placeholder: "RM Phone Number",
            width: 200,
            className: "form-field-lg",
            value: editRecord.rmPhoneNumber,
            onChange: (e) => { setEditRecord({ ...editRecord, rmPhoneNumber: e.target.value }) }
        },

        {
            type: "defaultdate",
            label: "Start Date",
            placeholder: "Start Date",
            dateFormat:dateFormat,
            width: 200,
            allowClear: false,
            className: "form-field-lg",
            // value: editRecord.startDate,
            defaultValue: editRecord.startDate,
           
            onChange: (date, dateString) => handleDateChange(date, dateString), 
        },


        {
            type: "input",
            label: "Intacct Location",
            placeholder: "Intacct Location",
            width: 200,
            className: "form-field-lg",
            value: editRecord.intacctLocationId,
            onChange: (e) => { setEditRecord({ ...editRecord, intacctLocationId: e.target.value }) }
        },

        {
            type: "input",
            label: "Current Key",
            placeholder: "Current Key",
            width: 200,
            className: "form-field-lg",
            value: editRecord.currentKey,
            onChange: (e) => { setEditRecord({ ...editRecord, currentKey: e.target.value }) }
        },

        {
            type: "input",
            label: "Api Interval",
            placeholder: "Api Interval",
            width: 200,
            className: "form-field-lg",
            value: editRecord.apiInterval,
            onChange: (e) => { setEditRecord({ ...editRecord, apiInterval: e.target.value }) }
        },

        {
            type: "button",
            className: "primary-btn",
            label: "Update",
            name: "Update",
            loading: updateLoading,
            valuePropName: "checked",
            wrapperCol: {
                offset: 0,
                span: 24,
            },
            onClick: () => handle_update(),
        },

        {
            className: "bottom-btn",
            type: "button",
            label: "Cancel",
            name: "Cancel",
            valuePropName: "checked",
            wrapperCol: {
                offset: 0,
                span: 24,
            },
            onClick: () => setIsModalEdit(false),
        },
    ]

    useEffect(() => {
        if (fetchStoreKeys === "") {
            setPageLoader(true)
        }
        else if (fetchStoreKeys !== "") {
            setPageLoader(false)
        }
        let StoreState_filter = []
        let LicenseType_filter = []
        let StoreSystem_filter = []
        const key = "store_state";
        const StoreStateUniqueByKey = [...new Map(fetchStoreKeys.map(item =>
            [item[key], item])).values()];
        if (StoreStateUniqueByKey.length > 0) {
            StoreStateUniqueByKey.map((res) => {
                if (res.store_state !== "") {
                    StoreState_filter.push({
                        text: res.store_state,
                        value: res.store_state
                    },)
                }
                return StoreState_filter
            }
            )
        }
        const LicenseTypeUniqueByKey = [...new Map(fetchStoreKeys.map(item =>
            [item["license_type"], item])).values()];
        if (LicenseTypeUniqueByKey.length > 0) {
            LicenseTypeUniqueByKey.map((res) => {
                if (res.license_type !== "") {
                    LicenseType_filter.push({
                        text: res.license_type,
                        value: res.license_type
                    },)
                }
                return LicenseType_filter
            })
        }
        const StoresystemUniqueByKey = [...new Map(fetchStoreKeys.map(item =>
            [item["system"], item])).values()];
        if (StoresystemUniqueByKey.length > 0) {
            StoresystemUniqueByKey.map((res) => {
                if (res.system !== "") {
                    StoreSystem_filter.push({
                        text: res.system,
                        value: res.system
                    },)
                }
                return StoreSystem_filter
            })
        }
        setStoreStateFilter(StoreState_filter)
        setLicenseTypeFilter(LicenseType_filter)
        setstoreSystemFilter(StoreSystem_filter)
    }, [fetchStoreKeys])



    const FetchStore = async () => {
        try {
            const response = await request("Post", {
                "requesttype": "fetchstorekeys"
            })
            let res = await JSON.parse(response.data.data);
            setFetchStoreKeys(res)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setPageLoader(false);
            console.error("Error fetching data:", error.message);
        } finally {
            setLoading(false)
            setPageLoader(false);
        }
    }




    const FetchDropDown = async () => {
        try {
            const response = await request("Post", {
                "requesttype": "fetchskmapp",
                "app_id": 1
            })
            const data = JSON.parse(response.data.data)
            let license_typeFilt_system = data.filter((res) => res.message_type === 'System')
            let System = [];
            for (let object of license_typeFilt_system) {
                let obj = {
                    value: object.message_key,
                    label: object.message_key
                }
                System.push(obj);
            }
            setSystemDropDown(System)
            let license_typeFilt = data.filter((res) => res.message_type === 'License_type')
            let License = [];
            for (let object of license_typeFilt) {
                let obj = {
                    value: object.message_key,
                    label: object.message_key
                }
                License.push(obj);
            }
            setLicenseTypeDropDown(License)
        } catch (error) {
            setPageLoader(false);
            console.error("Error fetching data:", error.message);
        }
    }

    // const Fetch_Zip_Code = async (e) => {
    //     setAddRecord({ ...addRecord, storeZip: e.target.value })
    //     setEditRecord((prevEditRecord) => ({ ...prevEditRecord, storeZip: e.target.value }))
    //     if (e.target.value === "") {
    //         setAddRecord({ ...addRecord, storeState: "", storeCity: "", storeTimeZone: "", storeRegion: "" })
    //         // setEditRecord({ ...editRecord, storeState: "", storeCity: "", storeTimeZone: "", storeRegion: "" })
    //         setEditRecord((prevEditRecord) => ({ ...prevEditRecord, storeState: "", storeCity: "", storeTimeZone: "", storeRegion: "" }))
    //     }
    //     if (e.target.value.length >= 5) {
    //         try {
    //             const response = await request("Post", {
    //                 "requesttype": "citybyzipcode",
    //                 "state_id": "NY",
    //                 "zip_code": e.target.value
    //             })
    //             const data = JSON.parse(response.data.data)
               
    //             if (data !== "") {
    //                 setAddRecord({
    //                     ...addRecord,
    //                     storeState: data.state_id,
    //                     storeCity: data.city,
    //                     storeTimeZone: data.timezone_abbr,
    //                     storeRegion: data.state_id
    //                 })
    //                 setEditRecord((prevEditRecord) => ({
    //                     ...prevEditRecord,
    //                     storeState: data.state_id,
    //                     storeCity: data.city,
    //                     storeTimeZone: data.timezone_abbr,
    //                     storeRegion: data.state_id
    //                 }))

    //                 // setEditRecord({
    //                 //     ...editRecord,
    //                 //     storeState: data.state_id,
    //                 //     storeCity: data.city,
    //                 //     storeTimeZone: data.timezone_abbr,
    //                 //     storeRegion: data.state_id
    //                 // })
    //             }
    //             // else {
    //             //     setAddRecord({ ...addRecord, storeState: "", storeCity: "", storeTimeZone: "", storeRegion: "" })
    //             //     setEditRecord({ ...editRecord, storeState: "", storeCity: "", storeTimeZone: "", storeRegion: "" })
    //             // }

    //         } catch (error) {
    //             message.error("Please Enter Valid Zip Code")
    //             console.error("Error fetching data:", error.message);
    //         }
    //     }
    // }

    const Fetch_Zip_Code = async (e) => {
        setAddRecord({ ...addRecord, storeZip:e.target.value })
        setEditRecord((prevEditRecord) => ({ ...prevEditRecord, storeZip: e.target.value }))
        if (e.target.value === "") {
            setAddRecord({ ...addRecord, storeState: "", storeCity: "", storeTimeZone: "", storeRegion: "" })
       
            setEditRecord((prevEditRecord) => ({ ...prevEditRecord, storeState: "", storeCity: "", storeTimeZone: "", storeRegion: "" }))
        }
       
        if (e.target.value.length >0) {
            try {
                const response = await request("Post", {
                    "requesttype": "citybyzipcode",
                    "state_id": "NY",
                    "zip_code": e.target.value
                })
                const data = JSON.parse(response.data.data)
                const StatusCode = JSON.parse(response.data.statuscode)
               console.log("first",StatusCode)
                if (StatusCode === 200) {
                    setAddRecord({
                        ...addRecord,
                        storeState: data.state_id,
                        storeCity: data.city,
                        storeTimeZone: data.timezone_abbr,
                        storeRegion: data.state_id
                    })
                    setEditRecord((prevEditRecord) => ({
                        ...prevEditRecord,
                        storeState: data.state_id,
                        storeCity: data.city,
                        storeTimeZone: data.timezone_abbr,
                        storeRegion: data.state_id
                    }))

    
                }
              
               



                
            

            } catch (error) {
                setAddRecord({
                    ...addRecord,
                    storeState: "",
                    storeCity: "",
                    storeTimeZone: "",
                    storeRegion: ""
                })
                setEditRecord((prevEditRecord) => ({
                    ...prevEditRecord,
                    storeState: "",
                    storeCity: "",
                    storeTimeZone: "",
                    storeRegion: ""
                }))
               
                console.error("Error fetching data:", error.message);
            }
        }
    }


    const handle_save = async () => {
        setLoading(true)
        setSaveLoading(true)
        try {
            const res = await request("Post", {
                "requesttype": "form_gen_crud",
                "op_type": "new",
                "app_id": "3",
                "user_name": username,
                "user_id": Store_Emailid,
                // "user_name": "vijay.kumar@gtigrows.com",
                // "user_id": "vijay.kumar@gtigrows.com",
                "store_name": addRecord.storeName,
                "llapikey": addRecord.llApiKey,
                "store_timezone": addRecord.storeTimeZone,
                "store_state": addRecord.storeState,
                "store_city": addRecord.storeCity,
                "store_zip": addRecord.storeZip,
                "store_street": addRecord.storeStreet,
                "store_manager": addRecord.storeManager,
                "store_email": addRecord.storeEmail,
                "store_phone": addRecord.storePhone,
                "store_hours": addRecord.storeHours,
                "store_region": addRecord.storeRegion,
                "store_address": addRecord.storeAddress,
                "current_key": addRecord.currentKey,
                "license_type": addRecord.licenseType,
                "system": addRecord.systemCode,
                "api_interval": addRecord.apiInterval,
                "store_name_alias": addRecord.storeNameAlias,
                "start_date": dayjs( addRecord.startDate ).format(dateFormat),
                "intacct_location_id": addRecord.intacctLocationId,
                "gm_email": addRecord.gmEmail,
                "gm_phone_number": addRecord.gmPhoneNumber,
                "district_manager": addRecord.districtManager,
                "dm_phone_number": addRecord.dmPhoneNumber,
                "regional_manager": addRecord.regionalManager,
                "rm_phone_number": addRecord.rmPhoneNumber
            })
            if (res.data.statuscode === 200) {
                message.success("New Storekey added Successfully")
                setIsModalAdd(false)
                setSaveLoading(false)
                setLoading(false)
                FetchStore()
                handle_clear()
            }
            else {
                setSaveLoading(false)
                setLoading(false)
                message.warning("Storekey not added")
            }
        } catch (error) {
            setLoading(false)
            setSaveLoading(false)
            message.warning("Storekey not added")
            console.error("Error fetching data:", error.message);
        } finally {
            setSaveLoading(false)
            setLoading(false)
        }
    }

    const handle_update = async () => {
        console.log(editRecord)
        setLoading(true)
        setUpdateLoading(true)
       
        try {
            const res = await request("Post", {
                "requesttype": "form_gen_crud",
                "op_type": "update",
                "app_id": "3",
                "user_name": username,
                "user_id": Store_Emailid,
                // "user_name": "vijay.kumar@gtigrows.com",
                // "user_id": "vijay.kumar@gtigrows.com",
                "store_name": editRecord.storeName,
                "llapikey": editRecord.llApiKey,
                "new_llapikey": editRecord.newllApiKey,
                "store_timezone": editRecord.storeTimeZone,
                "store_state": editRecord.storeState,
                "store_city": editRecord.storeCity,
                "store_zip": editRecord.storeZip,
                "store_street": editRecord.storeStreet,
                "store_manager": editRecord.storeManager,
                "store_email": editRecord.storeEmail,
                "store_phone": editRecord.storePhone,
                "store_hours": editRecord.storeHours,
                "store_region": editRecord.storeRegion,
                "store_address": editRecord.storeAddress,
                "current_key": editRecord.currentKey.toString(),
                "license_type": editRecord.licenseType,
                "system": editRecord.systemCode,
                "api_interval": editRecord.apiInterval,
                "store_name_alias": editRecord.storeNameAlias,
                "start_date":  dayjs( editRecord.startDate).format(dateFormat),
                "intacct_location_id": editRecord.intacctLocationId,
                "gm_email": editRecord.gmEmail,
                "gm_phone_number": editRecord.gmPhoneNumber,
                "district_manager": editRecord.districtManager,
                "dm_phone_number": editRecord.dmPhoneNumber,
                "regional_manager": editRecord.regionalManager,
                "rm_phone_number": editRecord.rmPhoneNumber
            })
            if (res.data.statuscode === 200) {
                message.success("Storekey Data updated successfully.")
                setIsModalEdit(false)
                setUpdateLoading(false)
                setLoading(false)
                FetchStore()
            }
            else {
                setUpdateLoading(false)
                setLoading(false)
                message.warning("Storekey Data not Updated")
            }
        } catch (error) {
            setLoading(false)
            setUpdateLoading(false)
            message.warning(" Storekey Data not Updated")
            console.error("Error fetching data:", error.message);
        } finally {
            setUpdateLoading(false)
            setLoading(false)
        }
    }

    const handle_delete = async (value) => {
        setLoading(true)
        try {
            const response = await request("Post", {
                "requesttype": "form_gen_crud",
                "op_type": "delete",
                "app_id": "3",
                "user_name": username,
                "user_id": Store_Emailid,
                "store_name": value.store_name,
                "llapikey": value.llapikey,
                "store_timezone": value.store_timezone,
                "store_state": value.store_state,
                "store_city": value.store_city,
                "store_zip": value.store_zip,
                "store_street": value.store_street,
                "store_manager": value.store_manager,
                "store_email": value.store_email,
                "store_phone": value.store_phone,
                "store_hours": value.store_hours,
                "store_region": value.store_region,
                "store_address": value.store_address,
                "current_key": value.current_key,
                "license_type": value.license_type,
                "system": value.system,
                "api_interval": value.api_interval,
                "store_name_alias": value.store_name_alias,
                "start_date": value.start_date,
                "intacct_location_id": value.intacct_location_id,
            })
            let res = await JSON.parse(response.data.statuscode);
            if (res === 200) {
                message.success("Data Deleted Successfull")
                setLoading(false)
                FetchStore()
            }
        } catch (error) {
            setLoading(false)
            message.warning("Data Not Deleted")
            console.error("Error fetching data:", error.message);
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        FetchStore();
        FetchDropDown()
    }, [])

    const handle_edit = (value, record) => {
        console.log(value)
        setIsModalEdit(true)
        setEditRecord((prevEditRecord) => ({
            ...prevEditRecord,
            storeName: value?.store_name || "",
            storeState: value?.store_state || "",
            llApiKey: value?.llapikey || "",
            newllApiKey: value?.llapikey || "",
            systemCode: value?.system || "",
            licenseType: value?.license_type || "",
            storeNameAlias: value?.store_name_alias || "",
            storePhone: value?.store_phone || "",
            storeManager: value?.store_manager || "",
            storeEmail: value?.store_email || "",
            storeHours: value?.store_hours || "",
            storeAddress: value?.store_address || "",
            storeZip: value?.store_zip || "",
            storeCity: value?.store_city || "",
            storeRegion: value?.store_state || "",
            storeStreet: value?.store_street || "",
            storeTimeZone: value?.store_timezone || "",
            startDate: value.start_date,
            intacctLocationId: value?.intacct_location_id || "",
            currentKey: value?.current_key || "",
            apiInterval: value?.api_interval || "",
            gmEmail: value?.gm_email || "",
            gmPhoneNumber: value?.gm_phone_number || "",
            districtManager: value?.district_manager || "",
            dmPhoneNumber: value?.dm_phone_number || "",
            regionalManager: value?.regional_manager || "",
            rmPhoneNumber: value?.rm_phone_number || ""
        }))
    }
    
console.log(editRecord.startDate,"null")

    const handle_clear = async () => {
        setAddRecord(
            {
                storeName: "",
                storeState: "",
                llApiKey: "",
                systemCode: "",
                licenseType: "",
                storeNameAlias: "",
                storePhone: "",
                storeManager: "",
                storeEmail: "",
                storeHours: "",
                storeAddress: "",
                storeZip: "",
                storeCity: "",
                storeRegion: "",
                storeStreet: "",
                storeTimeZone: "",
                startDate: "",
                intacctLocationId: "",
                currentKey: "",
                apiInterval: ""
            }
        )
    }


    return (
        <div className='storekeymaster-container'>
            <DynamicModal   visible={isModalAdd} content={<UserInfo fieldData={userData} defaultDate={addRecord.startDate}  />} title={"Add Record"} onClose={() => { handle_clear(); setIsModalAdd(false)}} width={1100}  />
            <DynamicModal visible={isModalEdit} content={<UserInfo fieldData={userDataEdit}  />} title={"Edit Record"} onClose={() => setIsModalEdit(false)} width={1100} />
        
           <div className='header-session'>
                <h3 className={DarkMode==="true"||DarkMode===true?"page-header-dark":"page-header"}>STOREKEYMASTER</h3>
                <Button
                    onClick={() => {
                        setAddRecord((prevAddRecord) => ({...prevAddRecord,startDate:""}))
                        setIsModalAdd(true)
                  
                    }}
                    label={"Add Record"}
                    className={"third-btn"}
                />
            </div>

            <div>
                <Table loading={loading} dataSource={fetchStoreKeys} columns={columns} />
            </div>

        </div>
    )
}

export default StoreKeyMaster
