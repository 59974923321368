import React, { useState } from 'react'



import PageTwo from './PageTwo'
import PageOne from './PageOne'

function PurchaceOrder() {

  const [status, setStatus] = useState(localStorage.getItem("status"))
  const [data, setData] = useState([])
  const [invoiceNo, setInvoiceNo] = useState()



  const handleDropdownChange = (value) => {
    if (value===true|| localStorage.getItem("status") === true) {
      setStatus(true)
    }
   if(value===false) {
      setStatus(false)
    }

  }
  const handleInvoiceNoChange = (value) => {
    setInvoiceNo(value);
  }
  const handleData = (value) => {
    setData(value);
  }




  return (

   

      <>



{status === true ? (
  <PageTwo data={data} InvoiceNo={invoiceNo} onDropdownChange={handleDropdownChange} />
) : (
  localStorage.getItem("status") === "true" ? (
    <PageTwo data={data} InvoiceNo={invoiceNo} onDropdownChange={handleDropdownChange} />
  ) : (
    <PageOne
      onDropdownChange={handleDropdownChange}
      onDataChange={handleData}
      InvoiceNo={handleInvoiceNoChange}
    />
  )
)}
</>




  )
}

export default PurchaceOrder
