import React, { useContext, useEffect, useState } from 'react'
import { DeleteOutlined, EditOutlined, SearchOutlined, InfoCircleFilled } from '@ant-design/icons';
import { useMsal } from '@azure/msal-react';
import { Space, Form, message } from 'antd';
import { useRef } from 'react';
import Highlighter from 'react-highlight-words';
import moment from 'moment'
import { useNavigate } from 'react-router';
import { request } from '../../../customHook/request';
import DynamicModal from '../../organisms/DynamicModel/DynamicModel';
import Table from '../../atoms/Table/Table';
import Input from '../../atoms/Input/Input';
import Button from '../../atoms/Button/Button';
import { UserInfo } from '../../organisms/UserInfo/UserInfo';
import { ThemeContext } from '../../../customHook/useContext/Usecontext';

function MasterCpgEntry() {

    const { accounts } = useMsal();
    const username = accounts[0] && accounts[0].name;
    const Store_Emailid = accounts[0] && accounts[0].username;
    const { state } = useContext(ThemeContext);
    const DarkMode = state.darkMode;
    const [addRecord, setAddRecord] = useState({
        locationName: "",
        roomName: "",
        subRoom: "",
        productGroup: "",
    })

    const [editRecord, setEditRecord] = useState({
        locationName: "",
        newLocationName: "",
        roomName: "",
        newRoomName: "",
        subRoom: "",
        productGroup: "",
    })

    const [locationDropDown, setLocationDropDown] = useState([])
    const [roomNameDropDown, setRoomNameDropDown] = useState([])
    const [subRoomDropDown, setSubRoomDropDown] = useState([])
    const [productGroupDropDown, setProductGroupDropDown] = useState([])

    const [fetchData, setFetchData] = useState("")
    const [loading, setLoading] = useState(true)
    const [pageLoader, setPageLoader] = useState(true);
    const [saveLoading, setSaveLoading] = useState(false)
    const [updateLoading, setUpdateLoading] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [isModalAdd, setIsModalAdd] = useState(false)
    const [isModalEdit, setIsModalEdit] = useState(false)

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                    maxWidth: "20rem"
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >

                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                        label={"Search"}
                        Icon={SearchOutlined}
                        className={"third-btn"}
                    >

                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                        label={"Reset"}
                        className={"third-btn"}
                    >

                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                        label={"close"}
                        className={"third-btn"}
                    >

                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (

            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            key: '1',
            title: "Location Name",
            dataIndex: 'location_name',
            // fixed: "left",
            // width: 170,
            ...getColumnSearchProps('location_name'),
        },
        {
            key: '2',
            title: "Room Name",
            align: 'left',
            dataIndex: 'roomname',
            // fixed: "left",
            // width: 240,
            ...getColumnSearchProps('roomname'),
        },
        {
            key: '3',
            title: "Sub Rooms",
            align: 'center',
            dataIndex: 'sub_rooms',
            // fixed: "left",
            // width: 140,
        },
        {
            key: '4',
            title: "Product Group",
            align: 'center',
            dataIndex: 'product_group',
            // fixed: "left",
            // width: 140,
        },

        {
            key: '5',
            title: 'Actions',
            fixed: "right",
            width: 100,
            render: (record) => {
                return <>
                    <div className='action-button'>
                        <EditOutlined
                            onClick={() => handle_edit_record(record)}
                            className='table-icon' />
                        <DeleteOutlined
                            onClick={() => handle_delete(record)}
                            className='table-delete-icon' />
                    </div>
                </>
            },
        },
    ];

    const UserData = [
        {
            type: "dropdown",
            label: "Location Name",
            placeholder: "Location Name",
            width: 200,
            className: "form-field-lg",
            options: locationDropDown,
            value: addRecord.locationName,
            onChange: (value) => { setAddRecord({ ...addRecord, locationName: value }) }
        },

        {
            type: "dropdown",
            label: "Room Name",
            placeholder: "Room Name",
            width: 200,
            className: "form-field-lg",
            options: roomNameDropDown,
            value: addRecord.roomName,
            onChange: (value) => { setAddRecord({ ...addRecord, roomName: value }) }
        },

        {
            type: "dropdown",
            label: "Sub Room",
            placeholder: "Sub Room",
            width: 200,
            className: "form-field-lg",
            options: subRoomDropDown,
            value: addRecord.subRoom,
            onChange: (value) => { setAddRecord({ ...addRecord, subRoom: value }) }
        },

        {
            type: "dropdown",
            label: "Product Group",
            placeholder: "Product Group",
            width: 200,
            className: "form-field-lg",
            options: productGroupDropDown,
            value: addRecord.productGroup,
            onChange: (value) => { setAddRecord({ ...addRecord, productGroup: value }) }
        },

        {
            type: "button",
            className: "bottom-btn",
            label: "Save",
            name: "Save",
            valuePropName: "checked",
            loading: saveLoading,
            wrapperCol: {
                offset: 0,
                span: 24,
            },
            onClick: () => handle_save(),
        },

        {
            className: "bottom-btn",
            type: "button",
            label: "Cancel",
            name: "Cancel",
            valuePropName: "checked",
            wrapperCol: {
                offset: 0,
                span: 24,
            },
            onClick: () => setIsModalAdd(false),
        },
    ]

    const UserDataEdit = [
        {
            type: "dropdown",
            label: "Location Name",
            placeholder: "Location Name",
            width: 200,
            className: "form-field-lg",
            options: locationDropDown,
            value: editRecord.locationName,
            onChange: (value) => { setEditRecord({ ...editRecord, newLocationName: value }) }
        },

        {
            type: "dropdown",
            label: "Room Name",
            placeholder: "Room Name",
            width: 200,
            className: "form-field-lg",
            options: roomNameDropDown,
            value: editRecord.roomName,
            onChange: (value) => { setEditRecord({ ...editRecord, newRoomName: value }) }
        },

        {
            type: "dropdown",
            label: "Sub Room",
            placeholder: "Sub Room",
            width: 200,
            className: "form-field-lg",
            options: subRoomDropDown,
            value: editRecord.subRoom,
            onChange: (value) => { setEditRecord({ ...editRecord, subRoom: value }) }
        },

        {
            type: "dropdown",
            label: "Product Group",
            placeholder: "Product Group",
            width: 200,
            className: "form-field-lg",
            options: productGroupDropDown,
            value: editRecord.productGroup,
            onChange: (value) => { setEditRecord({ ...editRecord, productGroup: value }) }
        },

        {
            type: "button",
            className: "bottom-btn",
            label: "Update",
            name: "Update",
            valuePropName: "checked",
            loading: updateLoading,
            wrapperCol: {
                offset: 0,
                span: 24,
            },
            onClick: () => handle_update(),
        },

        {
            className: "bottom-btn",
            type: "button",
            label: "Cancel",
            name: "Cancel",
            valuePropName: "checked",
            wrapperCol: {
                offset: 0,
                span: 24,
            },
            onClick: () => setIsModalEdit(false),
        },
    ]

    const FetchData = async () => {
        try {
            const response = await request("Post", {
                "requesttype": "master_cpg_import",
                "op_type": "fetch_master_cpg_import",
                "user_id": Store_Emailid,
                "user_name": username
            })
            let res = await JSON.parse(response.data.data);
            if (response.data.statuscode === 200) {
                setFetchData(res)
                setLoading(false)
            }
            else if (response.data.statuscode === 404) {
                setLoading(false)
                message.error("Data Unavailable")
            }
        } catch (error) {
            setLoading(false)
            // setPageLoader(false);
            console.error("Error fetching data:", error.message);
        } finally {
            setLoading(false)
            // setPageLoader(false);
        }
    }

    const LocationName = async () => {
        try {
            const response = await request("Post", {
                "requesttype": "master_cpg_import",
                "op_type": "fetch_master_cpg_import_location_name",
                "user_id": Store_Emailid,
                "user_name": username
            })
            let res = await JSON.parse(response.data.data);
            if (response.data.statuscode === 200) {
                let locationName = []
                res.map((res) => {
                    locationName.push({
                        text: res.location_name,
                        value: res.location_name
                    })
                    return locationName
                })
                setLocationDropDown(locationName)
            }
        } catch (error) {
            setLoading(false)
            // setPageLoader(false);
            console.error("Error fetching data:", error.message);
        } finally {
            setLoading(false)
            // setPageLoader(false);
        }
    }

    const RoomName = async () => {
        try {
            const response = await request("Post", {
                "requesttype": "master_cpg_import",
                "op_type": "fetch_master_cpg_import_room_name",
                "user_id": Store_Emailid,
                "user_name": username
            })
            let res = await JSON.parse(response.data.data);
            if (response.data.statuscode === 200) {
                let RoomName = []
                res.map((res) => {
                    RoomName.push({
                        text: res.roomname,
                        value: res.roomname
                    })
                    return RoomName
                })
                setRoomNameDropDown(RoomName)
            }
        } catch (error) {
            setLoading(false)
            // setPageLoader(false);
            console.error("Error fetching data:", error.message);
        } finally {
            setLoading(false)
            // setPageLoader(false);
        }
    }

    const SubRoom = async () => {
        try {
            const response = await request("Post", {
                "requesttype": "master_cpg_import",
                "op_type": "fetch_master_cpg_import_sub_rooms",
                "user_id": Store_Emailid,
                "user_name": username
            })
            let res = await JSON.parse(response.data.data);
            if (response.data.statuscode === 200) {
                let SubRoom = []
                res.map((res) => {
                    SubRoom.push({
                        text: res.sub_rooms,
                        value: res.sub_rooms
                    })
                    return SubRoom
                })
                setSubRoomDropDown(SubRoom)
            }
        } catch (error) {
            setLoading(false)
            // setPageLoader(false);
            console.error("Error fetching data:", error.message);
        } finally {
            setLoading(false)
            // setPageLoader(false);
        }
    }

    const ProductGroup = async () => {
        try {
            const response = await request("Post", {
                "requesttype": "master_cpg_import",
                "op_type": "fetch_master_cpg_import_product_group",
                "user_id": Store_Emailid,
                "user_name": username
            })
            let res = await JSON.parse(response.data.data);
            if (response.data.statuscode === 200) {
                let ProductGroup = []
                res.map((res) => {
                    ProductGroup.push({
                        text: res.product_group,
                        value: res.product_group
                    })
                    return ProductGroup
                })
                setProductGroupDropDown(ProductGroup)
            }
        } catch (error) {
            setLoading(false)
            // setPageLoader(false);
            console.error("Error fetching data:", error.message);
        } finally {
            setLoading(false)
            // setPageLoader(false);
        }
    }

    const handle_edit_record = (value) => {
        // console.log(value, "value")
        setIsModalEdit(true)
        setEditRecord((prevEditRecord) => ({
            ...prevEditRecord,
            locationName: value.location_name,
            newLocationName: value.location_name,
            roomName: value.roomname,
            newRoomName: value.roomname,
            subRoom: value.sub_rooms,
            productGroup: value.product_group
        }))
    }

    const handle_save = async () => {
        setLoading(true)
        setSaveLoading(true)
        try {
            const response = await request("Post", {
                "requesttype": "master_cpg_import",
                "op_type": "new",
                "message_key": "master_cpg_import",
                "user_id": Store_Emailid,
                "user_name": username,
                "location_name": addRecord.locationName,
                "roomname": addRecord.roomName,
                "product_group": addRecord.productGroup,
                "sub_rooms": addRecord.subRoom
            })
            if (response.data.statuscode === 200) {
                setSaveLoading(false)
                setLoading(false)
                message.success("Data Save Successfully")
                setIsModalAdd(false)
                FetchData()
                handle_clear()
            }
            else {
                setSaveLoading(false);
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            setSaveLoading(false)
            console.error("Error fetching data:", error.message);
        } finally {
            setSaveLoading(false)
            setLoading(false)
        }
    }

    const handle_update = async () => {
        setLoading(true)
        setUpdateLoading(true)
        try {
            const response = await request("Post", {
                "requesttype": "master_cpg_import",
                "op_type": "update",
                "message_key": "master_cpg_import",
                "user_id": Store_Emailid,
                "user_name": username,
                "location_name": editRecord.locationName,
                "new_location_name": editRecord.newLocationName,
                "roomname": editRecord.roomName,
                "new_roomname": editRecord.newRoomName,
                "product_group": editRecord.productGroup,
                "sub_rooms": editRecord.subRoom
            })
            if (response.data.statuscode === 200) {
                setUpdateLoading(false)
                setLoading(false)
                message.success("Data Update Successfully")
                setIsModalEdit(false)
                FetchData()
            }
            else {
                setUpdateLoading(false);
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            setUpdateLoading(false)
            console.error("Error fetching data:", error.message);
        } finally {
            setUpdateLoading(false)
            setLoading(false)
        }
    }

    const handle_delete = async (value) => {
        setLoading(true)
        setDeleteLoading(true)
        try {
            const response = await request("Post", {
                "requesttype": "master_cpg_import",
                "op_type": "delete",
                "message_key": "master_cpg_import",
                "user_id": Store_Emailid,
                "user_name": username,
                "location_name": value.location_name,
                "roomname": value.roomname,
                "product_group": value.product_group,
                "sub_rooms": value.sub_rooms
            })
            if (response.data.statuscode === 200) {
                setDeleteLoading(false)
                setLoading(false)
                message.success("Data Save Successfully")
                FetchData()
            }
            else {
                setDeleteLoading(false);
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            setDeleteLoading(false)
            console.error("Error fetching data:", error.message);
        } finally {
            setDeleteLoading(false)
            setLoading(false)
        }

    }

    useEffect(() => {
        FetchData()
        LocationName()
        RoomName()
        SubRoom()
        ProductGroup()
    }, [])

    const handle_add_record = () => {
        setIsModalAdd(true)
        handle_clear()
    }

    const handle_clear = () => {
        setAddRecord({
            locationName: "",
            roomName: "",
            subRoom: "",
            productGroup: "",
        })
    }

    return (
        <div className='storekeymaster-container'>
            <DynamicModal visible={isModalAdd} content={<UserInfo fieldData={UserData} />} title={"Add Record"} onClose={() => { setIsModalAdd(false); handle_clear() }} width={1100} />
            <DynamicModal visible={isModalEdit} content={<UserInfo fieldData={UserDataEdit} />} title={"Edit Record"} onClose={() => setIsModalEdit(false)} width={1100} />
            <div className='header-session'>
                <h3 className={DarkMode==="true"||DarkMode===true?"page-header-dark":"page-header"}>MASTER CPG ENTRY</h3>
                <Button
                    onClick={() => handle_add_record()}
                    label={"Add Record"}
                    className={"primary-btn"}
                />
            </div>

            <div>
                <Table loading={loading} dataSource={fetchData} columns={columns} />
            </div>

        </div>
    )
}

export default MasterCpgEntry