import React, { useState, useEffect, useContext } from 'react';
import { ArrowLeftOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import { ThemeContext } from '../../../customHook/useContext/Usecontext';
import "./Barcode.css";
import Button from '../../atoms/Button/Button';
import { useMsal } from "@azure/msal-react";
import { request } from '../../../customHook/request';
import { Table } from 'antd';


function ScannedData() {
  const { state } = useContext(ThemeContext);
  const { theme } = state;
  const [scannedData, setScannedData] = useState([]);
  const { accounts } = useMsal();
  const username = accounts[0] && accounts[0].name;

  useEffect(() => {
    const getAllCommets = async () => {
      try {
        const response = await request("Post", {
          "requesttype": "barcode_reader",
            // "user_name":  "vijay.kumar@gtigrows.com",
            "user_name":  username 
        //    username
           ,
          "op_type": "fetch"
        });

        if (response && response.data) {
          const barcode = await response.data.data;
          setScannedData(barcode);
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    getAllCommets();
  }, []);

  const columns = [
    {
      title: 'Serial No',
      dataIndex: 'serialNo',
      key: 'serialNo',
      align: 'center',
      width: 100,
      
    },
    {
      title: 'Scanned Barcodes',
      dataIndex: 'barcode_no',
      key: 'barcode_no',
      align: 'center',
    },
    {
      title: 'Scanned Time',
      dataIndex: 'scannedTime',
      key: 'scannedTime',
      align: 'center',
    }
  ];

  const dataSource = scannedData.map((data, index) => ({
    key: index,
    serialNo: index + 1,
    barcode_no: data.barcode_no,
    scannedTime: new Date().toLocaleTimeString()
  }));

  return (
    <>
      <div className="backbtn">
        <NavLink to={"/barcodescanner"}>
          <Button
            className={"primary-btn back-btn "}
            Icon={ArrowLeftOutlined}
          />
        </NavLink>

        <h3 className="receivedpage-header">
          <span className="receivedpage-header">BARCODE TABLE</span>
        </h3>
      </div>
<br/>
    
       
      <div >
          {scannedData && scannedData.length > 0 ? (
            <div >
              <Table columns={columns}
               dataSource={dataSource}
               scroll={{
                x: 100,
                y:350
              }}
              bordered
           
               />
            </div>
          ) : (
            <h3 style={{ color: "red" }}>No Barcodes Scanned</h3>
          )}
        </div>
   
    </>
  );
}

export default ScannedData;
