import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./config/msalconfig";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AppProvider } from './customHook/useContext/Usecontext';
const msalInstance = new PublicClientApplication(msalConfig);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    
  <MsalProvider instance={msalInstance}>
    <AppProvider>

   
 <BrowserRouter>
      <Routes>
        <Route path="/*"  element={<App />} />
   
      </Routes>
    </BrowserRouter>
    </AppProvider>
    </MsalProvider>

);


reportWebVitals();
