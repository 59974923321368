import { DatePicker } from "antd";
import Button from "../../atoms/Button/Button";
import AntCard from "../../atoms/Card/Card";
import CommentBox from "../../molecules/CommentBox/CommentBox";
import FormDropdown from "../../molecules/FormDropdown/FormDropdown";
import FormInput from "../../molecules/FormInput/FormInput";
import moment from "moment";
import "./UserInfo.css"
import dayjs from 'dayjs';
import Upload from "../../atoms/Upload/upload";
export const UserInfo = ({ fieldData }) => {


  //return input,dropdown based on type properties
  const cardContent = (
    <div className='user-card'>
      {fieldData.map((field, index) =>
        field.type === "paragraph" ? (
          <FormInput
            key={index}
            label={field.label}
            className={field.className}
            value={field.value}
            type={field.type}
            inputType={field.inputType}
            disabled={field.disabled}
          />
        ) : field.type === "dropdown" ? (
          <FormDropdown
            key={index}
            label={field.label}
            allowClear={field.allowClear}
            showSearch={field.showSearch}
            placeholder={field.placeholder}
            width={field.width}
            className={field.className}
            onChange={field.onChange}
            options={field.options}
            value={field.value}
            mode={field.mode}
            disabled={field.disabled}
            defaultValue={field.defaultValue}
          />
        ) : field.type === "button" ? (
          <Button
            key={index}
            onClick={field.onClick}
            loading={field.loading}
            disabled={field.disabled}
            Icon={field.Icon}
            label={field.label}
            className={field.className}
          />

        ) : field.type === "input" ? (
          <FormInput
            key={index}
            label={field.label}
            className={field.className}
            value={field.value}
            type={field.type}
            onChange={field.onChange}
            inputType={field.inputType}
            disabled={field.disabled}
          />
        ) : field.type === "date" ? (
          <DatePicker
            label={field.label}
            placeholder={field.placeholder}
            className={field.className}
            onChange={field.onChange}
            format={field.format}
            allowClear={field.allowClear}
            picker={field.picker}
            style={field.style}
            value={field.value }
            defaultValue={dayjs(field.value)}

          />
        ) : field.type === "upload" ? (

          <Upload label={field.label} icon={field.icon} props={field.props} onChange={field.onChange} customRequest={field.customRequest}/>

        ) : field.type === "buttonGroup" ? (
          <div key={index} style={{}}>
            {field.buttons.map((button, buttonIndex) => (

              <Button

                key={buttonIndex}
                onClick={button.onClick}
                label={button.label}
                className={button.className}
              />

            ))}
          </div>
        ) 
        :  field.type === "daysdate" ? (
          <DatePicker
          label={field.label}
          placeholder={field.placeholder}
          className={field.className}
          value={field.value}
          onChange={field.onChange}
          format={field.format}
          allowClear={field.allowClear}
          picker={field.picker}
          style={field.style}
          defaultValue={dayjs(field.defaultValue)}
        />
        ):

        field.type === "defaultdate"&& field.defaultValue ? (
          <DatePicker
          // defaultValue={parsedDate}
          label={field.label}
          placeholder={field.placeholder}
          className={field.className}
          value={dayjs(field.defaultValue)}
          onChange={field.onChange}
          format={field.dateFormat}
          allowClear={field.allowClear}
          // picker={field.picker}
          // style={field.style}
          // defaultValue={dayjs(field.defaultValue)}
        />
        )
:    field.type === "defaultdate"?(
  <DatePicker
  // defaultValue={parsedDate}
  label={field.label}
  placeholder={field.placeholder}
  className={field.className}

  onChange={field.onChange}
  format={field.dateFormat}
  allowClear={field.allowClear}
  // picker={field.picker}
  // style={field.style}
  // defaultValue={dayjs(field.defaultValue)}
/>
)

        :
        null

      )}
    </div>
  );

  return <AntCard className={"default-card user-card"} content={cardContent} />;
}





//CommentBox 
export const CommentsData = ({ fieldData, className }) => {
  const cardContent = (
    <div className={className}>
      {fieldData.map((field, index) =>
        field.type === "textarea" ? (
          <CommentBox
            key={index}
            onChange={field.onChange}
            rows={field.rows}
            maxLength={field.maxLength}
            readOnly={field.readOnly}
            value={field.value}
            title={field.title}
            showCount={field.showCount}
            loading={field.loading}
            IconClick={field.IconClick}
          />
        ) : null
      )}
    </div>
  );

  return cardContent;
}



