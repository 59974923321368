import { CommentOutlined, InfoCircleFilled } from "@ant-design/icons";
import "./CommentBox.css";
import AntCard from "../../atoms/Card/Card";
import TextArea from "../../atoms/TextArea/TextArea";
import { Divider } from "antd";

function CommentBox({ onChange, rows, showCount, maxLength, readOnly, value, title, loading, IconClick }) {

  return (
    <AntCard className={"cmt-card"} loading={loading} content={
      <>

        {/* CommentBox Header */}
        <div className="cmt-header">
          <div className="cmt-header-lt">
            <CommentOutlined className="cmt-icon" />
          </div>
          <div className="cmt-header-rt">
            <h4 className="cmt-title">{title}</h4>
            <div>
              <InfoCircleFilled className="info-icon" onClick={IconClick} />
            </div>
          </div>
        </div>

        {/* Divider */}
        <Divider className='divider' />

        {/* CommentBox content */}
        <div className="cmt-box">
          <TextArea
            maxLength={maxLength}
            onChange={onChange}
            readOnly={readOnly}
            rows={rows}
            showCount={showCount}
            value={value}
          />
        </div>
      </>

    }>
    </AntCard>
  );
}

export default CommentBox;
