
import React from 'react'

import { QuestionCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { useContext, useEffect, useState } from 'react';
import { FloatButton } from 'antd';
import { MdDarkMode } from "react-icons/md";
import { FaMagic } from "react-icons/fa";
import { ThemeContext } from '../../../customHook/useContext/Usecontext';
import { IoSunnySharp } from "react-icons/io5";
function Darkmode() {
    const [open, setOpen] = useState(false);
    const [darkMode, setDarkMode] = useState(localStorage.getItem("darkmode")==="true"?true:false);
    const { state, dispatch } = useContext(ThemeContext);
    const DarkMode = state.darkMode;
    const toggleDarkMode = () => {
        setOpen(!open);
        setDarkMode(!darkMode);
        localStorage.setItem("darkmode",!darkMode)
        dispatch({ type: "TOGGLE_DARK_MODE" });
    
      };
      const onChange = () => {
        setOpen(!open);
      };
      const onQuestionClick = () => {
        const documentUrl = localStorage.getItem("documenturl");
        if (documentUrl) {
          window.open(documentUrl, '_blank');
        }
      };
  return (
    <div className="float-icons-container">
    <FloatButton
      icon={open ? <CloseOutlined onClick={onChange} /> : <FaMagic onClick={onChange} />}
      type="primary"
      style={{ right:"9rem",top:5 }}
    />

    {open===true && (
     <div className={`float-icons-container`}>

        <FloatButton icon={<QuestionCircleOutlined onClick={onQuestionClick} />} type="primary" style={{right:"12rem",top:5 }} />
       
 
     <FloatButton
       icon={
        darkMode===true?
        (
          <IoSunnySharp style={{color:"blueviolet",fontSize:"1.3rem"}}  onClick={toggleDarkMode}/>
        )
    :
     (
    
      <MdDarkMode
      onClick={toggleDarkMode}
      className={`${DarkMode === false || DarkMode==="false" ? 'dark-mode' : 'not-dark-mode'}`}
    />
     )
       }
       type="default"
       style={{ right:"15rem",top:5}}
     />
   </div>
    )}
  </div>
  )
}

export default Darkmode