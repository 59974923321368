import React, { useContext, useEffect, useState } from 'react'
import { DeleteOutlined, EditOutlined, SearchOutlined, InfoCircleFilled } from '@ant-design/icons';
import { useMsal } from '@azure/msal-react';
import { Space, Form, message } from 'antd';
import { useRef } from 'react';
import Highlighter from 'react-highlight-words';
import moment from 'moment'
import { useNavigate } from 'react-router';
import { request } from '../../../customHook/request';
import DynamicModal from '../../organisms/DynamicModel/DynamicModel';
import Table from '../../atoms/Table/Table';
import Input from '../../atoms/Input/Input';
import Button from '../../atoms/Button/Button';
import { UserInfo } from '../../organisms/UserInfo/UserInfo';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import Dropdown from '../../atoms/Dropdown/Dropdown';
import _ from 'lodash';
import { ThemeContext } from '../../../customHook/useContext/Usecontext';

const monthFormat = 'YYYY-MM';


function CashSupplyExpenses() {

    const { accounts } = useMsal();
    const username = accounts[0] && accounts[0].name;
    const Store_Emailid = accounts[0] && accounts[0].username;
    const navigate = useNavigate()
    const[fixedColumnLeft,setFixedColumnLeft]=useState("left")
    const [addRecord, setAddRecord] = useState({
        storeName: localStorage.getItem("storename"),
        entryDate: "",
        catagory: "",
        reason: "",
        description: "",
        amount: "",
    })

    const [editRecord, setEditRecord] = useState({
        storeName: localStorage.getItem("storename"),
        entryDate: "",
        catagory: "",
        reason: "",
        description: "",
        amount: "",
        comments: "",
    })

    const [storeDrobDown, setStoreDropDown] = useState("")
    const [reasonDropDown, setReasonDropDown] = useState("")
    const [fetchData, setFetchData] = useState("")
    const [entriesKey, setEntriesKey] = useState("")
    const [storeNameFilterData, setStoreNameFilterData] = useState([])
    const [storeNameFilterDataApproval, setStoreNameFilterDataApproval] = useState([])
    const [approvalFilterData, setApprovalFilterData] = useState([])
    const [data, setData] = useState(false)
    const [approval, setApproval] = useState(true)
    const [approvalButton, setApprovalButton] = useState(true)
    const [approvalFilter, setApprovalFilter] = useState(false)
    const [catagoryDropDown, setCatagoryDropDown] = useState("")
    const [error, setError] = useState("")
    const [email, setEmail] = useState("")
    const [dateFilter, setDateFilter] = useState([])
    const [isApproveFilter, setIsApproveFilter] = useState([])
    const [editEnable, setEditEnable] = useState(false)
    const [disable, setDisable] = useState(true)
    const [buttonShow, setButtonShow] = useState(false)
    const [edit, setEdit] = useState(false)
    const [approveLoading, setApproveLoading] = useState(false)
    const [rejectLoading, setRejectLoading] = useState(false)
    const [selectedValue, setSelectedValue] = useState(localStorage.getItem("storename") ? localStorage.getItem("storename") : "");
    const [storeName, setStoreName] = useState("")
    const defaultValue = dayjs(localStorage.getItem("date"));
    const [date, setDate] = useState(getCurrentDate());
    const { state } = useContext(ThemeContext);
    const DarkMode = state.darkMode;


    useEffect(() => {
        const handleResize = () => {
         
      
          if (window.innerWidth < 1000 ) {
            setFixedColumnLeft("")
      
            // setFixedColumnRight("")
          } else {
            setFixedColumnLeft("left")
      
            // setFixedColumnRight("right")
          }
        };
      
        window.addEventListener("resize", handleResize);
      
        // Call handleResize initially to set the class based on the initial window width
        handleResize();
      
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }, []);

    function getCurrentDate() {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}`;
    }

    const handle_amount_change = (e) => {
        const inputValue = e.target.value;
        const cleanedValue = inputValue.replace(/[^0-9.]/g, '');
        const roundedValue = parseFloat(cleanedValue).toFixed(2);
        setAddRecord((prevAddRecord) => ({ ...prevAddRecord, amount: e.target.value }))
        setEditRecord((prevEditRecord) => ({ ...prevEditRecord, amount: e.target.value }))
        if (roundedValue > 500) {
            setError(`Approval Required From ${email}`);
        } else {
            setError('');
        }
    };


    const [loading, setLoading] = useState(true)
    const [saveLoading, setSaveLoading] = useState(false)
    const [updateLoading, setUpdateLoading] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [isModalAdd, setIsModalAdd] = useState(false)
    const [isModalEdit, setIsModalEdit] = useState(false)

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                    maxWidth: "20rem"
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >

                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                        label={"Search"}
                        Icon={SearchOutlined}
                        className={"third-btn"}
                    >

                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                        label={"Reset"}
                        className={"third-btn"}
                    >

                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                        label={"close"}
                        className={"third-btn"}
                    >

                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (

            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            key: '1',
            title: "Store Name",
            // align: 'center',
            dataIndex: 'store_name',
            fixed: fixedColumnLeft,
            width: 150,
        },
        {
            key: '2',
            title: "Entry Date",
            align: 'center',
            dataIndex: 'entry_date',
            fixed: fixedColumnLeft,
            width: 150,
            filters: dateFilter,
            filterMode: 'tree',
            filterSearch: true,
            onFilter: (value, record) => record.entry_date.startsWith(value),
        },
        {
            key: '3',
            title: "Catagory",
            align: 'center',
            dataIndex: 'sub_catagory',
            // fixed: "left",
            width: 160,
        },
        // {
        //     key: '4',
        //     title: "Sub Catagory",
        //     align: 'center',
        //     dataIndex: 'sub_catagory',
        //     // fixed: "left",
        //     width: 160,
        // },
        {
            key: '4',
            title: 'Reason',
            dataIndex: 'reason',
            width: 240,
            // align: "center",
        },
        {
            key: '5',
            title: 'Description',
            dataIndex: 'description',
            width: 250,
            // align: "center",
        },
        {
            key: '6',
            title: 'Amount',
            dataIndex: 'amount',
            width: 100,
            align: "center",
        },
        {
            key: '7',
            title: 'Comments',
            dataIndex: 'comments',
            width: 200,
            align: 'center',
        },
        {
            key: '8',
            title: 'User Name',
            dataIndex: 'user_name',
            width: 250,
            align: 'center',
        },
        {
            key: '9',
            title: 'Is Approved',
            dataIndex: 'is_approved',
            width: 170,
            align: 'center',
            filters: isApproveFilter,
            filterMode: 'tree',
            filterSearch: true,
            onFilter: (value, record) => record.is_approved.toString().startsWith(value),
        },
        editEnable === false
            ? {
                key: '10',
                title: 'Actions',
                fixed: 'right',
                width: 100,
                render: (record) => (
                    <div className="action-button">
                        <EditOutlined className="table-icon" onClick={() => handle_edit_record(record)} />
                        <DeleteOutlined
                            onClick={() => handle_delete(record)}
                            className="table-delete-icon"
                        />
                    </div>
                ),
            }
            :

            {
                key: '10',
                title: 'Actions',
                fixed: 'right',
                width: 100,
                render: (record) => (
                    // edit === true ?
                    <div className="action-button">
                        <EditOutlined className="table-icon" onClick={() => handle_edit_record(record)} />
                        <DeleteOutlined
                            onClick={() => handle_delete(record)}
                            className="table-delete-icon"
                        />
                    </div>
                    // : null
                ),
            }
    ];

  
      

    const UserData = [
        {
            type: "dropdown",
            label: "Store Name",
            placeholder: "Store Name",
            width: 200,
            className: "form-field-lg",
            options: storeDrobDown,
            // value: addRecord.storeName,
            defaultValue: localStorage.getItem("storename"),
            onChange: (value) => handle_store_name(value),
        },

        {
            type: "date",
            label: "Entry Date",
            placeholder: "Entry Date",
            width: 200,
            className: "form-field-lg",
            value: addRecord.entryDate,
            onChange: (dateString, date) => {
                setAddRecord({ ...addRecord, entryDate: date })
            }
        },

        {
            type: "dropdown",
            label: "Catagory",
            placeholder: "Catagory",
            width: 200,
            className: "form-field-lg",
            options: catagoryDropDown,
            value: addRecord.catagory,
            onChange: (value) => handle_catagory(value),
        },

        {
            type: "dropdown",
            label: "Reason",
            placeholder: "Reason",
            width: 200,
            className: "form-field-lg",
            options: reasonDropDown,
            value: addRecord.reason,
            onChange: (value) => { setAddRecord({ ...addRecord, reason: value }) }
        },

        {
            type: "input",
            label: "Description",
            placeholder: "Description",
            width: 200,
            className: "form-field-lg",
            // options: subRoomDropDown,
            value: addRecord.description,
            onChange: (e) => { setAddRecord({ ...addRecord, description: e.target.value }) }
        },

        {
            type: "input",
            label: "Amount",
            placeholder: "Amount",
            width: 200,
            className: "form-field-lg",
            value: addRecord.amount,
            onChange: (e) => handle_amount_change(e),
        },

        {
            type: "button",
            className: "bottom-btn",
            label: "Save",
            name: "Save",
            valuePropName: "checked",
            loading: saveLoading,
            wrapperCol: {
                offset: 0,
                span: 24,
            },
            onClick: () => handle_save(),
        },

        {
            className: "bottom-btn",
            type: "button",
            label: "Cancel",
            name: "Cancel",
            valuePropName: "checked",
            wrapperCol: {
                offset: 0,
                span: 24,
            },
            onClick: () => setIsModalAdd(false),
        },
    ]
    const UserDataEdit = [
        {
            type: "dropdown",
            label: "Store Name",
            placeholder: "Store Name",
            width: 200,
            className: "form-field-lg",
            options: storeDrobDown,
            // value: addRecord.storeName,
            defaultValue: localStorage.getItem("storename"),
            onChange: (value) => handle_store_name(value),
        },

        {
            type: "date",
            label: "Entry Date",
            placeholder: "Entry Date",
            width: 200,
            className: "form-field-lg",
            value: editRecord.entryDate,
            onChange: (dateString, date) => {
                setEditRecord({ ...editRecord, entryDate: date })
            }
        },

        {
            type: "dropdown",
            label: "Catagory",
            placeholder: "Catagory",
            width: 200,
            className: "form-field-lg",
            options: catagoryDropDown,
            value: editRecord.catagory,
            onChange: (value) => handle_catagory(value),
        },

        {
            type: "dropdown",
            label: "Reason",
            placeholder: "Reason",
            width: 200,
            className: "form-field-lg",
            options: reasonDropDown,
            value: editRecord.reason,
            onChange: (value) => { setEditRecord({ ...editRecord, reason: value }) }
        },

        {
            type: "input",
            label: "Description",
            placeholder: "Description",
            width: 200,
            className: "form-field-lg",
            // options: subRoomDropDown,
            value: editRecord.description,
            onChange: (e) => { setEditRecord({ ...editRecord, description: e.target.value }) }
        },

        {
            type: "input",
            label: "Amount",
            placeholder: "Amount",
            width: 200,
            className: "form-field-lg",
            value: editRecord.amount,
            onChange: (e) => handle_amount_change(e),
        },

        {
            type: "input",
            label: "Comments",
            placeholder: "Comments",
            width: 200,
            className: "form-field-lg",
            value: editRecord.comments,
            disabled: disable,
            onChange: (e) => (setEditRecord({ ...editRecord, comments: e.target.value })),
        },

        {
            type: "button",
            className: "bottom-btn",
            label: "Update",
            name: "Update",
            valuePropName: "checked",
            loading: updateLoading,
            wrapperCol: {
                offset: 0,
                span: 24,
            },
            onClick: () => handle_update(),
        },
        // (
        //     buttonShow === false ? null :
        //         {
        //             type: "button",
        //             className: "bottom-btn",
        //             label: "Approve",
        //             name: "Approve",
        //             valuePropName: "checked",
        //             loading: approveLoading,
        //             wrapperCol: {
        //                 offset: 0,
        //                 span: 24,
        //             },
        //             // onClick: () => handle_update(),

        //         },
        //     {
        //         type: "button",
        //         className: "bottom-btn",
        //         label: "Reject",
        //         name: "Reject",
        //         valuePropName: "checked",
        //         loading: rejectLoading,
        //         wrapperCol: {
        //             offset: 0,
        //             span: 24,
        //         },
        //         onClick: () => handle_update(),

        //     }

        // ),

        // {
        //     className: "bottom-btn",
        //     type: "button",
        //     label: "Cancel",
        //     name: "Cancel",
        //     valuePropName: "checked",
        //     wrapperCol: {
        //         offset: 0,
        //         span: 24,
        //     },
        //     onClick: () => setIsModalEdit(false),
        // },
    ]
    if (buttonShow) {
        UserDataEdit.push(
            {
                type: "button",
                className: "bottom-btn",
                label: "Approve",
                name: "Approve",
                valuePropName: "checked",
                loading: approveLoading,
                wrapperCol: {
                    offset: 0,
                    span: 24,
                },
                onClick: () => handle_approve(),
            },
            {
                type: "button",
                className: "bottom-btn",
                label: "Reject",
                name: "Reject",
                valuePropName: "checked",
                loading: rejectLoading,
                wrapperCol: {
                    offset: 0,
                    span: 24,
                },
                onClick: () => handle_reject(),
            }
        );
    }

    UserDataEdit.push({
        className: "bottom-btn",
        type: "button",
        label: "Cancel",
        name: "Cancel",
        valuePropName: "checked",
        wrapperCol: {
            offset: 0,
            span: 24,
        },
        onClick: () => setIsModalEdit(false),
    });

    const handle_pending_approval = async (value) => {
        setApproval(false)
        setLoading(true)
        setData(true)
        setApprovalButton(false)
        setEditEnable(true)
        setButtonShow(true)
        setDisable(false)
        try {
            const response = await request("Post", {
                "requesttype": "petty_cash_entry",
                "op_type": "approver_pending_list",
                "user_id": Store_Emailid,
                // "user_id": "greg.zambelli@gtigrows.com",
                "user_name": username,
            })
            let res = await JSON.parse(response.data.data);
            if (response.data.statuscode === 200) {
                setLoading(false)
                setApprovalFilterData(res)
                res.map((res) => {
                    if (res.is_approver === "NO") {
                        setEdit(true)
                        // setButtonShow(true)
                    }
                    else {
                        setEdit(false)
                        // setButtonShow(false)
                    }
                })
            }
            else if (response.data.statuscode === 404) {
                setLoading(false)
                setStoreNameFilterData([])
                setData(true)
                setApproval(true)
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            // setPageLoader(false);
            console.error("Error fetching data:", error.message);
        } finally {
            setLoading(false)
            // setPageLoader(false);
        }
    }
  

    const FetchData = async () => {
        try {
            const response = await request("Post", {
                "requesttype": "petty_cash_entry",
                "op_type": "fetch_petty_cash_data",
                "user_id": Store_Emailid,
                "user_name": username,
                "month": date
            })
            let res = await JSON.parse(response.data.data);
            if (response.data.statuscode === 200) {
                setFetchData(res)
                let Store_Name = []
                let Entry_Date = []
                let Is_Approved = []
                res.map((res) => {
                    if (res.store_name !== "") {
                        Store_Name.push({
                            text: res.store_name,
                            value: res.store_name
                        })
                    }
                    if (res.entry_date !== "") {
                        Entry_Date.push({
                            text: res.entry_date,
                            value: res.entry_date
                        })
                    }
                    if (res.is_approved !== "") {
                        Is_Approved.push({
                            text: res.is_approved,
                            value: res.is_approved
                        });
                    }
                    return Store_Name, Entry_Date, Is_Approved
                })
                const uniqueStoreNames = _.uniqBy(Store_Name, 'value');
                const uniqueEntryDate = _.uniqBy(Entry_Date, 'value');
                const uniqueIsApproved = _.uniqBy(Is_Approved, 'value')
                setDateFilter(uniqueEntryDate)
                setIsApproveFilter(uniqueIsApproved)
            }
            else if (response.data.statuscode === 404) {
                setLoading(false)
                message.error("Data Unavailable")
            }
        } catch (error) {
            setLoading(false)
            // setPageLoader(false);
            console.error("Error fetching data:", error.message);
        } finally {
            setLoading(false)
            // setPageLoader(false);
        }
    }

    const StoreName = async () => {
        try {
            const response = await request("Post", {
                "requesttype": "petty_cash_entry",
                "op_type": "fetch_store_name",
                "user_id": Store_Emailid,
                "user_name": username
                // "user_id": "greg.zambelli@gtigrows.com"
            })
            let res = await JSON.parse(response.data.data);
            if (response.data.statuscode === 200) {
                const Store = []
                res.map((res) => {
                    if (res.store_name !== "") {
                        Store.push({
                            text: res.store_name,
                            value: res.store_name
                        })
                    }
                    return Store
                })
                setStoreDropDown(Store)
            }

        } catch (error) {
            setLoading(false)
            // setPageLoader(false);
            console.error("Error fetching data:", error.message);
        } finally {
            setLoading(false)
            // setPageLoader(false);
        }
    }

    const FetchCatagory = async () => {
        try {
            const response = await request("Post", {
                "requesttype": "petty_cash_entry",
                "message_key": "petty_cash_insert_update",
                "op_type": "fetch_petty_cash_catagory",
                "user_id": Store_Emailid,
                "user_name": username,
                "app_id": localStorage.getItem("app_id"),
                // "app_id": "13"
            })
            let res = await JSON.parse(response.data.data);
            if (response.data.statuscode === 200) {
                const Catagory = []
                res.map((res) => {
                    if (res.message_key !== "") {
                        Catagory.push({
                            text: res.message_key,
                            value: res.message_key
                        })
                    }
                    return Catagory
                })
                setCatagoryDropDown(Catagory)
            }

        } catch (error) {
            setLoading(false)
            // setPageLoader(false);
            console.error("Error fetching data:", error.message);
        } finally {
            setLoading(false)
            // setPageLoader(false);
        }
    }

    const handle_catagory = async (value) => {
        setAddRecord((prevAddRecord) => ({ ...prevAddRecord, catagory: value }))
        setEditRecord((prevEditRecord) => ({ ...prevEditRecord, catagory: value }))
        try {
            const response = await request("Post", {
                "op_type": "fetch_journal_entry_config",
                "requesttype": "petty_cash_entry",
                "message_key": "petty_cash_insert_update",
                "user_id": Store_Emailid,
                "user_name": username,
                "app_id": localStorage.getItem("app_id"),
                // "app_id": "13",
                "catagory": value
            })
            let res = await JSON.parse(response.data.data);
            if (response.data.statuscode === 200) {
                const Reason = []
                res.map((res) => {
                    if (res.message_key !== "") {
                        Reason.push({
                            text: res.menu_value,
                            value: res.menu_value
                        })
                    }
                    return Reason
                })
                setReasonDropDown(Reason)
            }

        } catch (error) {
            setLoading(false)
            // setPageLoader(false);
            console.error("Error fetching data:", error.message);
        } finally {
            setLoading(false)
            // setPageLoader(false);
        }
    }

    const PendingApproval = async () => {
        try {
            const response = await request("Post", {
                "requesttype": "petty_cash_entry",
                "op_type": "approver_pending_list",
                "user_id": Store_Emailid,
                // "user_id": "greg.zambelli@gtigrows.com",
                "user_name": username
            })
            let res = await JSON.parse(response.data.data);
            if (response.data.statuscode === 200) {
                setApprovalFilterData(res)
                setLoading(false)
            }
            else if (response.data.statuscode === 403) {
                message.error("You Are Not Authorization Person")
                setApprovalFilterData([])
                setLoading(false)
            }

        } catch (error) {
            setLoading(false)
            // setPageLoader(false);
            console.error("Error fetching data:", error.message);
        } finally {
            setLoading(false)
            // setPageLoader(false);
        }
    }

    const FetchStoreName = async () => {
        let Month = localStorage.getItem("date") ? localStorage.getItem("date") : date
        try {
            const response = await request("Post", {
                "requesttype": "petty_cash_entry",
                "op_type": "fetch_petty_cash_store_name",
                "user_id": Store_Emailid,
                "store_name": localStorage.getItem("storename"),
                "user_name": username,
                "month": Month,
            })
            let res = response.data.data;
            if (response.data.statuscode === 200) {
                setStoreNameFilterData(res)
                setLoading(false)
                setData(true)
                setApproval(true)
            }
            else if (response.data.statuscode === 404) {
                setLoading(false)
                setStoreNameFilterData([])
                // message.warning("Data Unavailable")
                setData(true)
                setApproval(true)
                setLoading(false)
            }

        } catch (error) {
            setLoading(false)
            // setPageLoader(false);
            console.error("Error fetching data:", error.message);
        } finally {
            setLoading(false)
            // setPageLoader(false);
        }
    }

    const handle_change_store = async (value) => {
        setStoreName(value)
        if (value == "" || value === undefined || value === null) {
            localStorage.setItem("storename", "")
        }
        if (value) {
            localStorage.setItem("storename", value)
        }
        setLoading(true)
        if (!value) {
            setStoreNameFilterData([])
            setLoading(false)
            setData(false)
        }
        try {
            let Month = localStorage.getItem("date") ? localStorage.getItem("date") : date
            const response = await request("Post", {
                "requesttype": "petty_cash_entry",
                "op_type": "fetch_petty_cash_store_name",
                "user_id": Store_Emailid,
                "store_name": value,
                "user_name": username,
                "month": Month,
            })
            let res = response.data.data;
            // console.log(response.data.data,"response")
            if (response.data.statuscode === 200) {
                setLoading(false)
                if (res !== null && res.length > 0) {
                    setStoreNameFilterData(res)
                    setLoading(false)
                    setData(true)
                    setApproval(true)
                }
            }
            else if (response.data.statuscode === 404) {
                setLoading(false)
                setStoreNameFilterData([])
                message.warning("Data Unavailable")
                setData(true)
                setApproval(true)
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            // setPageLoader(false);
            console.error("Error fetching data:", error.message);
        } finally {
            setLoading(false)
            // setPageLoader(false);
        }
    }
 

    const handle_change_store_approval = async (value) => {
        setLoading(true)
        if (!value) {
            setStoreNameFilterDataApproval([])
            setLoading(false)
            setApprovalFilter(false)
        }
        try {
            const response = await request("Post", {
                "requesttype": "petty_cash_entry",
                "op_type": "fetch_petty_cash_store_name_approved",
                "user_id": Store_Emailid,
                "store_name": value,
                "user_name": username
            })
            let res = await response.data.data;
            // console.log(response.data.statuscode, "filter")
            if (response.data.statuscode === 200) {
                setLoading(false)
                if (res !== null && res.length > 0) {
                    setStoreNameFilterDataApproval(res)
                    setLoading(false)
                    setApprovalFilter(true)
                }
            }
            if (response.data.statuscode === 404) {
                setStoreNameFilterDataApproval([])
                message.warning("Data Unavailable")
            }

        } catch (error) {
            setLoading(false)
            // setPageLoader(false);
            console.error("Error fetching data:", error.message);
        } finally {
            setLoading(false)
            // setPageLoader(false);
        }
    }



    const handle_store_name = async (value) => {
        localStorage.setItem("storename", value)
        setAddRecord((prevAddRecord) => ({ ...prevAddRecord, storeName: value }))
        setEditRecord((prevEditRecord) => ({ ...prevEditRecord, storeName: value }))
        try {
            const response = await request("Post", {
                "requesttype": "petty_cash_entry",
                "op_type": "approver_email_storename",
                "user_id": Store_Emailid,
                "store_name": value,
                "user_name": username
            })
            let res = await JSON.parse(response.data.data);
            if (response.data.statuscode === 200) {
                res.map((res) => {
                    setEmail(res.system_pos_id)
                })
            }
        } catch (error) {
            setLoading(false)
            // setPageLoader(false);
            console.error("Error fetching data:", error.message);
        } finally {
            setLoading(false)
            // setPageLoader(false);
        }
    }

    const handle_edit_record = (value) => {
        // console.log(value, "value")
        setIsModalEdit(true)
        setEntriesKey(value.petty_cash_entries_key)
        setEditRecord((prevEditRecord) => ({
            ...prevEditRecord,
            storeName: value.store_name,
            entryDate: value.entry_date,
            catagory: value.catagory,
            reason: value.reason,
            description: value.description,
            amount: value.amount,
        }))
    }

    const handle_save = async () => {
        setLoading(true)
        setSaveLoading(true)
        try {
            const response = await request("Post", {
                "op_type": "new",
                "requesttype": "petty_cash_entry",
                "message_key": "petty_cash_insert_update",
                "user_id": Store_Emailid,
                "user_name": username,
                "store_name": addRecord.storeName,
                "entry_date": addRecord.entryDate,
                "description": addRecord.description,
                "reason": addRecord.reason,
                "amount": addRecord.amount,
                "catagory": addRecord.catagory,
                // "sub_catagory": addRecord.subCatagory
            })
            if (response.data.statuscode === 200) {
                setSaveLoading(false)
                setLoading(false)
                message.success("Data Save Successfully")
                setIsModalAdd(false)
                handle_clear()
                FetchStoreName()
            }
            else {
                setSaveLoading(false);
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            setSaveLoading(false)
            console.error("Error fetching data:", error.message);
        } finally {
            setSaveLoading(false)
            setLoading(false)
        }
    }

    const handle_update = async () => {
        setLoading(true)
        setUpdateLoading(true)
        try {
            const response = await request("Post", {
                "op_type": "update",
                "requesttype": "petty_cash_entry",
                "message_key": "petty_cash_insert_update",
                "user_id": Store_Emailid,
                "user_name": username,
                "store_name": editRecord.storeName,
                "entry_date": editRecord.entryDate,
                "description": editRecord.description,
                "reason": editRecord.reason,
                "amount": editRecord.amount,
                "comments": editRecord.comments,
                "petty_cash_entries_key": entriesKey,
                "catagory": editRecord.catagory,

            })
            if (response.data.statuscode === 200) {
                setUpdateLoading(false)
                setLoading(false)
                message.success("Data Update Successfully")
                setIsModalEdit(false)
                handle_pending_approval()
            }
            else {
                setUpdateLoading(false);
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            setUpdateLoading(false)
            console.error("Error fetching data:", error.message);
        } finally {
            setUpdateLoading(false)
            setLoading(false)
        }
    }

    const handle_approve = async () => {
        setLoading(true)
        setApproveLoading(true)
        try {
            const response = await request("Post", {
                "op_type": "approve",
                "requesttype": "petty_cash_entry",
                "user_id": Store_Emailid,
                "user_name": username,
                "message_key": "petty_cash_insert_update",
                "comments": editRecord.comments,
                "petty_cash_entries_key": entriesKey

            })
            if (response.data.statuscode === 200) {
                setApproveLoading(false)
                setLoading(false)
                message.success("Data Update Successfully")
                setIsModalEdit(false)
                handle_pending_approval()
            }
            else {
                setApproveLoading(false);
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            setApproveLoading(false)
            console.error("Error fetching data:", error.message);
        } finally {
            setApproveLoading(false)
            setLoading(false)
        }
    }

    const handle_reject = async () => {
        setLoading(true)
        setRejectLoading(true)
        try {
            const response = await request("Post", {
                "op_type": "reject",
                "requesttype": "petty_cash_entry",
                "user_id": Store_Emailid,
                "user_name": username,
                "message_key": "petty_cash_insert_update",
                "comments": editRecord.comments,
                "petty_cash_entries_key": entriesKey

            })
            if (response.data.statuscode === 200) {
                setRejectLoading(false)
                setLoading(false)
                message.success("Data Update Successfully")
                setIsModalEdit(false)
                handle_pending_approval()
            }
            else {
                setRejectLoading(false);
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            setRejectLoading(false)
            console.error("Error fetching data:", error.message);
        } finally {
            setRejectLoading(false)
            setLoading(false)
        }
    }

    const handle_delete = async (value) => {
        setLoading(true)
        try {
            const response = await request("Post", {
                "op_type": "delete",
                "requesttype": "petty_cash_entry",
                "message_key": "petty_cash_insert_update",
                "user_id": Store_Emailid,
                "user_name": username,
                "store_name": value.store_name,
                "entry_date": value.entry_date,
                "description": value.description,
                "reason": value.reason,
                "amount": value.amount,
                "petty_cash_entries_key": value.petty_cash_entries_key
            })
            if (response.data.statuscode === 200) {
                setLoading(false)
                message.success("Data Save Successfully")
                // handle_change_store()
            }
            else {
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            console.error("Error fetching data:", error.message);
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        FetchData();
        StoreName();
        FetchCatagory();
        PendingApproval();
        FetchStoreName()
    }, [])

    const handle_add_record = () => {
        setIsModalAdd(true)
        handle_clear()
    }

    const handle_summary = () => {
        navigate("/cash-supply-summary")
    }

    const handle_back = () => {
        setData(true)
        setApproval(true)
        setApprovalButton(true)
        setLoading(false)
        setEditEnable(false)
        setEdit(false)
        setButtonShow(false)
        setDisable(true)
    }

    const handle_clear = () => {
        setAddRecord((prevAddRecord) => ({
            ...prevAddRecord,
            entryDate: "",
            catagory: "",
            reason: "",
            description: "",
            amount: "",
        }))
    }

    const handle_date = (date, dateString) => {
        if (dateString == "" || dateString == null || dateString == undefined) {
            localStorage.setItem('date', dayjs(getCurrentDate()))
            setDate(dayjs(getCurrentDate()))
        }
        localStorage.setItem('date', dateString.toString())
        setDate(dateString)
        handle_change_store(storeName ? storeName : selectedValue ? selectedValue : "")
    }
    const ButtonData = [
        {
          type: "dropdown",
          className: "form-field-lg",
          showSearch: true,
          optionFilterProp: "children",
          filterOption: (input, option) =>
            (option?.value ?? "").toLowerCase().includes(input),
          filterSort: (optionA, optionB) =>
            (optionA?.value ?? "").toLowerCase().localeCompare((optionB?.value ?? "").toLowerCase()),
          placeholder: "Store Name",
        
          onChange: approvalButton === false ? handle_change_store_approval : handle_change_store,
          options: storeDrobDown,
          defaultValue: localStorage.getItem("storename"),
        },
        {
          type: "date",
          defaultValue: localStorage.getItem("date")
            ? defaultValue
            : dayjs(getCurrentDate(), monthFormat),
          format: monthFormat,
          className: "form-field-lg",
          onChange: (date, dateString) => handle_date(date, dateString),
          allowClear: false,
          picker: "month",
   
        },
        {
          type: "buttonGroup",
          className: "form-field-lg",
          buttons: approvalButton === true
            ? [
                { onClick: () => handle_add_record(storeName), label: "Add Record", className: "third-btn gap-sm" },
                { onClick: handle_pending_approval, label: "Pending Approval", className: "primary-btn gap-sm" },
              
                { onClick: handle_summary, label: "Cash Supply Summary", className: "primary-btn gap-sm" },
              ]
            : [{ onClick: handle_back, label: "Back", className: "primary-btn" }],
        },
      ];

    return (
        <div className='storekeymaster-container'>
            <DynamicModal visible={isModalAdd} content={<UserInfo fieldData={UserData} />} title={"Add Record"} onClose={() => { setIsModalAdd(false); handle_clear() }} width={1100} />
            <DynamicModal visible={isModalEdit} content={<UserInfo fieldData={UserDataEdit} />} title={"Edit Record"} onClose={() => setIsModalEdit(false)} width={1100} />
            <div className='header-session'>
                <h3  className={DarkMode==="true"||DarkMode===true?"page-header-dark":"page-header"}>CASH SUPPLY EXPENSES</h3>
                {/* <div style={{ display: "flex", gap: "70px" }}>
                    {approvalButton === false ?
                        <Dropdown
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.value ?? '').toLocaleLowerCase().includes(input)}
                            filterSort={(optionA, optionB) =>
                                (optionA?.value ?? '').toLowerCase().localeCompare((optionB?.value ?? '').toLowerCase())
                            }
                            placeholder="Store Name"
                            style={{ width: "100%" }}
                            onChange={handle_change_store_approval}
                            options={storeDrobDown}
                            defaultValue={localStorage.getItem("storename")}
                        />
                        :
                        <>
                            <Dropdown
                                showSearch

                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.value ?? '').toLocaleLowerCase().includes(input)}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.value ?? '').toLowerCase().localeCompare((optionB?.value ?? '').toLowerCase())
                                }
                                placeholder="Store Name"
                                style={{ width: "100%" }}
                                onChange={handle_change_store}
                                options={storeDrobDown}
                                defaultValue={localStorage.getItem("storename")}


                            ></Dropdown>

                        </>
                    }

                    {
                        localStorage.getItem("date") ? <DatePicker defaultValue={defaultValue} format={monthFormat} onChange={(date, dateString) => handle_date(date, dateString)}
                            allowClear={false}



                            picker="month" className='field-width' /> :
                            <DatePicker defaultValue={dayjs(getCurrentDate(), monthFormat)} format={monthFormat} onChange={(date, dateString) => handle_date(date, dateString)}
                                allowClear={false}



                                picker="month" className='field-width' />
                    }

                    {
                        approvalButton === true ?

                            <><Button onClick={handle_pending_approval} label={"Pending Approval"} className={"primary-btn"} />
                                <Button onClick={() => handle_add_record(storeName)} label={"Add Record"} className={"primary-btn"} />
                                <Button onClick={handle_summary} label={"Cash Supply Summary"} className={"primary-btn"} /></>
                            :
                            <Button onClick={handle_back} label={"Back"} className={"primary-btn"} />

                    }
                </div> */}
               
            </div>
            <UserInfo fieldData={ButtonData}/>

            <div>
                <Table
                    loading={loading}
                    dataSource={data === false ? fetchData : (approval === true ? storeNameFilterData : (approvalFilter === false ? approvalFilterData : storeNameFilterDataApproval))}
                    columns={columns}

                />
            </div>

        </div>
    )
}

export default CashSupplyExpenses