import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../config/msalconfig";
import { Button, Image } from "antd";
import { useNavigate } from "react-router-dom";
import MicrosoftIcon from "../../../assets/microsoft-icon.png";
import Cookies from "js-cookie";

export const SignInButton = () => {
  const { instance,inProgress } = useMsal();
//   const wait = (milliseconds) => new Promise(resolve => setTimeout(resolve, milliseconds));
// console.log("inprogress",inProgress)
const handleLogin = async () => {
  try {
    const tokenResponse =  await instance.loginRedirect(loginRequest);
    const accessToken = tokenResponse.accessToken;
    console.log(accessToken,"accc")
  } catch (error) {
    console.log(error);
  }
};
// const handleLogin = async () => {
//   try {
//     // Clear local storage
//     window.localStorage.clear();

//     // Clear session storage
//     window.sessionStorage.clear();

//     // Clear cookies
//     document.cookie.split(';').forEach(function(cookie) {
//       const eqPos = cookie.indexOf('=');
//       const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
//       document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
//     });

//     // Force a reload to clear the cache
//     window.location.reload(true);

//     // Alternatively, you can navigate to a new page
//     // window.location.href = '/newPage';

//     // Perform the login
//     const tokenResponse = await instance.loginRedirect(loginRequest);
//     const accessToken = tokenResponse.accessToken;
//     console.log(accessToken, "accc");
//   } catch (error) {
//     console.error(error);
//   }
// };

  
  return (
    <div>
      <Button onClick={handleLogin} className={"signin-btn"}>
        <Image src={MicrosoftIcon} /> Sign in with Microsoft
      </Button>
    </div>
  );
};
