import React from 'react'
import {Upload as AntUpload} from 'antd'
import Button from '../Button/Button'
import Label from '../Label/Label'

function Upload({ placeholder, value, onChange, className, inputType, disabled, icon , label, customRequest}) {
    return (
        <div>
            <AntUpload onChange={onChange} customRequest={customRequest}>
                <Button label={label}/>
            </AntUpload>
        </div>
    )
}

export default Upload